import { Text, useBreakpointValue } from "@chakra-ui/react";
import FullTemplateDomain from "entities/domain/templates/full_template";
import WhatsappTemplate from "entities/domain/whatsapp-template";
import React, { useEffect, useState } from "react";
import { RegularOrWhatsappTemplate } from "redux/features/templates";

const TemplateTitleColumn = ({ item }: { item: RegularOrWhatsappTemplate }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (item instanceof FullTemplateDomain) {
      setText(item.title);
    } else if (item instanceof WhatsappTemplate) {
      setText(item.name);
    }
  }, [item]);

  return (
    <Text
      textOverflow="ellipsis"
      textAlign="left"
      whiteSpace="nowrap"
      overflow="hidden"
      pl={isBaseSize ? 2 : 4}
    >
      {text}
    </Text>
  );
};

export default TemplateTitleColumn;
