import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  VStack,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertDescription,
  UnorderedList,
  ListItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import {
  WhatsappButton,
  WhatsappButtonType,
} from "entities/domain/whatsapp-template";
import BuildingBlockWrapper from "./BuildingBlockWrapper";
import BuildingBlockHeaderWrapper from "./BuildingBlockHeaderWrapper";
import ButtonInput from "./ButtonInput";

interface ButtonsBuildingBlockProps {
  isDisabled: boolean;
  onButtonsChange: (buttons: WhatsappButton[]) => void;
}

const MAX_QUICK_REPLY_BUTTONS = 3;
const MAX_URL_BUTTONS = 1;
const MAX_PHONE_NUMBER_BUTTONS = 1;
const MAX_BUTTONS_TOTAL = 3;

const ButtonsBuildingBlock: React.FC<ButtonsBuildingBlockProps> = ({
  isDisabled,
  onButtonsChange,
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [buttons, setButtons] = useState<WhatsappButton[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleAddUrlButton = () => {
    setButtons([...buttons, new WhatsappButton(WhatsappButtonType.URL, "")]);
  };

  const handleAddQuickReplyButton = () => {
    setButtons([
      ...buttons,
      new WhatsappButton(WhatsappButtonType.QUICK_REPLY, ""),
    ]);
  };

  const handleAddPhoneNumberButton = () => {
    setButtons([
      ...buttons,
      new WhatsappButton(WhatsappButtonType.PHONE_NUMBER, ""),
    ]);
  };

  const handleRemoveButton = (index: number) => {
    const newButtons = buttons.filter((_, i) => i !== index);
    setButtons(newButtons);
    onButtonsChange(newButtons);
  };

  const handleButtonChange = (index: number, field: string, value: string) => {
    const newButtons = buttons.map((button, i) => {
      if (i === index) {
        return { ...button, [field]: value };
      }
      return button;
    });
    setButtons(newButtons);
    onButtonsChange(
      newButtons.filter((b) => {
        if (b.type === WhatsappButtonType.QUICK_REPLY) {
          return b.text.length > 0;
        }

        if (b.type === WhatsappButtonType.URL) {
          return b.text.length > 0 && (b.url || "").length > 0;
        }

        if (b.type === WhatsappButtonType.PHONE_NUMBER) {
          return b.text.length > 0 && (b.phoneNumber || "").length > 0;
        }

        return true;
      })
    );
  };

  const [tooManyButtons, setTooManyButtons] = useState(false);
  const [tooManyQuickReplyButtons, setTooManyQuickReplyButtons] =
    useState(false);
  const [tooManyUrlButtons, setTooManyUrlButtons] = useState(false);
  const [tooManyPhoneNumberButtons, setTooManyPhoneNumberButtons] =
    useState(false);

  useEffect(() => {
    setTooManyButtons(buttons.length >= MAX_BUTTONS_TOTAL);
    setTooManyQuickReplyButtons(
      buttons.filter((b) => b.type === WhatsappButtonType.QUICK_REPLY).length >=
        MAX_QUICK_REPLY_BUTTONS
    );
    setTooManyUrlButtons(
      buttons.filter((b) => b.type === WhatsappButtonType.URL).length >=
        MAX_URL_BUTTONS
    );
    setTooManyPhoneNumberButtons(
      buttons.filter((b) => b.type === WhatsappButtonType.PHONE_NUMBER)
        .length >= MAX_PHONE_NUMBER_BUTTONS
    );
  }, [buttons]);

  useEffect(() => {
    setButtons([]);
    onButtonsChange([]);
  }, [isOpen]);

  return (
    <BuildingBlockWrapper>
      <BuildingBlockHeaderWrapper
        name="Buttons"
        isOpen={isOpen}
        setIsOpen={isDisabled ? undefined : setIsOpen}
      />
      {isOpen ? (
        <Box width="100%" p="1rem" px={isBaseSize ? 2 : 6}>
          <VStack spacing={4} align="stretch">
            {tooManyButtons ||
            tooManyPhoneNumberButtons ||
            tooManyQuickReplyButtons ||
            tooManyUrlButtons ? (
              <Alert status="info" borderRadius="xl">
                <AlertIcon />
                <AlertDescription>
                  <UnorderedList>
                    {tooManyButtons ? (
                      <ListItem>
                        You can only have up to 3 buttons in total.
                      </ListItem>
                    ) : null}
                    {tooManyUrlButtons ? (
                      <ListItem>You can only have up to 1 URL button.</ListItem>
                    ) : null}
                    {tooManyPhoneNumberButtons ? (
                      <ListItem>
                        You can only have up to 1 phone number button.
                      </ListItem>
                    ) : null}
                  </UnorderedList>
                </AlertDescription>
              </Alert>
            ) : null}
            {buttons.map((button, index) => (
              <ButtonInput
                key={index}
                button={button}
                index={index}
                handleButtonChange={handleButtonChange}
                handleRemoveButton={handleRemoveButton}
                isDisabled={isDisabled}
              />
            ))}
            <ButtonGroup
              colorScheme="gray"
              id="add-whatsapp-button-group"
              isAttached
            >
              <Button
                leftIcon={<AddIcon />}
                onClick={() => {
                  handleAddQuickReplyButton();
                  setTimeout(() => {
                    document
                      .getElementById("add-whatsapp-button-group")
                      ?.scrollIntoView();
                  }, 0);
                }}
                isDisabled={
                  isDisabled ||
                  buttons.length >= MAX_BUTTONS_TOTAL ||
                  buttons.filter(
                    (b) => b.type === WhatsappButtonType.QUICK_REPLY
                  ).length >= MAX_QUICK_REPLY_BUTTONS
                }
              >
                {isBaseSize ? "Reply" : "Add Quick Reply Button"}
              </Button>
              <Button
                leftIcon={<AddIcon />}
                onClick={() => {
                  handleAddUrlButton();
                  setTimeout(() => {
                    document
                      .getElementById("add-whatsapp-button-group")
                      ?.scrollIntoView();
                  }, 0);
                }}
                isDisabled={
                  isDisabled ||
                  buttons.length >= MAX_BUTTONS_TOTAL ||
                  buttons.filter((b) => b.type === WhatsappButtonType.URL)
                    .length >= MAX_URL_BUTTONS
                }
              >
                {isBaseSize ? "URL" : "Add URL Button"}
              </Button>
              <Button
                leftIcon={<AddIcon />}
                onClick={() => {
                  handleAddPhoneNumberButton();
                  setTimeout(() => {
                    document
                      .getElementById("add-whatsapp-button-group")
                      ?.scrollIntoView();
                  }, 0);
                }}
                isDisabled={
                  isDisabled ||
                  buttons.length >= MAX_BUTTONS_TOTAL ||
                  buttons.filter(
                    (b) => b.type === WhatsappButtonType.PHONE_NUMBER
                  ).length >= MAX_PHONE_NUMBER_BUTTONS
                }
              >
                {isBaseSize ? "Phone" : "Add Phone Number Button"}
              </Button>
            </ButtonGroup>
          </VStack>
        </Box>
      ) : null}
    </BuildingBlockWrapper>
  );
};

export default ButtonsBuildingBlock;
