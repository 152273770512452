import { TrackAnalyticsPayload } from "hooks/use-analytics";

const sendNotification = (
  message: string,
  conversationId: number,
  track: (eventName: string, payload: TrackAnalyticsPayload) => void
) => {
  if (document.hidden) {
    let fuzeyLogo = "/fuzey-target.svg";

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      fuzeyLogo = "/fuzey-target-light.svg";
    }

    const notification = new Notification("New message from Fuzey Inbox", {
      icon: fuzeyLogo,
      body: message,
    });

    if (track) {
      track("show_notification", {
        conversation_id: conversationId,
      });
    }

    notification.onclick = () => {
      if (track) {
        track("click_notification", {
          conversation_id: conversationId,
        });
      }

      window.focus();
      notification.close();
    };
  }
};

export function askForNotificationsPermission(
  track: (eventName: string, payload?: TrackAnalyticsPayload) => void
) {
  if (!("Notification" in window)) {
    // eslint-disable-next-line
    console.error("This browser doesn't support notifications");

    return;
  }

  if (
    Notification.permission !== "denied" &&
    Notification.permission !== "granted"
  ) {
    track("request_notification_permission");

    Notification.requestPermission();
  }
}

export function sendBrowserNotification(
  message: string,
  conversationId: number,
  trackAnalytics: (eventName: string, payload: TrackAnalyticsPayload) => void
) {
  if (canSendBrowserNotification()) {
    sendNotification(message, conversationId, trackAnalytics);
    // eslint-disable-next-line
    return;
  }
}

export function canSendBrowserNotification() {
  return (
    "Notification" in window &&
    Notification.permission === "granted" &&
    document.hidden
  );
}
