import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InventoryVehicleDomain, {
  AvailabilityStatus,
  VehicleCondition,
} from "entities/domain/inventory-vehicle";
import { InventoryVehicleFilterOptions } from "services/inventory";

interface InventoryState {
  inventoryVehicles: InventoryVehicleDomain[];
  filterOptions: InventoryVehicleFilterOptions;
  selectedVehicle: InventoryVehicleDomain | null;
  filterByAvailability: AvailabilityStatus;
  filterByMake: string | null;
  filterByModel: string | null;
  filterByYearMin: number | null;
  filterByYearMax: number | null;
  filterByPriceMin: number | null;
  filterByPriceMax: number | null;
  filterByCondition: VehicleCondition;
}

const initialState: InventoryState = {
  inventoryVehicles: [],
  filterOptions: {
    allMakeModels: [],
    allMakes: [],
    allModels: [],
    minYear: 0,
    maxYear: 0,
    minPrice: 0,
    maxPrice: 0,
    allConditions: [],
    allAvailabilities: [],
  },
  selectedVehicle: null,
  filterByMake: null,
  filterByModel: null,
  filterByYearMin: null,
  filterByYearMax: null,
  filterByPriceMin: null,
  filterByPriceMax: null,
  filterByCondition: VehicleCondition.NEW,
  filterByAvailability: AvailabilityStatus.AVAILABLE,
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setInventoryVehicles(
      state,
      action: PayloadAction<InventoryVehicleDomain[]>
    ) {
      state.inventoryVehicles = action.payload;
    },
    setInventoryVehicleFilterOptions(
      state,
      action: PayloadAction<InventoryVehicleFilterOptions>
    ) {
      state.filterOptions = action.payload;
    },
    setFilterByMake(state, action: PayloadAction<string | null>) {
      state.filterByMake = action.payload;
    },
    setFilterByModel(state, action: PayloadAction<string | null>) {
      state.filterByModel = action.payload;
    },
    setFilterByYearMin(state, action: PayloadAction<number | null>) {
      state.filterByYearMin = action.payload;
    },
    setFilterByYearMax(state, action: PayloadAction<number | null>) {
      state.filterByYearMax = action.payload;
    },
    setFilterByPriceMin(state, action: PayloadAction<number | null>) {
      state.filterByPriceMin = action.payload;
    },
    setFilterByPriceMax(state, action: PayloadAction<number | null>) {
      state.filterByPriceMax = action.payload;
    },
    setFilterByCondition(state, action: PayloadAction<VehicleCondition>) {
      state.filterByCondition = action.payload;
    },
    setFilterByAvailability(state, action: PayloadAction<AvailabilityStatus>) {
      state.filterByAvailability = action.payload;
    },
    setSelectedVehicle(
      state,
      action: PayloadAction<InventoryVehicleDomain | null>
    ) {
      state.selectedVehicle = action.payload;
    },
  },
});

export const {
  setInventoryVehicles,
  setFilterByMake,
  setFilterByModel,
  setFilterByYearMin,
  setFilterByYearMax,
  setFilterByPriceMin,
  setFilterByPriceMax,
  setFilterByCondition,
  setSelectedVehicle,
  setInventoryVehicleFilterOptions,
  setFilterByAvailability,
} = inventorySlice.actions;

export default inventorySlice.reducer;
