import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AgentDomain from "entities/domain/agents/agent-domain";
import NewAgentDomain from "entities/domain/agents/new-agent-domain";
import TeamDomain from "entities/domain/team";
import { RootState } from "redux/store";

export interface AgentsState {
  selectedAgentIds: number[];
  agents: AgentDomain[];
  teams: TeamDomain[];
  loading: boolean;
  isTeamCreationToolLoading: boolean;
  isTeamCreationToolEnabled: boolean;
  errors: string[];
  currentAgent: NewAgentDomain | null;
}

const initialState: AgentsState = {
  selectedAgentIds: [],
  isTeamCreationToolLoading: false,
  isTeamCreationToolEnabled: true,
  agents: [],
  teams: [],
  loading: true,
  errors: [],
  currentAgent: null,
};

export const agentsSelector = (state: RootState) => state.agents;

const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    fetchAgents(state) {
      state.loading = true;
    },
    fetchAgentsSuccess(state, action: PayloadAction<AgentDomain[]>) {
      state.loading = false;
      state.agents = action.payload;
      state.errors = [];
    },
    fetchAgentsFail(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    setCurrentAgent(state, action: PayloadAction<NewAgentDomain>) {
      state.currentAgent = action.payload;
    },
    updateAgent(state, action: PayloadAction<AgentDomain>) {
      state.agents = state.agents.map((a) => {
        return a.id === action.payload.id ? action.payload : a;
      });
    },
    setTeams(state, action: PayloadAction<TeamDomain[]>) {
      state.teams = action.payload;
    },
    updateAgentSelection: (
      state,
      action: PayloadAction<{ agentId: number; isSelected: boolean }>
    ) => {
      if (
        action.payload.isSelected &&
        !state.selectedAgentIds.includes(action.payload.agentId)
      ) {
        state.selectedAgentIds.push(action.payload.agentId);
      } else if (!action.payload.isSelected) {
        state.selectedAgentIds = state.selectedAgentIds.filter(
          (id) => id !== action.payload.agentId
        );
      }
    },
    clearSelectedAgents: (state) => {
      state.selectedAgentIds = [];
    },
    enableTeamCreationTool: (state) => {
      state.isTeamCreationToolEnabled = true;
    },
    disableTeamCreationTool: (state) => {
      state.isTeamCreationToolEnabled = false;
    },
    startLoadingTeamCreationTool: (state) => {
      state.isTeamCreationToolLoading = true;
    },
    stopLoadingTeamCreationTool: (state) => {
      state.isTeamCreationToolLoading = false;
    },
    propagateTeamUpdate: (state, action: PayloadAction<TeamDomain>) => {
      state.teams = state.teams.map((t) =>
        t.id === action.payload.id ? action.payload : t
      );
    },
    removeTeam: (state, action: PayloadAction<string>) => {
      state.teams = state.teams.filter((t) => t.id !== action.payload);
    },
    addTeam: (state, action: PayloadAction<TeamDomain>) => {
      if (state.teams.find((t) => t.id === action.payload.id)) {
        return;
      }

      state.teams.push(action.payload);
    },
  },
});

export const {
  fetchAgents,
  fetchAgentsSuccess,
  fetchAgentsFail,
  setCurrentAgent,
  updateAgent,
  updateAgentSelection,
  clearSelectedAgents,
  enableTeamCreationTool,
  disableTeamCreationTool,
  startLoadingTeamCreationTool,
  stopLoadingTeamCreationTool,
  setTeams,
  addTeam,
  propagateTeamUpdate,
  removeTeam,
} = agentsSlice.actions;

export default agentsSlice.reducer;
