import {
  Button,
  Flex,
  VStack,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import React, { useEffect, useRef, useState } from "react";
import { format, subDays } from "date-fns";
import ReportPerAgentDomain from "entities/domain/report-per-agent";
import AgentsService from "services/agents";
import { useAuth0 } from "@auth0/auth0-react";
import { canViewAgentsAnalytics } from "util/permissions";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import List from "./List";
import DateFilter from "./DateFilter";
import BarChart from "./BarChart";

interface AnalyticsPageProps {}

const AnalyticsPage = (_props: AnalyticsPageProps) => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();
  const toast = useToast();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const [fromDate, setFromDate] = useState<Date | null>(subDays(new Date(), 7));
  const [toDate, setToDate] = useState<Date | null>(new Date());

  const [reports, setReports] = useState<ReportPerAgentDomain[]>([]);
  const [filteredReports, setFilteredReports] = useState<
    ReportPerAgentDomain[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showBarChart, setShowBarChart] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const loadReports = async () => {
    setIsLoading(true);

    try {
      const newReports = await AgentsService.getAnalyticsReports(
        auth0Context,
        merchant.id,
        format(fromDate!, "yyyy-MM-dd"),
        format(toDate!, "yyyy-MM-dd")
      );

      setReports(newReports);
    } catch (error) {
      toast({
        status: "error",
        title: "We were unable to retrieve analytics data at this time",
        description: "Please try again or contact us if the issue persists.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!canViewAgentsAnalytics(merchant.id, currentAgent!)) {
      setIsLoading(false);
      return;
    }

    loadReports();
  }, [toDate, fromDate]);

  useEffect(() => {
    if (canViewAgentsAnalytics(merchant.id, currentAgent!)) {
      return;
    }

    navigate(`/${merchant.id}`);
  }, []);

  return (
    <Flex
      direction="column"
      position="relative"
      flex={1}
      width="100%"
      height="100%"
      {...(isBaseSize
        ? {}
        : {
            bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
            pl: "2rem",
            pr: "1rem",
          })}
    >
      {isBaseSize ? (
        <Topbar title="Analytics" />
      ) : (
        <Flex
          py={0}
          pr={4}
          justifyContent="start"
          flexWrap="wrap"
          gridGap={4}
          alignItems="center"
        >
          <DateFilter
            toDate={toDate}
            fromDate={fromDate}
            isLoading={isLoading}
            reports={filteredReports}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </Flex>
      )}
      <Flex
        direction="column"
        borderTopRadius={isBaseSize ? 0 : "3xl"}
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        w="100%"
        h="100%"
        overflowY="hidden"
        position="relative"
        alignItems="start"
        justifyContent="start"
      >
        <VStack
          alignItems="start"
          justifyContent="start"
          w="100%"
          h="100%"
          ref={scrollContainerRef}
          overflowY="auto"
        >
          <Flex
            gridGap={2}
            justifyContent="start"
            alignItems="center"
            w="100%"
            p={4}
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
          >
            <Button
              isActive={showBarChart}
              variant="ghost"
              colorScheme={colorScheme}
              onClick={() => {
                setShowBarChart(true);
              }}
            >
              Chart 📊
            </Button>
            <Button
              isActive={!showBarChart}
              colorScheme={colorScheme}
              variant="ghost"
              onClick={() => {
                setShowBarChart(false);
              }}
            >
              List 📋
            </Button>
          </Flex>
          {isBaseSize ? (
            <DateFilter
              toDate={toDate}
              fromDate={fromDate}
              isLoading={isLoading}
              reports={filteredReports}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          ) : null}
          {showBarChart ? (
            <BarChart isLoading={isLoading} reports={reports} />
          ) : (
            <List
              reports={reports}
              filteredReports={filteredReports}
              setFilteredReports={setFilteredReports}
              isLoading={isLoading}
              scrollContainerRef={scrollContainerRef}
            />
          )}
        </VStack>
      </Flex>
    </Flex>
  );
};

export default AnalyticsPage;
