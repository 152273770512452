import React, { useEffect, useState } from "react";
import {
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  useClipboard,
  IconButton,
  useColorMode,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { MdContentCopy } from "react-icons/md";
import { RiCheckFill } from "react-icons/ri";
import Integration from "components/user-settings/shared/Integration";
import { ReactComponent as EbayIcon } from "assets/icons/ebay.svg";
import { ReactComponent as MotorsIcon } from "assets/icons/motors.svg";
import { ReactComponent as CarGurusIcon } from "assets/icons/car-gurus.svg";
import CarZoneIcon from "assets/icons/car-zone.png";
import { ReactComponent as DoneDealIcon } from "assets/icons/done-deal.svg";
import CarsIrelandIcon from "assets/icons/cars-ireland.png";

import { useAppSelector } from "redux/hooks";

type SvgIcon = React.FC<React.SVGProps<SVGSVGElement>>;

interface IconData {
  icon: SvgIcon | string;
  title: string;
  type: "svg" | "png";
}

const MarketPlacesIntegrationItem = () => {
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { colorMode } = useColorMode();

  if (merchant.country !== "GB" && merchant.country !== "IE") {
    return null;
  }

  const gbIcons: IconData[] = [
    { icon: EbayIcon, title: "eBay", type: "svg" },
    { icon: MotorsIcon, title: "Motors", type: "svg" },
    { icon: CarGurusIcon, title: "CarGurus", type: "svg" },
  ];

  const ieIcons: IconData[] = [
    { icon: CarZoneIcon, title: "CarZone", type: "png" },
    { icon: DoneDealIcon, title: "DoneDeal", type: "svg" },
    { icon: CarsIrelandIcon, title: "CarsIreland", type: "png" },
  ];
  const { colorScheme } = useAppSelector((state) => state.theme);

  const icons = merchant.country === "IE" ? ieIcons : gbIcons;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const emailAddress = `${merchant.public_id}__new_lead@pipeline.getfuzey.com`;
  const { hasCopied, onCopy } = useClipboard(emailAddress);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % icons.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const currentIcon = icons[currentIndex];

  const nextCarouselItem = () => {
    setCarouselIndex((prevIndex) => (prevIndex + 1) % icons.length);
  };

  const prevCarouselItem = () => {
    setCarouselIndex(
      (prevIndex) => (prevIndex - 1 + icons.length) % icons.length
    );
  };

  const renderIcon = (
    iconData: IconData,
    height: string = "3rem",
    width: string = "8rem"
  ) => {
    if (iconData.type === "svg") {
      return (
        <Icon as={iconData.icon as SvgIcon} height={height} width={width} />
      );
    } else {
      return (
        <Image
          src={iconData.icon as string}
          alt={iconData.title}
          height={height}
          width={width}
        />
      );
    }
  };

  return (
    <>
      <Integration
        icon={renderIcon(currentIcon)}
        primaryButton={
          <Button onClick={onOpen} colorScheme={colorScheme}>
            Connect
          </Button>
        }
      >
        <Text mt={2}>
          Automatically collect and forward new customer leads from{" "}
          <b>{currentIcon.title}</b> to our system.
        </Text>
      </Integration>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent borderRadius="xl">
          <ModalHeader textAlign="center">Connect to Marketplaces</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={8}>
            <VStack spacing={8} align="stretch">
              <VStack spacing={4}>{renderIcon(icons[carouselIndex])}</VStack>

              <VStack spacing={2} align="stretch">
                <Text fontWeight="bold">
                  To connect, please forward the emails to us at:
                </Text>
                <InputGroup size="sm">
                  <Input
                    pr="4.5rem"
                    type="text"
                    value={emailAddress}
                    readOnly
                    bg={colorMode === "dark" ? "black" : "gray.100"}
                    fontSize="xs"
                    sx={{
                      direction: "rtl",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      "&::placeholder": {
                        direction: "ltr",
                      },
                    }}
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      size="xs"
                      aria-label="Copy email"
                      variant="ghost"
                      onClick={onCopy}
                      icon={
                        hasCopied ? (
                          <RiCheckFill color="green" />
                        ) : (
                          <MdContentCopy />
                        )
                      }
                    />
                  </InputRightElement>
                </InputGroup>
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme={colorScheme}
              onClick={prevCarouselItem}
              leftIcon={<ChevronLeftIcon />}
              variant="ghost"
            >
              Prev
            </Button>
            <Text>
              {carouselIndex + 1} of {icons.length}
            </Text>
            <Button
              onClick={nextCarouselItem}
              colorScheme={colorScheme}
              rightIcon={<ChevronRightIcon />}
              variant="ghost"
            >
              Next
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MarketPlacesIntegrationItem;
