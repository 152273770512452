import {
  Badge,
  Box,
  Textarea,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";
import TemplateAttachment, {
  SUPPORTED_MEDIA_TYPES,
} from "components/user-settings/shared/TemplateAttachment";
import FileDragAndDrop from "components/shared/input-drag-drop/FileDragAndDrop";
import TextLimits, { getCustomFieldsCount } from "./TextLimits";
import Toolbar from "./Toolbar";
import BuildingBlockWrapper from "./BuildingBlockWrapper";
import BuildingBlockHeaderWrapper from "./BuildingBlockHeaderWrapper";

interface HeaderBuildingBlockProps {
  text?: string;
  mediaUrl?: string;
  mediaType?: string;
  isDisabled: boolean;
  onTextChange: (text: string) => void;
  onSetFile: (file: File | null) => void;
}

interface SelectFormatOption {
  value: boolean | null;
  label: string;
}

const formatOptions: SelectFormatOption[] = [
  {
    value: null,
    label: "Text",
  },
  {
    value: true,
    label: "Media",
  },
];

const MAX_HEADER_LENGTH = 60;
const MAX_CUSTOM_FIELDS = 1;

const HeaderBuildingBlock = ({
  isDisabled,
  text = "",
  mediaUrl = "",
  mediaType = "",
  onTextChange,
  onSetFile,
}: HeaderBuildingBlockProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [hasMedia, setHasMedia] = useState<boolean | null>(
    !!(mediaUrl && mediaType)
  );
  const [localText, setLocalText] = useState<string>(text);
  const [localFile, setLocalFile] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(
    text !== "" || !!(mediaUrl && mediaType)
  );

  const [selectedFormatValue, setSelectedFormatValue] = useState<
    SingleValue<SelectFormatOption>
  >(formatOptions.find(({ value }) => value === hasMedia) || null);

  const handleCategoryChange = (
    selectedOption: SingleValue<SelectFormatOption>
  ) => {
    if (!selectedOption) {
      return;
    }

    setSelectedFormatValue(selectedOption);
    setHasMedia(selectedOption.value);
  };

  useEffect(() => {
    setSelectedFormatValue(
      formatOptions.find(({ value }) => value === hasMedia)!
    );
    setLocalText("");
    onTextChange("");
  }, [hasMedia]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFormatValue(
        formatOptions.find(({ value }) => value === null)!
      );
      setLocalText("");
      onTextChange("");
      onSetFile(null);
      setHasMedia(!!(mediaUrl && mediaType));
    }
  }, [isOpen]);

  const insertIntoText = useCallback(
    (someText: string) => {
      if (!textAreaRef || !textAreaRef.current) {
        return;
      }

      const cursorPosition = textAreaRef.current.selectionStart;

      setLocalText((oldText) => {
        const newText =
          oldText.substring(0, cursorPosition) +
          someText +
          oldText.substring(cursorPosition);

        onTextChange(newText);

        return newText;
      });

      textAreaRef.current.focus();
    },
    [textAreaRef]
  );

  return (
    <BuildingBlockWrapper>
      <BuildingBlockHeaderWrapper
        name="Header"
        isOpen={isOpen}
        setIsOpen={isDisabled ? undefined : setIsOpen}
      >
        <Select
          id="header-format-select"
          placeholder="Select format"
          onChange={handleCategoryChange}
          isDisabled={isDisabled}
          isClearable={false}
          options={formatOptions}
          value={selectedFormatValue}
          menuPortalTarget={document.body}
          styles={{
            ...{
              ...getReactSelectStyles(colorMode, colorScheme),
              container: (provided: any) => ({
                ...getReactSelectStyles(colorMode, colorScheme).container(
                  provided
                ),
                width: isBaseSize ? "100px" : "200px",
              }),
              control: (provided: any) => ({
                ...getReactSelectStyles(colorMode, colorScheme).control(
                  provided
                ),
                minHeight: "2rem",
                maxHeight: "2rem",
              }),
            },
          }}
        />
        {hasMedia ? null : (
          <Toolbar
            isDisabled={isDisabled}
            onCustomFieldSelect={
              getCustomFieldsCount(localText) >= MAX_CUSTOM_FIELDS
                ? undefined
                : insertIntoText
            }
          />
        )}
      </BuildingBlockHeaderWrapper>
      {isOpen ? (
        <>
          <Box
            width="100%"
            height={isBaseSize ? "8rem" : hasMedia ? "24rem" : "4rem"}
            p="1rem"
            px="1.5rem"
            overflow="hidden"
          >
            {hasMedia ? (
              <>
                {!localFile && !isDisabled ? (
                  <FileDragAndDrop
                    isCompact={isBaseSize}
                    setFile={(newFile) => {
                      setLocalFile(newFile);
                      onSetFile(newFile);
                    }}
                    accept={SUPPORTED_MEDIA_TYPES.join(", ")}
                    onClose={() => {
                      setLocalFile(null);
                      onSetFile(null);
                    }}
                    setIsLoading={() => {}}
                  />
                ) : null}
                <TemplateAttachment
                  file={localFile}
                  mediaType={mediaType || null}
                  mediaUrl={mediaUrl || null}
                  onAttachmentRemove={
                    !localFile && mediaType && mediaUrl
                      ? undefined
                      : () => {
                          setLocalFile(null);
                          onSetFile(null);
                        }
                  }
                />
              </>
            ) : (
              <Textarea
                ref={textAreaRef}
                border="none"
                p={0}
                id="whatsapp-template-header-textarea"
                isDisabled={isDisabled}
                placeholder="e.g. Hello, welcome to our store!"
                value={localText}
                onChange={(e) => {
                  setLocalText(e.target.value);
                  onTextChange(e.target.value);
                }}
                maxLength={MAX_HEADER_LENGTH}
                noOfLines={1}
              />
            )}
          </Box>
          {hasMedia ? (
            <Badge
              colorScheme="gray"
              variant="solid"
              position="absolute"
              right={0}
              bottom={0}
            >
              image/video/pdf
            </Badge>
          ) : (
            <TextLimits
              maxCharacters={MAX_HEADER_LENGTH}
              maxCustomFields={MAX_CUSTOM_FIELDS}
              text={localText}
            />
          )}
        </>
      ) : null}
    </BuildingBlockWrapper>
  );
};

export default HeaderBuildingBlock;
