import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { v4 as uuidv4 } from "uuid";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { ReactComponent as ArrowDownAZ } from "assets/icons/arrow-down-a-z-svgrepo-com.svg";
import { ReactComponent as ArrowDownZA } from "assets/icons/arrow-down-z-a-svgrepo-com.svg";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import { FiMoreHorizontal, FiMoreVertical } from "react-icons/fi";
import { useAppSelector } from "redux/hooks";
import FuzeyPopover from "../FuzeyPopover";

export interface SmartListColumnComponentProps<T> {
  item: T;
  [key: string]: any;
}

export interface SmartListColumn<T> {
  label: string;
  currentSorting?: "asc" | "desc" | undefined;
  setCurrentSorting?: (sorting: "asc" | "desc" | undefined) => void;
  component: React.FC<SmartListColumnComponentProps<T>>;
  infoLabel?: string;
  additionalProps?: any;
}

export interface SmartListIndividualAction<T> {
  label: string;
  execute: (item: T) => void;
  shouldShowConfirmation?: boolean;
  confirmationText?: string;
  confirmationSubmitText?: string;
}

interface SmartListProps<T> {
  addMarginAtTheBottom?: boolean;
  hasHeader?: boolean;
  isVirtualized?: boolean;
  defaultScrollToIndex?: number;
  initialScroll?: boolean;
  hasBulkActions?: boolean;
  itemIdentifier: string;
  selectedItemIds?: Array<number | string>;
  handleItemsSelected?: (ids: Array<number | string>) => void;
  getIndividualActions?: (
    item: T
  ) => SmartListIndividualAction<T>[] | undefined;
  canFetchMore?: boolean;
  hasNextPage?: boolean;
  columns: SmartListColumn<T>[];
  containerRef: React.RefObject<HTMLElement>;
  items: T[];
  hasDivider?: boolean;
  fetchMore?: () => Promise<void>;
  onItemClick?: (item: T) => void;
  getDifferentiator?: (item: T | undefined) => string;
}

interface SmartListItemProps<T> {
  index: number;
  item: T;
  items: T[];
  hasBulkActions: boolean;
  tableId: string;
  columns: SmartListColumn<T>[];
  getIndividualActions?: (
    item: T
  ) => SmartListIndividualAction<T>[] | undefined;
  getDifferentiator?: (item: T | undefined) => string;
  itemIdentifier?: string;
  onItemClick?: (item: T) => void;
  checkedItemIds: Array<number | string>;
  setCheckedItemIds: (ids: Array<number | string>) => void;
  hasDivider: boolean;
}

const SmartListItem = function <T>({
  index,
  item,
  items,
  hasBulkActions,
  tableId,
  columns,
  getIndividualActions,
  hasDivider,
  getDifferentiator,
  itemIdentifier,
  onItemClick,
  checkedItemIds,
  setCheckedItemIds,
}: SmartListItemProps<T>) {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Grid
      onClick={(e) => {
        if (!isBaseSize) {
          return;
        }

        return checkedItemIds.includes(
          item[itemIdentifier as keyof T] as string | number
        )
          ? setCheckedItemIds(
              checkedItemIds.filter(
                (itemId: string | number) =>
                  itemId !==
                  (item[itemIdentifier as keyof T] as string | number)
              )
            )
          : setCheckedItemIds([
              ...checkedItemIds,
              item[itemIdentifier as keyof T] as string | number,
            ]);
      }}
      bgColor={
        hasBulkActions &&
        checkedItemIds.includes(
          item[itemIdentifier as keyof T] as string | number
        )
          ? colorMode === "dark"
            ? `${colorScheme}.800`
            : `${colorScheme}.100`
          : "transparent"
      }
      key={
        (item[itemIdentifier as keyof T] as undefined | string) ||
        `${tableId}-body-column-${index}`
      }
      data-testid="smart-list-item"
      templateColumns={`${hasBulkActions ? "minmax(0, 0.5fr) " : ""}${
        columns.length > 0
          ? `minmax(0, ${isBaseSize ? "1fr" : "3fr"}) `.repeat(columns.length)
          : ""
      }${
        typeof getIndividualActions !== "undefined" ? "minmax(0, 0.5fr)" : ""
      }`}
      gap={isBaseSize ? 2 : 6}
      {...(typeof onItemClick === "undefined"
        ? {}
        : {
            onClick: (e) => {
              if (!(e.target instanceof HTMLElement)) {
                return;
              }

              if (
                e.target instanceof HTMLButtonElement ||
                e.target.closest("button")
              ) {
                return;
              }

              onItemClick(item);
            },
            style: {
              cursor: "pointer",
            },
            _hover: {
              bgColor: colorMode === "dark" ? "gray.900" : "gray.50",
            },
          })}
      position="relative"
      {...(hasDivider
        ? {
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: colorMode === "dark" ? "gray.700" : "gray.50",
          }
        : {})}
      py={isBaseSize ? 2 : 4}
      {...(typeof getDifferentiator !== "undefined" &&
      getDifferentiator(item) !== getDifferentiator(items[index - 1])
        ? {
            pt: isBaseSize ? "3rem" : "3.5rem",
          }
        : {})}
      alignItems="center"
    >
      {typeof getDifferentiator !== "undefined" &&
        getDifferentiator(item) !== getDifferentiator(items[index - 1]) && (
          <Box
            position="absolute"
            top={0}
            left={0}
            height="2.5rem"
            width="100%"
            py={2}
            px={4}
            bgColor={colorMode === "dark" ? "gray.600" : "gray.50"}
            fontWeight="bold"
            color={colorMode === "dark" ? "gray.300" : "gray.600"}
          >
            {getDifferentiator(item)}
          </Box>
        )}
      {hasBulkActions ? (
        <GridItem textAlign="center" pl={4}>
          <Flex justifyContent="center" alignItems="center">
            <Checkbox
              display="flex"
              isChecked={checkedItemIds.includes(
                item[itemIdentifier as keyof T] as string | number
              )}
              pointerEvents={isBaseSize ? "none" : "auto"}
              onChange={(e) => {
                return checkedItemIds.includes(
                  item[itemIdentifier as keyof T] as string | number
                )
                  ? setCheckedItemIds(
                      checkedItemIds.filter(
                        (itemId: string | number) =>
                          itemId !==
                          (item[itemIdentifier as keyof T] as string | number)
                      )
                    )
                  : setCheckedItemIds([
                      ...checkedItemIds,
                      item[itemIdentifier as keyof T] as string | number,
                    ]);
              }}
            />
          </Flex>
        </GridItem>
      ) : null}
      {columns.map((column, columnIndex) => (
        <GridItem
          key={`${tableId}-body-column-index-${columnIndex}-row-${index}`}
          textAlign="center"
        >
          <column.component item={item} {...column.additionalProps} />
        </GridItem>
      ))}
      {typeof getIndividualActions !== "undefined" ? (
        <GridItem textAlign="center" pr={4}>
          <FuzeyPopover
            triggerIcon={<FiMoreVertical />}
            triggerSize="sm"
            actions={
              getIndividualActions(item)?.map((action) => {
                return {
                  name: action.label,
                  callback: () => action.execute(item),
                  shouldShowConfirmation: action.shouldShowConfirmation,
                  confirmationText: action.confirmationText,
                  confirmationSubmitText: action.confirmationSubmitText,
                };
              }) || []
            }
          />
        </GridItem>
      ) : null}
    </Grid>
  );
};

const SmartList = function <T>(
  {
    addMarginAtTheBottom = true,
    columns,
    isVirtualized = true,
    items,
    hasHeader = true,
    handleItemsSelected,
    selectedItemIds,
    itemIdentifier = "id",
    hasBulkActions = false,
    hasDivider = true,
    getIndividualActions,
    getDifferentiator,
    canFetchMore = false,
    hasNextPage = false,
    containerRef,
    initialScroll = true,
    fetchMore,
    onItemClick,
    defaultScrollToIndex = 0,
  }: SmartListProps<T>,
  listRef: ForwardedRef<ViewportListRef>
) {
  const tableId = uuidv4();
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [loadMore, setLoadMore] = useState<undefined | (() => Promise<void>)>();
  const [isListOverflown, setIsListOverflown] = useState<boolean>(false);

  useEffect(() => {
    if (!containerRef.current) {
      setIsListOverflown(false);

      return;
    }

    setIsListOverflown(
      containerRef.current.scrollHeight > containerRef.current.clientHeight
    );
  }, [containerRef.current?.scrollHeight, containerRef.current?.clientHeight]);

  useEffect(() => {
    if (typeof fetchMore === "undefined") {
      return;
    }

    setLoadMore(() => async () => {
      setIsLoading(true);
      await fetchMore();
      setIsLoading(false);
    });
  }, [fetchMore]);

  return (
    <Flex direction="column" mb={addMarginAtTheBottom ? 16 : 0}>
      {hasHeader && !isBaseSize ? (
        <Grid
          templateColumns={`${hasBulkActions ? "minmax(0, 0.5fr) " : ""}${
            columns.length > 0 ? "minmax(0, 3fr) ".repeat(columns.length) : ""
          }${
            typeof getIndividualActions !== "undefined"
              ? "minmax(0, 0.5fr)"
              : ""
          }`}
          gap={isBaseSize ? 2 : 6}
          alignItems="center"
        >
          {hasBulkActions &&
          !isBaseSize &&
          handleItemsSelected !== undefined &&
          selectedItemIds !== undefined ? (
            <GridItem
              pl={4}
              textAlign="center"
              py={isBaseSize ? 4 : 6}
              fontWeight="bold"
              color={colorMode === "dark" ? "gray.300" : "gray.600"}
              position="relative"
            >
              <Flex justifyContent="center" alignItems="center">
                <Checkbox
                  display="flex"
                  isChecked={
                    selectedItemIds.length === items.length && items.length > 0
                  }
                  onChange={() =>
                    selectedItemIds.length === items.length
                      ? handleItemsSelected([])
                      : handleItemsSelected(
                          items.map(
                            (item: T) =>
                              item[itemIdentifier as keyof T] as string | number
                          )
                        )
                  }
                  _after={{
                    content:
                      selectedItemIds.length > 0
                        ? `'${selectedItemIds.length}'`
                        : "''",
                    position: "absolute",
                    marginLeft: "1.5rem",
                  }}
                />
              </Flex>
            </GridItem>
          ) : null}
          {columns.map((column, index) => (
            <GridItem
              key={`${tableId}-header-column-${index}`}
              py={isBaseSize ? 4 : 6}
            >
              <HStack alignItems="center" justifyContent="center" spacing={2}>
                <Text
                  textAlign="center"
                  fontWeight="bold"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  color={colorMode === "dark" ? "gray.300" : "gray.600"}
                >
                  {column.label}
                </Text>
                {column.setCurrentSorting !== undefined && !isBaseSize && (
                  <IconButton
                    aria-label="Sort"
                    icon={
                      <Icon
                        as={
                          column.currentSorting === "asc" ||
                          column.currentSorting === undefined
                            ? ArrowDownAZ
                            : ArrowDownZA
                        }
                        __css={{
                          path: {
                            stroke:
                              column.currentSorting === undefined
                                ? colorMode === "dark"
                                  ? "gray.300"
                                  : "gray.600"
                                : "white",
                          },
                        }}
                      />
                    }
                    size="sm"
                    variant={column.currentSorting ? "solid" : "ghost"}
                    colorScheme={colorScheme}
                    onClick={() => {
                      if (column.currentSorting === "asc") {
                        column.setCurrentSorting!("desc");
                      } else if (column.currentSorting === "desc") {
                        column.setCurrentSorting!(undefined);
                      } else {
                        column.setCurrentSorting!("asc");
                      }
                    }}
                  />
                )}
                {column.infoLabel && (
                  <Tooltip
                    px={3}
                    py={1}
                    borderRadius="md"
                    backgroundColor="rgba(54, 178, 250, 0.9)"
                    label={column.infoLabel}
                  >
                    <Icon as={InfoIcon} />
                  </Tooltip>
                )}
              </HStack>
            </GridItem>
          ))}
        </Grid>
      ) : null}
      {isVirtualized ? (
        <ViewportList
          ref={listRef}
          viewportRef={containerRef}
          items={items}
          scrollThreshold={1000}
          overscan={10}
          initialIndex={(initialScroll && defaultScrollToIndex) || undefined}
          onViewportIndexesChange={([startIndex, endIndex]) => {
            if (!items.length || !canFetchMore) {
              return;
            }

            if (
              endIndex === items.length - 1 &&
              hasNextPage &&
              typeof loadMore !== "undefined"
            ) {
              loadMore();
            }
          }}
        >
          {(item, index) => (
            <SmartListItem
              key={
                (item[itemIdentifier as keyof T] as undefined | string) ||
                `${tableId}-body-row-${index}`
              }
              index={index}
              item={item}
              items={items}
              hasDivider={hasDivider}
              hasBulkActions={hasBulkActions}
              tableId={tableId}
              columns={columns}
              getIndividualActions={getIndividualActions}
              getDifferentiator={getDifferentiator}
              itemIdentifier={itemIdentifier}
              onItemClick={onItemClick}
              checkedItemIds={selectedItemIds || []}
              setCheckedItemIds={handleItemsSelected || (() => {})}
            />
          )}
        </ViewportList>
      ) : (
        items.map((item, index) => (
          <SmartListItem
            key={
              (item[itemIdentifier as keyof T] as undefined | string) ||
              `${tableId}-body-row-${index}`
            }
            index={index}
            item={item}
            items={items}
            hasBulkActions={hasBulkActions}
            tableId={tableId}
            hasDivider={hasDivider}
            columns={columns}
            getIndividualActions={getIndividualActions}
            getDifferentiator={getDifferentiator}
            itemIdentifier={itemIdentifier}
            onItemClick={onItemClick}
            checkedItemIds={selectedItemIds || []}
            setCheckedItemIds={handleItemsSelected || (() => {})}
          />
        ))
      )}
      {isLoading ? <Spinner mx="auto" mb={8} /> : null}
      {!canFetchMore && !isLoading && isListOverflown ? (
        <Flex alignItems="center" justifyContent="center" w="100%" py={8}>
          <Text>✅ List ends here</Text>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default forwardRef(SmartList) as <T>(
  props: SmartListProps<T> & { ref?: React.ForwardedRef<ViewportListRef> }
) => ReturnType<typeof SmartList>;
