import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum OnboardingStatus {
  IN_PROGRESS = "in_progress",
  PAUSED = "paused",
  COMPLETED = "completed",
}

interface StepProgress {
  isSkipped: boolean;
  isCompleted: boolean;
}

interface OnboardingState {
  status: OnboardingStatus;
  currentStep: number;
  progress: {
    [key: number]: StepProgress;
  };
}

const initialState: OnboardingState = {
  status: OnboardingStatus.IN_PROGRESS,
  currentStep: 0,
  progress: {
    0: {
      isSkipped: false,
      isCompleted: false,
    },
    1: {
      isSkipped: false,
      isCompleted: false,
    },
    2: {
      isSkipped: false,
      isCompleted: false,
    },
  },
};

const OnboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOnboardingStatus: (state, action: PayloadAction<OnboardingStatus>) => {
      state.status = action.payload;
    },
    setCurrentStep: (
      state,
      action: PayloadAction<{
        newCurrentStep: number;
        isPreviousStepSkipped: boolean;
        isPreviousStepCompleted: boolean;
      }>
    ) => {
      state.currentStep = action.payload.newCurrentStep;
      state.progress[state.currentStep] = {
        isSkipped: action.payload.isPreviousStepSkipped,
        isCompleted: action.payload.isPreviousStepCompleted,
      };
    },
  },
});

export const { setOnboardingStatus, setCurrentStep } = OnboardingSlice.actions;

export default OnboardingSlice.reducer;
