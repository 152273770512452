import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TenantDomain from "entities/domain/xero/tenant-domain";
import XeroDomain from "entities/domain/xero/xero-domain";
import { RootState } from "redux/store";

export interface SetUpXeroPayload {
  tenant_id: string;
}

interface XeroState {
  loading: boolean;
  errors: any[];
  tenants: TenantDomain[];
  selectedTenantId: string | undefined;
  xero: XeroDomain | undefined;
}

const initialState: XeroState = {
  xero: undefined,
  loading: false,
  errors: [],
  tenants: [],
  selectedTenantId: undefined,
};

const xeroSlice = createSlice({
  name: "xero",
  initialState,
  reducers: {
    fetchTenants: (state) => {
      state.loading = true;
    },
    fetchTenantsSuccess: (state, action: PayloadAction<TenantDomain[]>) => {
      state.loading = false;
      state.tenants = action.payload;
      state.errors = [];
    },
    fetchTenantsFail: (state, action: PayloadAction<any[]>) => {
      state.loading = false;
      state.errors = action.payload;
    },
    setSelectedTenant: (state, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.selectedTenantId = action.payload;
    },
    setUpXero: (state) => {
      state.loading = true;
    },
    setUpXeroSuccess: (state, action: PayloadAction<XeroDomain>) => {
      state.loading = false;
      state.xero = action.payload;
    },
    setUpXeroFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const XeroSelector = (state: RootState) => state.xero;

export const selectTenant = (
  tenantId: string | undefined,
  tenants: TenantDomain[]
): TenantDomain | undefined => {
  if (!tenantId || !tenants) return undefined;
  return tenants.find((tenant) => tenant.id === tenantId);
};

export const {
  fetchTenants,
  fetchTenantsSuccess,
  fetchTenantsFail,
  setSelectedTenant,
  setUpXero,
  setUpXeroSuccess,
  setUpXeroFail,
} = xeroSlice.actions;

export default xeroSlice.reducer;
