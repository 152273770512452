import { KeyloopAccount } from "services/admin";

export default class ExternalMetadataDomain {
  googleAccountId: string;

  googleLocationId: string;

  googlePlaceId: string;

  googleReviewLink: string;

  facebookBusinessId: string;

  stripeAccountId: string;

  shortReviewLink: string;

  autoTraderId: string;

  logo: string | null;

  keyloop: KeyloopAccount | null;

  constructor(
    googleAccountId: string,
    googleLocationId: string,
    googlePlaceId: string,
    googleReviewLink: string,
    facebookBusinessId: string,
    stripeAccountId: string,
    shortReviewLink: string,
    autoTraderId: string,
    keyloop: KeyloopAccount | null,
    logo: string | null
  ) {
    this.googleAccountId = googleAccountId;
    this.googleLocationId = googleLocationId;
    this.googlePlaceId = googlePlaceId;
    this.googleReviewLink = googleReviewLink;
    this.facebookBusinessId = facebookBusinessId;
    this.shortReviewLink = shortReviewLink;
    this.stripeAccountId = stripeAccountId;
    this.autoTraderId = autoTraderId;
    this.keyloop = keyloop;
    this.logo = logo;
  }
}
