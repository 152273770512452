import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Flex, Text, useColorMode } from "@chakra-ui/react";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import { useAppSelector } from "redux/hooks";
import React, { memo, useEffect, useState } from "react";
import {
  GroupBase,
  MultiValue,
  OptionProps,
  SingleValue,
  components,
  Props as SelectProps,
} from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import AdminService from "services/admin";
import { numberOfAllMerchantsPerLoad } from "util/constants";
import { getReactSelectStyles } from "util/methods";

interface SelectMerchantPropsBase {
  filterCallback?: (id: number) => boolean;
}

type SetSelectedMerchant = (
  newSelectedMerchant: AdminMerchantDomain | null
) => void;

interface SelectMerchantProps extends SelectMerchantPropsBase {
  isMulti?: false;
  selectedMerchant: AdminMerchantDomain | null;
  setSelectedMerchant: SetSelectedMerchant;
}

type SetSelectedMerchants = (
  newSelectedMerchants: AdminMerchantDomain[]
) => void;

interface SelectMerchantsProps extends SelectMerchantPropsBase {
  isMulti: true;
  selectedMerchants: AdminMerchantDomain[];

  setSelectedMerchants: SetSelectedMerchants;
  filterCallback?: (id: number) => boolean;
}

type SelectMerchantType =
  | (SelectMerchantProps & SelectProps<MerchantOptionTypes>)
  | (SelectMerchantsProps & SelectProps<MerchantOptionTypes>);

interface MerchantOptionTypes {
  value: number;
  label: string;
  logo?: string | null;
}

const MerchantColumn = ({ item }: { item: MerchantOptionTypes }) => {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      gridGap={4}
      px={8}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Avatar size="sm" src={item.logo || undefined} name={item.label} />
      <Text>{item.label}</Text>
    </Flex>
  );
};

const MerchantOption = function (props: OptionProps<MerchantOptionTypes>) {
  return (
    <components.Option {...props}>
      <MerchantColumn item={props.data} />
    </components.Option>
  );
};

const transformMerchantToOption = (
  merchant: AdminMerchantDomain
): MerchantOptionTypes => ({
  value: merchant.id,
  label: `${merchant.name} (${merchant.getPhoneNumber() || "N/A"})`,
  logo: merchant.logo,
});

const SelectMerchant = ({ filterCallback, ...props }: SelectMerchantType) => {
  let selectedMerchants: AdminMerchantDomain[] | null = null;
  let setSelectedMerchants: SetSelectedMerchants | null = null;
  let selectedMerchant: AdminMerchantDomain | null = null;
  let setSelectedMerchant: SetSelectedMerchant | null = null;

  if (props.isMulti === true) {
    selectedMerchants = props.selectedMerchants;
    setSelectedMerchants = props.setSelectedMerchants;
  } else {
    selectedMerchant = props.selectedMerchant;
    setSelectedMerchant = props.setSelectedMerchant;
  }

  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [cache, clearCache] = useState<object>({});
  const [previousSearchText, setPreviousSearchText] = useState<string>("");
  const [merchantOptions, setMerchantOptions] = useState<MerchantOptionTypes[]>(
    []
  );
  const [merchants, setMerchants] = useState<AdminMerchantDomain[]>([]);

  const hasMoreMerchants = (
    merchantsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (merchantsAmount < numberOfAllMerchantsPerLoad) {
      return false;
    }

    if (!currentPageAmount || currentPageAmount < numberOfAllMerchantsPerLoad) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    clearCache({});
    setMerchantOptions([]);
    setMerchants([]);
  }, [filterCallback]);

  const fetchMoreMerchants: LoadOptions<
    MerchantOptionTypes,
    GroupBase<MerchantOptionTypes>,
    unknown
  > = async (searchText = "", _loadedOptions) => {
    let offset = merchantOptions?.length || 0;

    if (searchText !== previousSearchText) {
      offset = 0;
    }

    setPreviousSearchText(searchText);

    const newMerchants = await AdminService.getAllMerchants(
      auth0Context,
      searchText,
      offset
    );

    const merchantIds = merchants.map((m) => m.id);
    const updatedMerchants: AdminMerchantDomain[] = merchants;
    for (const m of newMerchants) {
      if (!merchantIds.includes(m.id)) {
        updatedMerchants.push(m);
      }
    }
    setMerchants(updatedMerchants);

    const nextOptions = newMerchants.map<MerchantOptionTypes>(
      transformMerchantToOption
    );

    const newMerchantOptions: MerchantOptionTypes[] = merchantOptions?.length
      ? merchantOptions.concat(nextOptions)
      : nextOptions;

    setMerchantOptions(newMerchantOptions);

    return {
      options:
        typeof filterCallback !== "undefined"
          ? nextOptions.filter((m) => filterCallback(m.value))
          : nextOptions,
      hasMore: hasMoreMerchants(
        newMerchantOptions.length,
        newMerchants?.length || 0
      ),
    };
  };

  return (
    <AsyncPaginate
      loadOptions={fetchMoreMerchants}
      cacheUniqs={[cache, filterCallback]}
      placeholder="Select Merchant"
      onChange={(
        m: SingleValue<MerchantOptionTypes> | MultiValue<MerchantOptionTypes>
      ) => {
        if (setSelectedMerchants) {
          const merchantIds =
            (m as MerchantOptionTypes[])?.map((o) => o.value) || [];
          setSelectedMerchants(
            merchants.filter((merchant) => merchantIds.includes(merchant.id)) ||
              null
          );
        } else if (setSelectedMerchant) {
          setSelectedMerchant(
            merchants.find(
              (merchant) => (m as MerchantOptionTypes)?.value === merchant.id
            ) || null
          );
        }
        clearCache({});
        setMerchantOptions([]);
        setMerchants([]);
        setPreviousSearchText("");
      }}
      value={
        selectedMerchants
          ? selectedMerchants.map(transformMerchantToOption)
          : selectedMerchant
          ? transformMerchantToOption(selectedMerchant)
          : undefined
      }
      components={{
        Option: MerchantOption,
      }}
      styles={getReactSelectStyles(colorMode, colorScheme)}
      {...props}
    />
  );
};

export default SelectMerchant;
