import {
  Button,
  Flex,
  Box,
  Icon,
  Tooltip,
  useColorMode,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as NotesIcon } from "assets/icons/file-pen-regular.svg";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import TagsDomain from "entities/domain/tags/tags-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import { useAppSelector } from "redux/hooks";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import SummaryService from "services/summary";
import { format } from "date-fns";
import useMerchantStore from "hooks/use-merchant-store";
import ContactCardAccordionContent from "./ContactCardAccordionContent";

interface ContactNotesProps {
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
}

const ContactNotes = ({ contactToDisplay, isExpanded }: ContactNotesProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { editContact, getContactTags } = useContactsStore();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [notesValue, setNotesValue] = useState<string>(
    contactToDisplay.notes || ""
  );
  const [defaultNotesValue, setDefaultNotesValue] = useState<{
    value: string;
  }>({
    value: notesValue || "",
  });

  useEffect(() => {
    setNotesValue(contactToDisplay.notes || "");
    setDefaultNotesValue({
      value: contactToDisplay.notes || "",
    });
  }, [contactToDisplay.notes]);

  const auth0Context = useAuth0();

  const createSummary = async () => {
    if (isLoading) return;
    let newSummary = null;

    try {
      setIsLoading(true);
      newSummary = await SummaryService.generateSummary(
        auth0Context,
        contactToDisplay!.id!,
        merchant.id
      );
      setIsEditing(true);
      setDefaultNotesValue({
        value: `${
          defaultNotesValue.value ? `${defaultNotesValue.value}\n\n` : ""
        }**Summary from ${format(
          new Date(),
          "dd/MM/yyyy, hh:mm a"
        )}**\n\n${newSummary}`,
      });
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error("Error creating summary", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSaveInfo = async (latestNotes: string) => {
    editContact({
      id: contactToDisplay!.id!,
      name: contactToDisplay.name || undefined,
      surname: contactToDisplay.surname || undefined,
      tags: getContactTags(contactToDisplay).map((tag: TagsDomain) => tag.tag),
      tagIds: contactToDisplay.tagIds,
      address: contactToDisplay.address?.country
        ? {
            first_line: contactToDisplay.address.firstLine,
            second_line: contactToDisplay.address.secondLine,
            city: contactToDisplay.address.city,
            country: contactToDisplay.address.country,
            state: contactToDisplay.address.state,
            postcode: contactToDisplay.address.postcode,
          }
        : undefined,
      channels: contactToDisplay.channels.map((c: CustomerChannelDomain) => ({
        id: c.id,
        type: c.type,
        handle: c.handle,
        is_active: c.isActive,
      })),
      notes: latestNotes,
    });

    setDefaultNotesValue({ value: latestNotes });
  };

  return (
    <>
      <ContactCardAccordionContent
        isExpanded={isExpanded}
        sectionHeader={
          <Icon
            as={NotesIcon}
            boxSize={6}
            fill={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
            _hover={{ fill: colorMode === "dark" ? "gray.600" : "gray.300" }}
          />
        }
        sectionContent={
          <>
            <Flex
              justifyContent="center"
              alignItems="center"
              direction="column"
              px={4}
            >
              {isEditing && (
                <>
                  <Box
                    p={3}
                    borderColor={`${colorScheme}.400`}
                    borderWidth="1px"
                    borderStyle="solid"
                    borderRadius="md"
                    w="100%"
                  >
                    <EditorUltra
                      isDisabled={false}
                      defaultText={defaultNotesValue}
                      setText={(currentText: string) => {
                        setNotesValue(currentText);
                      }}
                      enabledPlugins={[
                        EditorUltraPlugin.AUTOFOCUS,
                        EditorUltraPlugin.RICHTEXT,
                      ]}
                    />
                    <Flex justifyContent="space-between" alignItems="center">
                      <IconButton
                        colorScheme="red"
                        variant="ghost"
                        aria-label="Cancel"
                        icon={<Icon as={CloseIcon} boxSize={3} />}
                        onClick={() => {
                          setIsEditing(false);
                          setNotesValue(defaultNotesValue.value);
                        }}
                      />
                      <IconButton
                        colorScheme="green"
                        variant="ghost"
                        aria-label="Save"
                        icon={<Icon as={CheckIcon} boxSize={4} />}
                        onClick={() => {
                          setIsEditing(false);
                          onSaveInfo(notesValue);
                        }}
                      />
                    </Flex>
                  </Box>
                </>
              )}
            </Flex>

            {!isEditing && notesValue && (
              <Tooltip
                label="Click to edit notes"
                px={3}
                py={1}
                backgroundColor="rgba(54, 178, 250, 0.9)"
                isDisabled={contactToDisplay.isArchived}
              >
                <Box
                  onClick={() => {
                    if (contactToDisplay.isArchived) {
                      return;
                    }
                    setIsEditing(true);
                  }}
                  cursor={contactToDisplay.isArchived ? "default" : "pointer"}
                  px={4}
                >
                  <EditorUltra
                    isEditable={false}
                    defaultText={defaultNotesValue}
                    setText={(currentText: string) => {
                      setNotesValue(currentText);
                    }}
                    enabledPlugins={[
                      EditorUltraPlugin.AUTOFOCUS,
                      EditorUltraPlugin.RICHTEXT,
                    ]}
                  />
                </Box>
              </Tooltip>
            )}

            {!isEditing && !notesValue && !contactToDisplay.isArchived && (
              <Flex justifyContent="center">
                <Button
                  colorScheme={colorScheme}
                  variant="ghost"
                  color="gray.400"
                  size="sm"
                  onClick={() => setIsEditing(true)}
                >
                  {" "}
                  Click here to add notes
                </Button>
              </Flex>
            )}

            {contactToDisplay.isArchived ? null : (
              <Flex justifyContent="center" mt={2}>
                <Button
                  colorScheme={colorScheme}
                  size="sm"
                  onClick={() => createSummary()}
                  isDisabled={isLoading}
                >
                  {" "}
                  Summarize conversation
                </Button>
              </Flex>
            )}
          </>
        }
      />
    </>
  );
};

export default ContactNotes;
