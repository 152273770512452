import { Text } from "@chakra-ui/react";
import FullTemplateDomain from "entities/domain/templates/full_template";
import React from "react";
import { RegularOrWhatsappTemplate } from "redux/features/templates";

const TemplateShortcutColumn = ({
  item,
}: {
  item: RegularOrWhatsappTemplate;
}): JSX.Element | null => {
  if (item instanceof FullTemplateDomain) {
    return item.shortcut ? <Text>/{item.shortcut}</Text> : null;
  }

  return null;
};

export default TemplateShortcutColumn;
