import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";

export interface DeleteCampaignPayload {
  id: string;
}

export interface PauseCampaignPayload {
  id: string;
}

export interface ResumeCampaignPayload {
  id: string;
}

export interface CancelCampaignPayload {
  id: string;
}

export interface GetCampaignCustomersPayload {
  id: string;
}

export interface CreateCampaignPayload {
  name: string;
  merchantId: number;
  status: CampaignStatus;
  audienceId: string;
  templateName: string | null;
  messageBody: string | null;
  sentAt: string | null;
  channel: ConversationChannel;
  scheduledAt: string | null;
  scheduledFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  file: File | null;
  idempotencyKey: string;
}

export interface EditCampaignPayload {
  id: string;
  name: string;
  merchantId: number;
  status: CampaignStatus;
  channel: ConversationChannel;
  audienceId: string;
  templateName: string | null;
  messageBody: string | null;
  sentAt: string | null;
  scheduledAt: string | null;
  scheduledFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  mediaUrl: string | null;
  mediaType: string | null;
  file: File | null;
}

export interface UpdateCampaignProgressPayload {
  id: string;
  totalRecipients: number;
  totalProcessed: number;
}

export const insertCampaignAccordingToStatus = (
  campaign: CampaignDomain,
  campaigns: CampaignDomain[]
): CampaignDomain[] => {
  const newCampaigns = campaigns.filter((c) => c.id !== campaign.id);
  const whereToInsert = newCampaigns.findIndex(
    (c) => c.status === campaign.status
  );

  return [
    ...newCampaigns.slice(0, whereToInsert),
    campaign,
    ...newCampaigns.slice(whereToInsert),
  ];
};

export const updateCampaignProgressById = (
  progressUpdate: UpdateCampaignProgressPayload,
  campaigns: CampaignDomain[]
): CampaignDomain[] => {
  const campaignIndex = campaigns.findIndex((c) => c.id === progressUpdate.id);
  const campaign = campaigns[campaignIndex];

  if (!campaign) {
    return campaigns;
  }

  const progress = Math.round(
    (progressUpdate.totalProcessed / progressUpdate.totalRecipients) * 100
  );

  const updatedCampaign = Object.setPrototypeOf(
    {
      ...campaigns[campaignIndex],
      progress: progress === 100 ? 99 : progress,
      status:
        campaign?.status === CampaignStatus.DRAFT && progress > 0
          ? CampaignStatus.PENDING
          : campaign.status,
    } as CampaignDomain,
    CampaignDomain.prototype
  );

  return [
    ...campaigns.slice(0, campaignIndex),
    updatedCampaign,
    ...campaigns.slice(campaignIndex + 1),
  ];
};

export const updateCampaignStatus = (
  campaignId: string,
  status: CampaignStatus,
  campaigns: CampaignDomain[]
) => {
  const whereToInsert = campaigns.findIndex((c) => c.id === campaignId);
  const updatedCampaign = Object.setPrototypeOf(
    {
      ...campaigns[whereToInsert],
      status,
    },
    CampaignDomain.prototype
  );
  return [
    ...campaigns.slice(0, whereToInsert),
    updatedCampaign,
    ...campaigns.slice(whereToInsert + 1),
  ];
};

export enum CampaignCategory {
  DraftScheduled = "draft_and_scheduled",
  Done = "done",
}

interface CampaignsState {
  loading: boolean;
  modalLoading: boolean;
  errors: any[];
  activeCampaignCategory: CampaignCategory | undefined;
  campaigns: CampaignDomain[];
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
}
const initialState: CampaignsState = {
  campaigns: [],
  loading: false,
  modalLoading: false,
  activeCampaignCategory: undefined,
  errors: [],
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    createCampaign: (state) => {
      state.modalLoading = true;
    },
    createCampaignSuccess: (state) => {
      state.modalLoading = false;
      state.toastMessage = {
        success: "Campaign created successfully!",
        errors: [],
        new: true,
      };
    },
    createCampaignFail: (state, action: PayloadAction<string[]>) => {
      state.modalLoading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    editCampaign: (state) => {
      state.modalLoading = true;
    },
    propagateCampaignUpdateAction: (
      state,
      action: PayloadAction<{ campaign: CampaignDomain }>
    ) => {
      state.campaigns = insertCampaignAccordingToStatus(
        action.payload.campaign,
        state.campaigns
      );
    },
    setCampaigns: (state, action: PayloadAction<CampaignDomain[]>) => {
      state.campaigns = action.payload;
    },
    editCampaignSuccess: (state, action: PayloadAction<CampaignDomain>) => {
      state.campaigns = insertCampaignAccordingToStatus(
        action.payload,
        state.campaigns
      );
      state.modalLoading = false;
      state.toastMessage = {
        success: "Campaign updated successfully!",
        errors: [],
        new: true,
      };
    },
    editCampaignFail: (state, action: PayloadAction<string[]>) => {
      state.modalLoading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    pauseCampaign: (state) => {
      state.loading = true;
    },
    resumeCampaign: (state) => {
      state.loading = true;
    },
    cancelCampaign: (state) => {
      state.loading = true;
    },
    deleteCampaign: (state) => {
      state.loading = true;
    },
    pauseCampaignFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.toastMessage = {
        success: "Failed to pause campaign!",
        errors: action.payload,
        new: true,
      };
    },
    resumeCampaignFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.toastMessage = {
        success: "Failed to resume campaign!",
        errors: action.payload,
        new: true,
      };
    },
    cancelCampaignFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.toastMessage = {
        success: "Failed to cancel campaign!",
        errors: action.payload,
        new: true,
      };
    },
    deleteCampaignSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.campaigns = state.campaigns.filter(
        (campaign: CampaignDomain) => campaign.id !== action.payload
      );
      state.toastMessage = {
        success: "Campaign deleted successfully!",
        errors: [],
        new: true,
      };
    },
    deleteCampaignFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    pauseCampaignSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.campaigns = updateCampaignStatus(
        action.payload,
        CampaignStatus.PAUSED,
        state.campaigns
      );
      state.toastMessage = {
        success: "Campaign paused successfully!",
        errors: [],
        new: true,
      };
    },
    resumeCampaignSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.campaigns = updateCampaignStatus(
        action.payload,
        CampaignStatus.PENDING,
        state.campaigns
      );
      state.toastMessage = {
        success: "Campaign resumed successfully!",
        errors: [],
        new: true,
      };
    },
    cancelCampaignSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.campaigns = updateCampaignStatus(
        action.payload,
        CampaignStatus.CANCELLED,
        state.campaigns
      );
      state.toastMessage = {
        success: "Campaign cancelled successfully!",
        errors: [],
        new: true,
      };
    },
    updateCampaignProgress: (
      state,
      action: PayloadAction<UpdateCampaignProgressPayload>
    ) => {
      state.campaigns = updateCampaignProgressById(
        action.payload,
        state.campaigns
      );
    },
    setActiveCampaignCategory: (
      state,
      action: PayloadAction<CampaignCategory | undefined>
    ) => {
      state.activeCampaignCategory = action.payload;
    },
  },
});

export const {
  createCampaign,
  createCampaignSuccess,
  createCampaignFail,
  editCampaign,
  editCampaignSuccess,
  editCampaignFail,
  pauseCampaign,
  pauseCampaignSuccess,
  pauseCampaignFail,
  resumeCampaign,
  resumeCampaignSuccess,
  resumeCampaignFail,
  cancelCampaign,
  cancelCampaignSuccess,
  cancelCampaignFail,
  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFail,
  setCampaigns,
  updateCampaignProgress,
  propagateCampaignUpdateAction,
  setActiveCampaignCategory,
} = campaignsSlice.actions;
export default campaignsSlice.reducer;
