import { Flex, useBreakpointValue, useToast } from "@chakra-ui/react";
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { AxiosError } from "axios";
import {
  $getRoot,
  $createParagraphNode,
  $createTextNode,
  LexicalEditor,
} from "lexical";
import useMessagesStore from "hooks/use-messages-store";
import { axiosErrorDataToDomainError } from "entities/domain/error/transformer";
import TemplateDomain, { CustomFields } from "entities/domain/templates";
import { useStateWithCallback } from "util/methods";
import { messageTemplateInsertion$ } from "util/constants";
import {
  OpenClosedFilter,
  setActiveConversationOpenClosedFilter,
} from "redux/features/conversations";
import InboxService from "services/inbox";
import { batch } from "react-redux";
import useAnalytics from "hooks/use-analytics";
import { MessageSubType } from "entities/domain/conversations/message-domain";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAttachments } from "redux/features/attachments";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import {
  appendMessage,
  messagesSelector,
  setAutoReplySuggestion,
} from "redux/features/messages";
import TypingZone from "./TypingZone";
import { Template } from "../new-message-input-box/RichTextArea";

const MESSAGE_CHAR_LIMIT = 1600;
const SUBJECT_CHAR_LIMIT = 100;

const MessageInput = () => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const {
    activeConversation,
    activeConversationId,
    templates: conversationTemplates,
  } = useAppSelector((state) => state.conversations);
  const { markConversationAsRead } = useMessagesStore();
  const conversationMessages = useAppSelector(messagesSelector);
  const { autoReplySuggestion } = useAppSelector((state) => state.messages);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { files } = useAppSelector((state) => state.attachments);
  const { track } = useAnalytics();
  const { merchant } = useAppSelector((state) => state.merchant);
  const toast = useToast();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const editorReference = useRef<LexicalEditor | undefined>();

  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: "",
  });
  const [text, setText] = useState<string>("");
  const [template, setTemplate] = useStateWithCallback<Template | null>(null);
  const [subject, setSubject] = useState<string>();
  const [showNoSubjectConfirmation, setShowNoSubjectConfirmation] =
    useState<boolean>(false);
  const [noSujectConfirmationAction, setNoSubjectConfirmationAction] =
    useState<() => void>();

  const updateEditorText = useCallback(
    (newTextForEditor: string = "") => {
      editorReference?.current?.update(() => {
        const root = $getRoot();
        root.clear();
        const p = $createParagraphNode();
        p.append($createTextNode(newTextForEditor));
        root.append(p);
        p.selectEnd();
      });
    },
    [editorReference.current]
  );

  const clearInput = () => {
    updateEditorText();
    setTemplate(null);
    setText("");
    setEditorText({
      value: "",
    });
    dispatch(clearAttachments());
  };

  useEffect(() => {
    clearInput();
  }, [activeConversation?.id]);

  const templatesShortcuts = conversationTemplates.reduce(
    (shortcuts: { [key: string]: Template }, t: Template) => {
      const newShortcuts = { ...shortcuts };

      if (t.shortcut) {
        newShortcuts[t.shortcut] = t;
      }

      return newShortcuts;
    },
    {}
  );

  const addOrReplaceCustomField = useCallback(
    (key: string, value: string) => {
      if (!template) {
        return;
      }

      setTemplate({
        ...template,
        id: template.id,
        customFields: {
          ...template.customFields,
          [key]: value,
        },
      } as Template);
    },
    [template?.customFields]
  );

  // eslint-disable-next-line arrow-body-style
  const memoizedInsertMessageTemplate = useCallback(
    (t: Template) => {
      clearInput();
      setTemplate(t);
      setText(TemplateDomain.getTextFromTemplate(t.text, t.customFields));
      setEditorText({ value: t.text });
      updateEditorText(t.text);
    },
    [activeConversationId]
  );

  useEffect(() => {
    const subscription = messageTemplateInsertion$.subscribe((t: Template) => {
      memoizedInsertMessageTemplate({
        id: t.id,
        name: t.name,
        title: t.title,
        text: t.text,
        customFields: t.customFields,
        shortcut: t.shortcut,
        subject: t.subject,
        favourite: t.favourite,
        channels: t.channels,
        mediaType: t.mediaType,
        mediaUrl: t.mediaUrl,
      });
    });

    // eslint-disable-next-line consistent-return
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (autoReplySuggestion) {
      setText(autoReplySuggestion);
      setEditorText({
        value: autoReplySuggestion,
      });
      updateEditorText(autoReplySuggestion);
      setTemplate(null);
      editorReference?.current?.focus(() => {}, {
        defaultSelection: "rootEnd",
      });

      dispatch(setAutoReplySuggestion(undefined));
    }
  }, [autoReplySuggestion]);

  useEffect(() => {
    const bringFocusToEditor = setTimeout(() => {
      if (!editorReference || !editorReference.current) {
        return;
      }

      editorReference.current.focus(() => {}, {
        defaultSelection: "rootEnd",
      });
    }, 0);

    return () => {
      clearTimeout(bringFocusToEditor);
    };
  }, [editorText, editorReference.current]);

  const [sendPossible, setSendPossible] = useState<boolean>(false);

  const isSendPossible = (): boolean => {
    if (activeConversation?.isChannelDisconnected(merchant)) {
      return false;
    }

    const isFileAttached = files.length > 0;
    const messageIsEmpty = (text.trim().length || 0) === 0;
    const messageExceedsLimits = (text.length || 0) > MESSAGE_CHAR_LIMIT;
    const templateHasCustomFieldsWithoutValue = template
      ? TemplateDomain.containsCustomFieldsWithoutValue(
          template.text,
          template.customFields
        )
      : false;

    if (templateHasCustomFieldsWithoutValue) {
      return false;
    }

    return (
      isFileAttached ||
      !messageIsEmpty ||
      (!messageIsEmpty && !messageExceedsLimits)
    );
  };

  useEffect(() => {
    setSendPossible(isSendPossible());
  }, [template, files, text, merchant, activeConversation]);

  useEffect(() => {
    return () => {
      clearAttachments();
    };
  }, []);

  const sendMessage = useCallback(async () => {
    if (!sendPossible) {
      return;
    }

    clearInput();

    try {
      if (files.length) {
        const data = new FormData();
        const file = files[files.length - 1]; // Select last appended file just in case
        data.append("file", file);
        data.append("body", text);
        data.append("conversation_id", activeConversation!.id.toString());

        if (activeConversation!.isEmailChannel()) {
          data.append("title", subject?.slice(0, SUBJECT_CHAR_LIMIT) || "");
          data.append(
            "reply_to_message_id",
            conversationMessages
              .filter(({ subType }) => subType === MessageSubType.REGULAR)
              .pop()
              ?.id.toString() || ""
          );
        }

        if (template) {
          if (activeConversation!.isTemplatesOnly() || template.text === text) {
            data.append("template_name", template.name);
          }
          data.append("custom_fields", JSON.stringify(template.customFields));
        }

        await InboxService.sendAttachment(
          auth0Context,
          data,
          merchant.id,
          activeConversation!.id
        ).then((response) => {
          track("send_message", {
            conversation_id: activeConversation!.id,
            channel: activeConversation!.channel,
            contains_files: true,
            customer_id: activeConversation!.customerId,
            template_id: template?.id,
          });
          batch(() => {
            dispatch(
              appendMessage({
                message: response,
                conversationId: activeConversation!.id,
              })
            );
            dispatch(
              setActiveConversationOpenClosedFilter(OpenClosedFilter.Open)
            );
          });
        });
      } else {
        const requestBody: {
          body: string;
          conversation_id: number;
          title?: string;
          reply_to_message_id?: number;
          template_name?: string;
          custom_fields?: CustomFields;
        } = {
          body: text,
          conversation_id: activeConversation!.id,
        };

        if (activeConversation!.isEmailChannel()) {
          requestBody.title = subject?.slice(0, SUBJECT_CHAR_LIMIT);
          requestBody.reply_to_message_id = conversationMessages
            .filter(({ subType }) => subType === MessageSubType.REGULAR)
            .pop()?.id;
        }

        if (template) {
          if (activeConversation!.isTemplatesOnly() || template.text === text) {
            requestBody.template_name = template.name;
          }
          requestBody.custom_fields = template.customFields;
        }

        await InboxService.sendMessage(
          auth0Context,
          requestBody,
          merchant.id,
          activeConversation!.id
        ).then((response) => {
          track("send_message", {
            conversation_id: activeConversation!.id,
            channel: activeConversation!.channel,
            contains_files: false,
            customer_id: activeConversation!.customerId,
            template_id: template?.id,
          });
          batch(() => {
            dispatch(
              appendMessage({
                message: response,
                conversationId: activeConversation!.id,
              })
            );
            dispatch(
              setActiveConversationOpenClosedFilter(OpenClosedFilter.Open)
            );
          });
        });
      }
    } catch (_error) {
      if (_error instanceof AxiosError) {
        const error = _error as AxiosError;
        const domainError = axiosErrorDataToDomainError(error.response?.data);
        toast({
          status: "error",
          title: domainError?.errorMessage,
        });
      }
    }
  }, [
    activeConversation,
    template,
    subject,
    text,
    conversationMessages,
    files,
    sendPossible,
  ]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (isBaseSize) {
      return;
    }

    if (!sendPossible) {
      return;
    }

    if (
      isBaseSize &&
      activeConversation?.isEmailChannel() &&
      e.key === "Enter"
    ) {
      return;
    }

    if (
      !activeConversation?.isEmailChannel() &&
      e.key === "Enter" &&
      !e.shiftKey
    ) {
      e.preventDefault();
      sendMessage();
      return;
    }

    if (
      activeConversation?.isEmailChannel() &&
      e.key === "Enter" &&
      e.shiftKey
    ) {
      if (!subject?.trim()) {
        setShowNoSubjectConfirmation(true);
        setNoSubjectConfirmationAction(() => sendMessage);

        return;
      }

      e.preventDefault();
      sendMessage();

      return;
    }
  };

  const onTextChange = useCallback(
    (currentText: string) => {
      if (!currentText) {
        setTemplate(null);
      }

      if (currentText === text) {
        return;
      }

      if (activeConversation && activeConversation.unreadCount > 0) {
        setTimeout(() => {
          markConversationAsRead(
            activeConversation!.id,
            activeConversation!.messageId,
            currentAgent!.id
          );
        }, 1000);
      }

      const templateShortcut = /^\/(.*)$/;
      const shortcutAttempt = currentText.match(templateShortcut);
      const foundTemplate =
        shortcutAttempt && templatesShortcuts[shortcutAttempt[1]];

      if (foundTemplate) {
        setTemplate({
          id: foundTemplate.id,
          name: foundTemplate.name,
          title: foundTemplate.title,
          customFields: foundTemplate.customFields,
          text: foundTemplate.text,
          shortcut: foundTemplate.shortcut,
          channels: foundTemplate.channels,
          subject: foundTemplate.subject,
          favourite: foundTemplate.favourite,
          mediaType: foundTemplate.mediaType,
          mediaUrl: foundTemplate.mediaUrl,
        });
        setText(
          TemplateDomain.getTextFromTemplate(
            foundTemplate.text,
            foundTemplate.customFields
          )
        );

        setEditorText({
          value: foundTemplate.text,
        });
        updateEditorText(foundTemplate.text);

        return;
      }

      setText(currentText);
    },
    [activeConversation, text, templatesShortcuts]
  );

  return (
    <Flex
      justifyContent="center"
      transition="all .5s linear"
      alignItems="center"
      gridGap={2}
      w="100%"
      onKeyDown={
        handleKeyPress as unknown as KeyboardEventHandler<HTMLDivElement>
      }
    >
      <TypingZone
        updateEditorText={updateEditorText}
        template={template}
        editorReference={editorReference}
        defaultText={editorText}
        subject={subject}
        setSubject={setSubject}
        onTextChange={onTextChange}
        textToSend={text}
        isSendPossible={sendPossible}
        clearInput={clearInput}
        sendMessage={async () => {
          if (activeConversation?.isEmailChannel() && !subject?.trim()) {
            setShowNoSubjectConfirmation(true);
            setNoSubjectConfirmationAction(() => sendMessage);
          } else {
            sendMessage();
          }
        }}
        customFields={
          template ? (template as Template).customFields : undefined
        }
        addOrReplaceCustomField={addOrReplaceCustomField}
      />
      <ConfirmationDialog
        headerText="Are you sure?"
        messageText="This message has no subject. Are you sure you want to send it?"
        buttonText="Send"
        cancelButtonText="Cancel"
        isOpen={showNoSubjectConfirmation}
        isDangerous={false}
        setIsOpen={setShowNoSubjectConfirmation}
        confirmationCallback={noSujectConfirmationAction || (() => {})}
      />
    </Flex>
  );
};

export default MessageInput;
