import { useAuth0 } from "@auth0/auth0-react";
import React, { Box, Flex, StyleProps, Text } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import {
  SocketDeletedMessage,
  SocketMessage,
  SocketPromptCreated,
  SocketPromptDeleted,
} from "entities/ISocketArgs";
import MessageDomain, {
  MessageDirection,
} from "entities/domain/conversations/message-domain";
import PromptDomain, { PromptAction, PromptType } from "entities/domain/prompt";
import { messageTransformFromDtoToDomain } from "entities/transformers/conversationTransformers";
import useDebounce from "hooks/use-debounce";
import useMessagesStore from "hooks/use-messages-store";
import { useWebSocket } from "hooks/use-socket";
import { Ref, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import {
  appendMessage,
  deleteMessage,
  messagesSelector,
} from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import useDeepCompareEffect from "use-deep-compare-effect";
import { numberOfMessagesPerLoad } from "util/constants";
import MessageComponent from "../message";
import MessageInput from "../message-input";
import EmailRaw from "../message/EmailRaw";
import Notification from "../notification";
import Prompt from "../prompt";
import MessageRow from "./MessageRow";
import SkeletonOverlay from "./SkeletonOverlay";
import Unread from "./Unread";
import BackgroundSettings from "./BackgroundSettings";

const shouldShowTail = (
  message: MessageDomain,
  index: number,
  currentMessagesArray: MessageDomain[]
) => {
  if (message.direction === MessageDirection.UNSPECIFIED) {
    return false;
  }

  const nextMessage = currentMessagesArray[index + 1];

  if (!nextMessage) {
    return true;
  }

  return (
    nextMessage.isIncoming !== message.isIncoming ||
    nextMessage.direction === MessageDirection.UNSPECIFIED
  );
};

const isFirstUnread = (
  index: number,
  messages: MessageDomain[] | undefined
): boolean => {
  if (!messages || !messages.length) {
    return false;
  }

  const message = messages[index];
  const previousIncomingMessage = messages
    .slice(0, index)
    .reverse()
    .filter((m) => m.isIncoming)[0];

  return (
    (message.isRead === false && !messages[index - 1]) ||
    (message.isRead === false && previousIncomingMessage?.isRead === true)
  );
};

interface MessageListProps {
  isFirstLoad: boolean;
  onBackgroundChange: (backgroundStyles: StyleProps) => void;
}

const MessageList = ({ isFirstLoad, onBackgroundChange }: MessageListProps) => {
  const auth0Context = useAuth0();
  const emailMessageToShow = useAppSelector(
    (state) => state.messages.emailMessageToShow
  );
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { activeConversation, activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { markConversationAsRead, fetchMoreMessages } = useMessagesStore();
  const [inViewRef] = useInView({
    threshold: 0,
    onChange(inView: boolean) {
      if (inView) {
        setTimeout(() => {
          markConversationAsRead(
            activeConversation!.id,
            activeConversation!.messageId,
            currentAgent!.id
          );
        }, 500);
      }
    },
  });
  const { isLoadingMessages } = useAppSelector((state) => state.messages);
  const messages = useAppSelector(messagesSelector);
  const [lastActiveConversationId, setLastActiveConversationId] = useState<
    number | undefined
  >();
  const [previousMessagesLength, setPreviousMessagesLength] = useState<number>(
    (messages && messages.length) || 0
  );
  const [loadedPrompt, setLoadedPrompt] = useState<PromptDomain | null>(null);

  const listRef = useRef<ViewportListRef | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const debouncedIsInitialLoad = useDebounce(isInitialLoad, 50);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const { addEventHandler, removeEventHandler } = useWebSocket();

  const [newInboundMessage, setNewInboundMessage] = useState(
    {} as SocketMessage
  );
  const [newUpdateMessage, setNewUpdateMessage] = useState({} as SocketMessage);
  const [newDeleteMessage, setNewDeleteMessage] = useState(
    {} as SocketDeletedMessage
  );
  const [newDeletePrompt, setNewDeletePrompt] = useState(
    {} as SocketPromptDeleted
  );
  const [newCreatePrompt, setNewCreatePrompt] = useState(
    {} as SocketPromptCreated
  );
  const [emailMessageIndex, setEmailMessageIndex] = useState<number | null>(
    null
  );
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);

  const rowRef = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
  const unreadRef = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isLoadingMessages) {
      return;
    }

    setIsInitialLoad(false);
  }, [isLoadingMessages]);

  useEffect(() => {
    if (debouncedIsInitialLoad) {
      return;
    }

    setShouldShowSkeleton(false);
  }, [debouncedIsInitialLoad]);

  useEffect(() => {
    setIsInitialLoad(true);
    setShouldShowSkeleton(true);
  }, [activeConversationId]);

  useEffect(() => {
    if (!messages || !messages.length) {
      return;
    }

    setHasMoreMessages(messages.length % numberOfMessagesPerLoad === 0);
  }, [messages?.length]);

  const fetchMore = () => {
    if (!hasMoreMessages || !activeConversationId) {
      return;
    }

    fetchMoreMessages(activeConversationId, messages?.length || 0, merchant.id);
  };

  useEffect(() => {
    if (
      isLoadingMessages ||
      !messages ||
      !messages.length ||
      emailMessageToShow ||
      emailMessageIndex !== null
    ) {
      return;
    }

    const shiftLength = Math.abs(messages.length - previousMessagesLength);

    if (previousMessagesLength !== messages.length) {
      setPreviousMessagesLength(messages.length);
    }

    const scrollIndexPosition =
      listRef?.current?.getScrollPosition().index || messages.length; // default to bottom

    const hasScrolledTooFar =
      messages.length <= 20
        ? false
        : scrollIndexPosition < messages.length - 20;

    if (
      (shiftLength === 1 && !hasScrolledTooFar) ||
      (shiftLength > 1 && messages.length <= 40)
    ) {
      scrollToBottom();
    } else if (shiftLength > 1 && emailMessageIndex === null) {
      listRef?.current?.scrollToIndex({
        index: messages.length - previousMessagesLength - 1,
      });
    }
  }, [messages?.length]);

  useEffect(() => {
    if (emailMessageToShow) {
      const index = messages.findIndex((m) => m.id === emailMessageToShow.id);

      setEmailMessageIndex(index);

      return;
    } else if (
      !emailMessageToShow &&
      emailMessageIndex !== null &&
      listRef?.current
    ) {
      listRef?.current?.scrollToIndex({
        index: emailMessageIndex,
      });

      setEmailMessageIndex(null);

      return;
    }
  }, [emailMessageToShow, listRef?.current]);

  useEffect(() => {
    if (!activeConversationId) {
      return;
    }

    fetchMoreMessages(activeConversationId, 0, merchant.id);
  }, [activeConversationId]);

  useEffect(() => {
    if (!activeConversation) {
      return;
    }

    if (!messages || !messages.length) {
      return;
    }

    if (!listRef || !listRef.current) {
      return;
    }

    const newFirstUnreadMessage = messages.filter(
      (msg) => msg.isRead === false
    )[0];

    if (newFirstUnreadMessage && !newFirstUnreadMessage.isIncoming) {
      setTimeout(() => {
        markConversationAsRead(
          activeConversation!.id,
          activeConversation!.messageId,
          currentAgent!.id
        );
      }, 250);
    } else if (activeConversation.unreadCount && !newFirstUnreadMessage) {
      fetchMoreMessages(
        activeConversation!.id,
        messages?.length || 0,
        merchant.id
      );
    }
  }, [activeConversationId, activeConversation?.unreadCount, messages]);

  useEffect(() => {
    if (lastActiveConversationId !== activeConversationId) {
      setLastActiveConversationId(activeConversationId || undefined);
      setPreviousMessagesLength(0);
    }
  }, [activeConversationId]);

  function scrollToBottom(): void {
    if (!listRef || !listRef.current || !messages || !messages.length) {
      return;
    }

    listRef.current.scrollToIndex({
      index: messages.length - 1,
    });
  }

  const [isListOverflown, setIsListOverflown] = useState<boolean>(false);

  useEffect(() => {
    if (!activeConversationId) {
      setLoadedPrompt(null);
      return;
    }

    InboxService.getPrompts(
      auth0Context,
      merchant.id,
      activeConversationId
    ).then((prompts) => {
      if (prompts.length > 0) {
        const prompt = prompts[0];

        setLoadedPrompt(prompt);
      } else {
        setLoadedPrompt(null);
      }
    });
  }, [activeConversationId]);

  useLayoutEffect(() => {
    const { current } = scrollContainerRef;

    if (!current) {
      setIsListOverflown(false);
      return;
    }

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsListOverflown(hasOverflow);
    };

    if ("ResizeObserver" in window) {
      new ResizeObserver(trigger).observe(current);
    }

    trigger();
  }, [scrollContainerRef]);

  const handleMessageUpdate = (args: SocketMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setNewUpdateMessage(args);
  };

  useDeepCompareEffect(() => {
    if (Object.keys(newUpdateMessage).length === 0) {
      return;
    }

    const message: MessageDomain = messageTransformFromDtoToDomain(
      newUpdateMessage.message
    );

    if (
      activeConversationId === undefined ||
      activeConversationId !== message.conversationId
    ) {
      return;
    }

    dispatch(
      appendMessage({ conversationId: message.conversationId, message })
    );
  }, [newUpdateMessage]);

  const handleMessageDeleted = (args: SocketDeletedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setNewDeleteMessage(args);
  };

  const handlePromptDeleted = (args: SocketPromptDeleted) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setNewDeletePrompt(args);
  };

  useDeepCompareEffect(() => {
    if (Object.keys(newDeleteMessage).length === 0) {
      return;
    }

    dispatch(
      deleteMessage({
        messageId: newDeleteMessage.messageId,
        conversationId: newDeleteMessage.conversationId,
      })
    );
  }, [newDeleteMessage]);

  useEffect(() => {
    if (!loadedPrompt) {
      return;
    }

    if (Object.keys(newDeletePrompt).length === 0) {
      return;
    }

    if (newDeletePrompt.prompt.conversation_id !== activeConversationId) {
      return;
    }

    if (newDeletePrompt.prompt.id !== loadedPrompt.id) {
      return;
    }

    setLoadedPrompt(null);
  }, [newDeletePrompt]);

  const handlePromptCreated = (args: SocketPromptCreated) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setNewCreatePrompt(args);
  };

  useDeepCompareEffect(() => {
    if (Object.keys(newCreatePrompt).length === 0) {
      return;
    }

    if (!newCreatePrompt.prompt) {
      return;
    }

    const newPrompt = new PromptDomain(
      newCreatePrompt.prompt.id,
      newCreatePrompt.prompt.conversation_id,
      newCreatePrompt.prompt.body,
      newCreatePrompt.prompt.type as PromptType,
      newCreatePrompt.prompt.actions as PromptAction[],
      newCreatePrompt.prompt.last_updated_at,
      newCreatePrompt.prompt.payload
    );

    if (activeConversationId === newPrompt.conversationId) {
      setLoadedPrompt(newPrompt);
    }

    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, [newCreatePrompt]);

  const handleInboundMessage = (args: SocketMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setNewInboundMessage(args);
  };

  useDeepCompareEffect(() => {
    if (Object.keys(newInboundMessage).length === 0) {
      return;
    }

    const message: MessageDomain = messageTransformFromDtoToDomain(
      newInboundMessage.message
    );

    if (activeConversationId === message.conversationId) {
      dispatch(
        appendMessage({
          conversationId: message.conversationId,
          message,
        })
      );
    }
  }, [newInboundMessage]);

  useEffect(() => {
    addEventHandler("deleted_message", handleMessageDeleted);
    addEventHandler("prompt_deleted", handlePromptDeleted);
    addEventHandler("prompt_created", handlePromptCreated);
    addEventHandler("message_update", handleMessageUpdate);
    addEventHandler("inbound_message", handleInboundMessage);

    return () => {
      removeEventHandler("deleted_message", handleMessageDeleted);
      removeEventHandler("prompt_deleted", handlePromptDeleted);
      removeEventHandler("prompt_created", handlePromptCreated);
      removeEventHandler("message_update", handleMessageUpdate);
      removeEventHandler("inbound_message", handleInboundMessage);
    };
  }, [addEventHandler, removeEventHandler]);

  return (
    <Flex
      h="100%"
      w="100%"
      direction="column"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
    >
      <Box
        px={{ base: 0, lg: 4 }}
        width="100%"
        pt={4}
        pb={0}
        ref={scrollContainerRef}
        flexGrow={1}
        flexShrink={0}
        flexBasis="1px"
        overflowY="auto"
        id="messages-list"
      >
        <BackgroundSettings onBackgroundChange={onBackgroundChange} />
        {emailMessageToShow ? (
          <EmailRaw message={emailMessageToShow} />
        ) : (
          <>
            {!isLoadingMessages && !hasMoreMessages && isListOverflown ? (
              <Flex alignItems="center" justifyContent="center" w="100%" py={8}>
                <Text>✅ No more messages found</Text>
              </Flex>
            ) : null}
            {shouldShowSkeleton ? (
              <SkeletonOverlay isInitialLoad={isFirstLoad} />
            ) : null}
            <ViewportList
              viewportRef={scrollContainerRef}
              items={messages || []}
              indexesShift={messages.length - 1}
              itemSize={60}
              itemMargin={0}
              overscan={10}
              withCache={false}
              ref={listRef}
              onViewportIndexesChange={([startIndex, endIndex]) => {
                if (
                  !messages ||
                  !messages.length ||
                  isLoadingMessages ||
                  emailMessageToShow ||
                  emailMessageIndex !== null
                ) {
                  return;
                }

                if (startIndex === 0 && endIndex !== messages.length - 1) {
                  fetchMore();
                }
              }}
            >
              {(message, index, currentMessagesArray) => (
                <MessageRow
                  ref={isFirstUnread(index, messages) ? inViewRef : undefined}
                  key={message.id}
                  isFirstUnread={isFirstUnread(index, messages)}
                  messageId={message.id}
                >
                  {isFirstUnread(index, messages) && (
                    <Unread
                      ref={unreadRef as Ref<HTMLButtonElement>}
                      showGoToRecent={false}
                    />
                  )}

                  {message.isNotification() ? (
                    <Notification
                      ref={rowRef as Ref<HTMLDivElement>}
                      message={message}
                    />
                  ) : (
                    <MessageComponent
                      ref={rowRef as Ref<HTMLDivElement>}
                      value={message}
                      showTail={shouldShowTail(
                        message,
                        index,
                        currentMessagesArray
                      )}
                    />
                  )}
                </MessageRow>
              )}
            </ViewportList>
          </>
        )}

        {loadedPrompt ? <Prompt loadedPrompt={loadedPrompt!} /> : null}
      </Box>

      <Box
        width="100%"
        flexGrow={0}
        flexShrink={0}
        flexBasis="1px"
        mb={{ base: 0, lg: 4 }}
        px={{ base: 0, lg: 4 }}
        ref={inputRef}
        zIndex={50}
      >
        <MessageInput />
      </Box>
    </Flex>
  );
};

export default MessageList;
