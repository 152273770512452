import CustomerChannelDomain, {
  MarketingStatus,
} from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import CustomerAddressDomain from "entities/domain/customers/customer-address-domain";
import {
  ContactAddressDTO,
  ContactChannelsDTO,
  ContactDTO,
} from "entities/dto/ContactDTO";
import { getChannelIcon } from "util/constants";

interface ChannelOptionTypes {
  value: string;
  label: string;
  icon?: string;
  color?: string;
}

export const transformFromContactChannelToOptions = (
  channel: CustomerChannelDomain
): ChannelOptionTypes => ({
  value: channel.id!,
  label: `${channel.getDisplayChannelName()} (${channel.displayHandle})`,
  icon: getChannelIcon(channel.type),
});

export const contactChannelTransformFromDtoToDomain = (
  channel: ContactChannelsDTO
): CustomerChannelDomain =>
  new CustomerChannelDomain(
    channel.id,
    channel.type,
    channel.handle,
    channel.display_handle,
    channel.is_active,
    channel.marketing_status as MarketingStatus
  );

export const contactAddressTransformFromDtoToDomain = (
  address: ContactAddressDTO
): CustomerAddressDomain =>
  new CustomerAddressDomain(
    address.first_line,
    address.second_line,
    address.city,
    address.country,
    address.state,
    address.postcode
  );

export const contactTransformFromDtoToDomain = (
  contact: ContactDTO
): ContactDomain =>
  new ContactDomain(
    contact.id,
    contact.name,
    contact.surname,
    contact.address
      ? contactAddressTransformFromDtoToDomain(contact.address)
      : undefined,
    contact.channels.map((chan) =>
      contactChannelTransformFromDtoToDomain(chan)
    ),
    contact.full_name,
    contact.type,
    contact.last_contact,
    contact.avatar,
    contact.tag_ids,
    contact.notes,
    contact.archived
  );

export const contactListTransformFromDtoToDomain = (
  contacts: ContactDTO[],
  totalCount: string
): ContactListDomain =>
  new ContactListDomain(
    contacts.map(contactTransformFromDtoToDomain),
    Number(totalCount)
  );
