import { useAuth0 } from "@auth0/auth0-react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import KeyloopAccount from "entities/domain/keyloop-account";
import KeyloopPrivacyStatement from "entities/domain/keyloop-privacy-statement";
import KeyloopUser from "entities/domain/keyloop-user";
import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import IntegrationService from "services/integrations";
import { getReactSelectStyles } from "util/methods";

interface EditAccountProps {
  selectedKeyloopAccount: KeyloopAccount | null;
}

interface KeyloopUserOption {
  value: string;
  label: string;
}

interface KeyloopPrivacyStatementOption {
  value: string;
  label: string;
}

const EditAccount = ({ selectedKeyloopAccount }: EditAccountProps) => {
  const auth0Context = useAuth0();
  const toast = useToast();
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [selectedPrivacyStatementId, setSelectedPrivacyStatemtentId] = useState<
    string | null
  >(selectedKeyloopAccount?.metadata.privacyStatementId || null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(
    selectedKeyloopAccount?.metadata.userId || null
  );
  const [showDisconnectAccountModal, setShowDisconnectAccountModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [privacyStatements, setPrivacyStatements] = useState<
    KeyloopPrivacyStatement[]
  >([]);
  const [keyloopUsers, setKeyloopUsers] = useState<KeyloopUser[]>([]);
  const [privacyStatementOptions, setPrivacyStatementOptions] = useState<
    KeyloopPrivacyStatementOption[]
  >([]);
  const [keyloopUserOptions, setKeyloopUserOptions] = useState<
    KeyloopUserOption[]
  >([]);

  const updateKeyloopAccount = async () => {
    if (!selectedKeyloopAccount) {
      return;
    }

    try {
      await IntegrationService.updateKeyloopAccount(
        auth0Context,
        selectedPrivacyStatementId!,
        merchant.id,
        selectedKeyloopAccount.enterpriseId,
        selectedKeyloopAccount.storeId,
        selectedUserId!
      );

      toast({
        status: "success",
        title: "Keyloop account updated successfully!",
      });

      navigate(`/${merchant.id}/settings/integrations`);
    } catch (error: unknown) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not update the selected Keyloop privacy statement at this time. Please try again later or contact our customer support!",
      });
    }
  };

  const disconnectKeyloopAccount = async () => {
    if (!selectedKeyloopAccount) {
      return;
    }

    try {
      await IntegrationService.disconnectKeyloopAccount(
        auth0Context,
        merchant.id,
        selectedKeyloopAccount.enterpriseId,
        selectedKeyloopAccount.storeId
      );

      toast({
        status: "success",
        title: "Keyloop account disconnected successfully!",
      });

      navigate(`/${merchant.id}/settings/integrations`);
    } catch (error: unknown) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not disconnect the Keyloop account at this time. Please try again later or contact our customer support!",
      });
    }
  };

  const fetchKeyloopPrivacyStatements = async () => {
    setIsLoading(true);

    try {
      const fetchedPrivacyStatements =
        await IntegrationService.getKeyloopPrivacyStatements(
          auth0Context,
          merchant.id
        );

      setPrivacyStatements(fetchedPrivacyStatements);
      setPrivacyStatementOptions(
        fetchedPrivacyStatements.map((ps) => ({
          value: ps.id,
          label: ps.description,
        }))
      );
    } catch (error: unknown) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not fetch Keyloop privacy statements at this time. Please try again later or contact our customer support!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKeyloopUsers = async () => {
    if (!selectedKeyloopAccount) {
      return;
    }

    setIsLoading(true);

    try {
      const fetchedUsers = await IntegrationService.getKeyloopUsers(
        auth0Context,
        merchant.id,
        selectedKeyloopAccount!.enterpriseId,
        selectedKeyloopAccount!.storeId
      );

      setKeyloopUsers(fetchedUsers);
      setKeyloopUserOptions(
        fetchedUsers.map((ku) => ({
          value: ku.id,
          label: ku.name || ku.email || "Unknown User",
        }))
      );
    } catch (error: unknown) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not fetch Keyloop users at this time. Please try again later or contact our customer support!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedPrivacyStatemtentId(
      selectedKeyloopAccount?.metadata.privacyStatementId || null
    );
    fetchKeyloopPrivacyStatements();
    fetchKeyloopUsers();
  }, [selectedKeyloopAccount]);

  return !selectedKeyloopAccount ? null : (
    <>
      {isLoading ? <Spinner /> : null}
      {!isLoading &&
      (privacyStatements.length === 0 || keyloopUsers.length === 0) ? (
        <Alert status="warning" my={4} borderRadius="xl">
          <AlertIcon />
          No Privacy Statements or Users available at the moment. Please try
          again later.
        </Alert>
      ) : null}
      <FormControl isRequired>
        <FormLabel>Select Privacy Statement</FormLabel>
        <Select
          onChange={(v) => setSelectedPrivacyStatemtentId(v?.value || null)}
          options={privacyStatementOptions}
          value={
            privacyStatementOptions.find(
              (pso) => pso.value === selectedPrivacyStatementId
            ) || null
          }
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Select User</FormLabel>
        <Select
          onChange={(v) => setSelectedUserId(v?.value || null)}
          options={keyloopUserOptions}
          value={
            keyloopUserOptions.find((kuo) => kuo.value === selectedUserId) ||
            null
          }
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
      </FormControl>

      <HStack
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
      >
        <Button
          colorScheme="red"
          variant="ghost"
          onClick={() => {
            setShowDisconnectAccountModal(true);
          }}
        >
          Disconnect
        </Button>
        <Button
          size="md"
          colorScheme={colorScheme}
          isDisabled={!selectedPrivacyStatementId || !selectedUserId}
          onClick={() => {
            updateKeyloopAccount();
          }}
        >
          Confirm
        </Button>
      </HStack>
      <ConfirmationDialog
        headerText="Disconnect this Keyloop account?"
        messageText="If you disconnect this Keyloop account, you will no longer be able to sync inventory and customer data from this account. Are you sure you want to disconnect this account?"
        buttonText="Yes"
        cancelButtonText="No"
        isOpen={showDisconnectAccountModal}
        setIsOpen={setShowDisconnectAccountModal}
        confirmationCallback={() => {
          setShowDisconnectAccountModal(false);
          disconnectKeyloopAccount();
        }}
      />
    </>
  );
};

export default EditAccount;
