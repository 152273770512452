import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Flex,
  Icon,
  StepIcon,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useBreakpointValue,
  useSteps,
  useColorMode,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as TuneIcon } from "assets/icons/tune.svg";
import { ReactComponent as AudienceIcon } from "assets/icons/account-group-outline.svg";
import { ReactComponent as FountainPenIcon } from "assets/icons/fountain-pen.svg";
import { ReactComponent as LaunchIcon } from "assets/icons/rocket-launch-outline.svg";
import Topbar from "components/shared/topbar/TopBar";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import useAudiencesStore from "hooks/use-audiences-store";
import useAnalytics from "hooks/use-analytics";
import AudienceDomain from "entities/domain/audience";

import AudiencesService from "services/audiences";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import useCampaignsStore from "hooks/use-campaigns-store";
import CampaignsService from "services/campaigns";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { propagateAudienceUpdate } from "redux/features/audiences";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { containsSuffix, removePrefixAndSuffix } from "util/methods";
import { format } from "date-fns";
import Tuning from "./steps/tuning";
import Audience from "./steps/audience";
import Message from "./steps/message";
import Preview from "./steps/preview";
import CampaignFormActionsBar from "./ActionsBar";
import WhatsappDocumentsModal from "./WhatsappDocumentsModal";

interface Action {
  text: "Send" | "Schedule" | "Save";
  requiresConfirmation: boolean;
  setShowConfirmation?: (show: boolean) => void;
  isConfirmed?: boolean;
  action: () => Promise<void>;
}

interface NewCampaignFormProps {}

const NewCampaignForm = (_props: NewCampaignFormProps) => {
  const stepsConfig = [
    {
      name: "Setup",
      icon: TuneIcon,
    },
    {
      name: "Message",
      icon: FountainPenIcon,
    },
    {
      name: "Audience",
      icon: AudienceIcon,
    },
    {
      name: "Preview & Launch",
      icon: LaunchIcon,
    },
  ];
  const { campaignId: id } = useParams<{ campaignId: string }>();
  const { track } = useAnalytics();
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: stepsConfig.length,
  });
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const toast = useToast();
  const { fetchAudiences } = useAudiencesStore();
  const { audiences } = useAppSelector((state) => state.audiences);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { editCampaign, createCampaign } = useCampaignsStore();
  const { campaigns } = useAppSelector((state) => state.campaigns);
  const [idempotencyKey, setIdempotencyKey] = useState<string | null>(null);

  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const navigate = useNavigate();
  const [isSavingCampaign, setIsSavingCampaign] = useState<boolean>(false);
  const [isSchedulingCampaign, setIsSchedulingCampaign] =
    useState<boolean>(false);
  const [isSendingCampaign, setIsSendingCampaign] = useState<boolean>(false);
  const [disabledSteps, setDisabledSteps] = useState<number[]>([0, 1, 2, 3]);
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [audience, setAudience] = useState<AudienceDomain | null>(null);

  const [campaignToEdit, setCampaignToEdit] = useState<CampaignDomain | null>(
    null
  );

  const [currentName, setCurrentName] = useState<string>("");
  const [currentStatus, setCurrentStatus] = useState<CampaignStatus>(
    CampaignStatus.DRAFT
  );
  const [currentAudienceId, setCurrentAudienceId] = useState<string | null>(
    null
  );
  const [currentTemplateName, setCurrentTemplateName] = useState<string | null>(
    null
  );
  const [currentMessageBody, setCurrentMessageBody] = useState<string | null>(
    null
  );
  const [currentSentAt, setCurrentSentAt] = useState<string | null>(null);
  const [currentScheduledAt, setCurrentScheduledAt] = useState<string | null>(
    null
  );
  const [currentScheduledFor, setCurrentScheduledFor] = useState<string | null>(
    null
  );
  const [currentCreatedAt, setCurrentCreatedAt] = useState<string | null>(null);
  const [currentUpdatedAt, setCurrentUpdatedAt] = useState<string | null>(null);
  const [currentChannel, setCurrentChannel] = useState<ConversationChannel>(
    ConversationChannel.SMS
  );
  const [currentMediaType, setCurrentMediaType] = useState<string | null>(null);
  const [currentMediaUrl, setCurrentMediaUrl] = useState<string | null>(null);

  const memoizedSetCurrentName = useCallback(setCurrentName, []);
  const memoizedSetCurrentChannel = useCallback(setCurrentChannel, []);
  const memoizedSetCurrentMessageBody = useCallback(setCurrentMessageBody, []);
  const memoizedSetCurrentTemplateName = useCallback(
    setCurrentTemplateName,
    []
  );
  const memoizedSetCurrentAudienceId = useCallback(setCurrentAudienceId, []);

  const [showSendConfirmation, setShowSendConfirmation] =
    useState<boolean>(false);

  const [whatsappAwarenessConfirmed, setWhatsappAwarenessConfirmed] =
    useState<boolean>(false);

  const defaultOptionalPrefix = `${merchant.name}:`;
  const [optionalPrefix, setOptionalPrefix] = useState<string>(
    !currentTemplateName && currentMessageBody
      ? `${currentMessageBody.split(":")[0]}:`
      : defaultOptionalPrefix
  );
  const memoizedSetPrefix = useCallback(
    (newPrefix: string) => setOptionalPrefix(newPrefix),
    []
  );

  useEffect(() => {
    setOptionalPrefix(defaultOptionalPrefix);
  }, [currentTemplateName]);

  const reset = () => {
    if (!audiences.length) {
      fetchAudiences();
    }
    setIdempotencyKey(uuidv4());
  };

  useEffect(() => {
    reset();
  }, []);

  const optionalSuffix = `\nReply UNSUB to unsubscribe`;

  const [isIncludingSuffix, setIsIncludingSuffix] = useState<boolean>(false);

  const memoizedSetIsIncludingSuffix = useCallback(setIsIncludingSuffix, []);

  useEffect(() => {
    setIsIncludingSuffix(
      !currentTemplateName
        ? containsSuffix(currentMessageBody || "", optionalSuffix)
        : false
    );
  }, [currentTemplateName, currentMessageBody]);

  const fetchCampaignById = async (campaignId: string) => {
    const fetchedCampaign = await CampaignsService.getCampaignById(
      auth0Context,
      campaignId,
      merchant.id
    );

    return fetchedCampaign;
  };

  const fetchAudience = async (audienceId: string | null) => {
    if (!audienceId) {
      return null;
    }

    let foundAudience = audiences.find(
      (a: AudienceDomain) => a.id === audienceId
    );

    if (foundAudience && foundAudience.count !== 0) {
      return foundAudience;
    }

    try {
      foundAudience = await AudiencesService.getAudience(
        auth0Context,
        merchant.id,
        audienceId
      );
    } catch (error) {
      // eslint-disable-next-line
      console.error("Failed to fetch a single audience", error);
    }

    return foundAudience;
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    const foundCampaign = campaigns.find((c: CampaignDomain) => c.id === id);

    if (foundCampaign) {
      setCampaignToEdit(foundCampaign);
      fetchAudience(foundCampaign.audienceId).then((fetchedAudience) => {
        dispatch(propagateAudienceUpdate(fetchedAudience!));
      });
    } else {
      fetchCampaignById(id).then((fetchedCampaign) => {
        if (fetchedCampaign) {
          setCampaignToEdit(fetchedCampaign);
        }
      });
    }
  }, [id, campaigns]);

  // to make sure we fetch the audience after all audiences are fetched
  // in order to avoid a nasty bug when count is back to 0
  useEffect(() => {
    if (!audiences.length || !campaignToEdit || !!audience) {
      return;
    }

    fetchAudience(campaignToEdit.audienceId).then((fetchedAudience) => {
      dispatch(propagateAudienceUpdate(fetchedAudience!));
      setAudience(fetchedAudience!);
    });
  }, [audiences, campaignToEdit]);

  useEffect(() => {
    if (!campaignToEdit) {
      setCurrentAudienceId(null);
      setCurrentName("");
      setCurrentTemplateName(null);
      setCurrentMessageBody(null);
      setCurrentStatus(CampaignStatus.DRAFT);
      setCurrentChannel(ConversationChannel.SMS);
      setCurrentSentAt(null);
      setCurrentScheduledAt(null);
      setCurrentScheduledFor(null);
      setCurrentCreatedAt(null);
      setCurrentUpdatedAt(null);
      setCurrentMediaType(null);
      setCurrentMediaUrl(null);
      setAttachedFile(null);
    } else {
      setCurrentAudienceId(campaignToEdit.audienceId);
      setCurrentName(campaignToEdit.name);
      setCurrentTemplateName(campaignToEdit.templateName);
      setCurrentMessageBody(campaignToEdit.messageBody);
      setCurrentStatus(campaignToEdit.status);
      setCurrentChannel(campaignToEdit.channel);
      setCurrentSentAt(campaignToEdit.sentAt);
      setCurrentScheduledAt(campaignToEdit.scheduledAt);
      setCurrentScheduledFor(campaignToEdit.scheduledFor);
      setCurrentCreatedAt(campaignToEdit.createdAt);
      setCurrentUpdatedAt(campaignToEdit.updatedAt);
      setCurrentMediaType(campaignToEdit.mediaType);
      setCurrentMediaUrl(campaignToEdit.mediaUrl);
      setAttachedFile(null);
    }
  }, [campaignToEdit]);

  const handleCampaignSave = async (
    action: string,
    newFile: File | null
  ): Promise<void> => {
    const isUpdate = !!id;

    try {
      const saveResponse = isUpdate
        ? await editCampaign({
            id: id!,
            name: currentName.trim(),
            audienceId: currentAudienceId!,
            merchantId: merchant.id,
            templateName: currentTemplateName,
            messageBody: removePrefixAndSuffix(
              currentMessageBody || "",
              optionalPrefix,
              optionalSuffix
            ) // because if there's no content besides prefix and suffix it's not valid
              ? currentMessageBody
              : null,
            status: currentStatus,
            channel: currentChannel,
            sentAt: currentSentAt,
            scheduledAt:
              action === "Send"
                ? format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
                : currentScheduledAt
                ? format(
                    new Date(currentScheduledAt),
                    "yyyy-MM-dd'T'HH:mm:ssxxx"
                  )
                : null,
            scheduledFor:
              action === "Send"
                ? format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
                : currentScheduledFor
                ? format(
                    new Date(currentScheduledFor),
                    "yyyy-MM-dd'T'HH:mm:ssxxx"
                  )
                : null,
            createdAt: currentCreatedAt,
            updatedAt: currentUpdatedAt,
            mediaType: currentMediaType,
            mediaUrl: currentMediaUrl,
            file: newFile,
          })
        : await createCampaign({
            name: currentName.trim(),
            audienceId: currentAudienceId!,
            merchantId: merchant.id,
            templateName: currentTemplateName,
            messageBody: removePrefixAndSuffix(
              currentMessageBody || "",
              optionalPrefix,
              optionalSuffix
            ) // because if there's no content besides prefix and suffix it's not valid
              ? currentMessageBody
              : null,
            channel: currentChannel,
            status: currentStatus,
            sentAt: currentSentAt,
            scheduledAt:
              action === "Send"
                ? format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
                : currentScheduledAt
                ? format(
                    new Date(currentScheduledAt),
                    "yyyy-MM-dd'T'HH:mm:ssxxx"
                  )
                : null,
            scheduledFor:
              action === "Send"
                ? format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
                : currentScheduledFor
                ? format(
                    new Date(currentScheduledFor),
                    "yyyy-MM-dd'T'HH:mm:ssxxx"
                  )
                : null,
            createdAt: currentCreatedAt,
            updatedAt: currentUpdatedAt,
            file: newFile,
            idempotencyKey: idempotencyKey!,
          });

      if (!saveResponse) {
        return;
      }

      track(
        isUpdate
          ? "edit_campaign_attempt_succeeded"
          : "new_campaign_attempt_succeeded",
        {
          campaign_action: action,
          campaign_id: saveResponse.id,
        }
      );

      navigate(`/${merchant.id}/campaigns`);
    } catch (err: unknown) {
      /* eslint-disable no-console */
      if (err instanceof Error) {
        toast({ status: "error", title: err.message });
      }
      /* eslint-enable no-console */
    }
  };

  const onSave = async (fileToAttach: File | null) => {
    setIsSavingCampaign(true);
    await handleCampaignSave("Save", fileToAttach);
    setIsSavingCampaign(false);
  };

  const onSchedule = async (fileToAttach: File | null) => {
    setIsSchedulingCampaign(true);
    await handleCampaignSave("Schedule", fileToAttach);
    setIsSchedulingCampaign(false);
  };

  const onSend = async (fileToAttach: File | null) => {
    setIsSendingCampaign(true);
    setCurrentScheduledFor(new Date().toISOString());
    await handleCampaignSave("Send", fileToAttach);
    setIsSendingCampaign(false);
  };

  useEffect(() => {
    if (id) {
      setCompletedSteps([0, 1, 2, 3]);
      setDisabledSteps([]);
      setActiveStep(3);
    }
  }, [id]);

  const showAudienceEmptyError = () => {
    toast({
      status: "error",
      title: `Audience ${audience?.name || ""} has zero customers`,
    });
  };

  const actions: Action[] = [
    {
      text: "Save",
      requiresConfirmation: false,
      action: async () => {
        if (audience && !audience.count) {
          showAudienceEmptyError();
          return;
        }

        const saveResponse = await onSave(attachedFile);

        return saveResponse;
      },
    },
    {
      text: "Schedule",
      requiresConfirmation: false,
      action: async () => {
        if (audience && !audience.count) {
          showAudienceEmptyError();
          return;
        }

        const scheduleResponse = await onSchedule(attachedFile);

        return scheduleResponse;
      },
    },
    {
      text: "Send",
      requiresConfirmation: true,
      isConfirmed: false,
      setShowConfirmation: setShowSendConfirmation,
      // eslint-disable-next-line
      action: async function () {
        // creating new context
        if (audience && !audience.count) {
          showAudienceEmptyError();
          return;
        }

        if (
          !this.isConfirmed &&
          typeof this.setShowConfirmation !== "undefined"
        ) {
          this.setShowConfirmation(true);
          return;
        }

        const sendResponse = await onSend(attachedFile);

        return sendResponse;
      },
    },
  ];

  const [isMessageValid, setIsMessageValid] = useState<boolean>(false);
  const [chosenAction, setChosenAction] = useState<Action["text"]>("Send");
  const [formHeader, setFormHeader] = useState<string>("New Campaign");

  const memoizedSetIsMessageValid = useCallback(setIsMessageValid, []);

  useEffect(() => {
    if (activeStep !== 3 || !chosenAction) {
      return;
    }

    if (chosenAction === "Schedule") {
      if (currentScheduledFor) {
        setDisabledSteps(disabledSteps.filter((s) => s !== 3));
      } else {
        setDisabledSteps(
          disabledSteps.includes(0) ? disabledSteps : [...disabledSteps, 3]
        );
      }
    } else {
      setDisabledSteps(disabledSteps.filter((s) => s !== 3));
    }
  }, [chosenAction, activeStep, currentScheduledFor]);

  useEffect(() => {
    if (isMessageValid && disabledSteps.includes(1)) {
      setDisabledSteps(disabledSteps.filter((s) => s !== 1));
    } else if (!isMessageValid && !disabledSteps.includes(1)) {
      setDisabledSteps([...disabledSteps, 1]);
    }
  }, [isMessageValid]);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (currentStatus === CampaignStatus.DONE) {
      setFormHeader("View Campaign");
    } else {
      setFormHeader("Edit Campaign");
    }
  }, [currentStatus]);

  useEffect(() => {
    if (
      currentChannel !== ConversationChannel.WHATSAPP &&
      currentTemplateName
    ) {
      setCurrentTemplateName(null);
    }
  }, [currentChannel]);

  const onTuningValidationSuccess = useCallback(
    () => setDisabledSteps(disabledSteps.filter((s) => s !== 0)),
    [disabledSteps]
  );
  const onTuningValidationFailure = useCallback(
    () =>
      setDisabledSteps(
        disabledSteps.includes(0) ? disabledSteps : [...disabledSteps, 0]
      ),
    [disabledSteps]
  );
  const onAttachmentRemoved = useCallback(() => {
    setAttachedFile(null);
    setCurrentMediaType(null);
    setCurrentMediaUrl(null);
  }, []);

  return (
    <Box w="100%" h="100%">
      <Topbar title={formHeader} />
      <Flex
        w="100%"
        h="100%"
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        px={0}
        position="relative"
      >
        {currentStatus !== CampaignStatus.DONE && (
          <Stepper
            colorScheme={colorScheme}
            index={activeStep}
            orientation="horizontal"
            size={isBaseSize ? "md" : "lg"}
            py={isBaseSize ? 4 : 8}
            px={isBaseSize ? 2 : 8}
            borderBottomStyle="solid"
            borderBottomWidth="1px"
            borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
            width="100%"
          >
            {stepsConfig.map((step, index) => (
              <Step
                key={index}
                onClick={() => {
                  if (disabledSteps.includes(index - 1)) {
                    return;
                  }

                  setActiveStep(index);
                }}
                style={{
                  cursor: disabledSteps.includes(index - 1)
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={
                      <Icon
                        as={step.icon}
                        __css={{
                          path: {
                            fill:
                              colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.500`,
                          },
                        }}
                      />
                    }
                    active={
                      <Icon
                        as={step.icon}
                        __css={{
                          path: {
                            fill:
                              colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.500`,
                          },
                        }}
                      />
                    }
                  />
                </StepIndicator>

                {isBaseSize ? null : (
                  <Box flexShrink={0}>
                    <StepTitle>
                      <Text
                        color={
                          activeStep === index
                            ? colorMode === "dark"
                              ? `${colorScheme}.200`
                              : `${colorScheme}.500`
                            : "inherit"
                        }
                        fontWeight={activeStep === index ? "bold" : "normal"}
                      >
                        {step.name}
                      </Text>
                    </StepTitle>
                  </Box>
                )}

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        )}
        <Box
          p={0}
          width="100%"
          height="100%"
          overflowY="auto"
          paddingBottom="5rem"
        >
          <WhatsappDocumentsModal
            isShown={
              currentChannel === ConversationChannel.WHATSAPP &&
              !whatsappAwarenessConfirmed &&
              activeStep === 1 &&
              !id
            }
            onClose={() => setWhatsappAwarenessConfirmed(true)}
          />
          {activeStep === 0 && (
            <Tuning
              campaignName={currentName}
              setCampaignName={memoizedSetCurrentName}
              campaignStatus={currentStatus}
              campaignChannel={currentChannel}
              setCampaignChannel={memoizedSetCurrentChannel}
              onValidationSuccess={onTuningValidationSuccess}
              onValidationFailure={onTuningValidationFailure}
            />
          )}
          {activeStep === 1 && (
            <Message
              attachedFile={attachedFile}
              setAttachedFile={setAttachedFile}
              campaignStatus={currentStatus}
              campaignChannel={currentChannel}
              campaignMediaUrl={currentMediaUrl}
              campaignMediaType={currentMediaType}
              campaignMessage={currentMessageBody}
              campaignTemplateName={currentTemplateName}
              setCampaignTemplateName={memoizedSetCurrentTemplateName}
              setCampaignMessage={memoizedSetCurrentMessageBody}
              onAttachmentRemove={onAttachmentRemoved}
              setIsValid={memoizedSetIsMessageValid}
              prefix={optionalPrefix}
              setPrefix={memoizedSetPrefix}
              isIncludingSuffix={isIncludingSuffix}
              setIsIncludingSuffix={memoizedSetIsIncludingSuffix}
            />
          )}
          {activeStep === 2 && (
            <Audience
              campaignChannel={currentChannel}
              campaignAudienceId={currentAudienceId}
              campaignId={id || null}
              campaignStatus={currentStatus}
              setCampaignAudienceId={memoizedSetCurrentAudienceId}
              onValidationSuccess={() =>
                setDisabledSteps(disabledSteps.filter((s) => s !== 2))
              }
              onValidationFailure={() =>
                setDisabledSteps(
                  disabledSteps.includes(2)
                    ? disabledSteps
                    : [...disabledSteps, 2]
                )
              }
            />
          )}
          {activeStep === 3 && (
            <Preview
              campaignId={id}
              campaignName={currentName}
              campaignStatus={currentStatus}
              campaignChannel={currentChannel}
              campaignAudienceId={currentAudienceId}
              campaignMessage={currentMessageBody}
              campaignTemplateName={currentTemplateName}
              campaignMediaUrl={currentMediaUrl}
              campaignMediaType={currentMediaType}
              campaignScheduledFor={currentScheduledFor}
              campaignAnalytics={campaignToEdit?.analytics || null}
              attachedFile={attachedFile}
              chosenAction={chosenAction}
              setScheduledFor={setCurrentScheduledFor}
            />
          )}
        </Box>
        {currentStatus !== CampaignStatus.DONE && (
          <CampaignFormActionsBar
            activeStep={activeStep}
            completedSteps={completedSteps}
            setStep={setActiveStep}
            disabledSteps={disabledSteps}
            setCompletedSteps={setCompletedSteps}
            action={chosenAction}
            actions={actions}
            setAction={setChosenAction}
            moveToNextStep={() => {
              setCompletedSteps([...completedSteps, activeStep]);

              if (activeStep < 3) {
                setActiveStep(activeStep + 1);
              }
            }}
          />
        )}
      </Flex>
      <ConfirmationDialog
        headerText="Are you happy to proceed?"
        messageText={`You're about to send a campaign to: ${
          audience?.name || "selected audience"
        }. This action cannot be undone.`}
        buttonText="Yes"
        isOpen={showSendConfirmation}
        setIsOpen={setShowSendConfirmation}
        confirmationCallback={() => {
          const newChosenAction = {
            ...actions[2],
            isConfirmed: true,
          };
          newChosenAction.action();
          setShowSendConfirmation(false);
          setChosenAction(actions[2].text);
        }}
      />
    </Box>
  );
};

export default NewCampaignForm;
