import { Flex } from "@chakra-ui/react";
import Spinner from "components/spinner";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FullTemplateDomain from "entities/domain/templates/full_template";
import { useAppSelector } from "redux/hooks";
import Topbar from "components/shared/topbar/TopBar";
import BackIconButton from "components/shared/BackButton";
import TemplateForm from "../shared/TemplateForm";

const TemplateView = () => {
  const { templateId: templateIdParam } = useParams<{ templateId: string }>();
  const { fetchTemplates } = useTemplatesStore();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { templates, isLoading } = useAppSelector((state) => state.templates);
  const navigate = useNavigate();

  const [templateToEdit, setTemplateToEdit] =
    useState<FullTemplateDomain | null>(
      (templates.find(
        (t) => t.id === templateIdParam
      ) as unknown as FullTemplateDomain | null) || null
    );

  useEffect(() => {
    fetchTemplates(undefined, true);
  }, []);

  useEffect(() => {
    if (!templates || !templates.length) {
      return;
    }

    const template = templates.find((t) => t.id === templateIdParam);

    if (template) {
      if (template instanceof FullTemplateDomain) {
        setTemplateToEdit(template);
      } else {
        navigate(
          `/${merchant.id}/settings/templates/whatsapp/${templateIdParam}`
        );
      }
    } else {
      setTemplateToEdit(null);
    }
  }, [templates]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Flex w="100%" h="100%" direction="column">
      <Topbar
        leftChildrenMobile={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate(`/${merchant.id}/settings/templates`);
            }}
          />
        }
        leftChildren={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate(`/${merchant.id}/settings/templates`);
            }}
          />
        }
      />
      <TemplateForm
        templateToEdit={templateToEdit}
        setDisplayBackButton={(val: boolean) => {}}
      />
    </Flex>
  );
};

export default TemplateView;
