import { Color } from "theme/old-design-system/styled-components";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import { Template } from "components/chat/shared/messages-area/new-message-input-box/RichTextArea";
import { ReactComponent as SmsIcon } from "assets/icons/sms-message.svg";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as ZapierFacebookIcon } from "assets/icons/zapier-facebook.svg";
import { QuestionIcon } from "@chakra-ui/icons";

export interface Bounds {
  width: number;
  height: number;
}

// eslint-disable-next-line import/prefer-default-export
export const defaultBound: Bounds = {
  width: -1,
  height: -1,
};

export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5004";
export const socketUrl =
  process.env.REACT_APP_SOCKET_URL || "http://localhost:5000";

export const convertToChannelGroup = (
  channel: ConversationChannel | string
): string => {
  switch (channel) {
    case ConversationChannel.SMS:
    case ConversationChannel.WHATSAPP:
      return "Phone";
    case ConversationChannel.FACEBOOK:
    case ConversationChannel.INSTAGRAM:
      return "Social";
    case ConversationChannel.EMAIL:
      return "Email";
    case ConversationChannel.NO_CHANNEL:
    default:
      return "Unknown";
  }
};

export const getChannelIcon = (chan: string | ConversationChannel) => {
  switch (chan) {
    case ConversationChannel.SMS:
      return "/sms-new.svg";
    case ConversationChannel.WHATSAPP:
      return "/whatsapp.svg";
    case ConversationChannel.FACEBOOK:
      return "/facebook-messenger.svg";
    case ConversationChannel.INSTAGRAM:
      return "/instagram.svg";
    case ConversationChannel.EMAIL:
      return "/email.svg";
    case ConversationChannel.ZAPIER_FACEBOOK:
      return "/zapier-facebook.svg";
    case ConversationChannel.NO_CHANNEL:
    default:
      return "/question.svg";
  }
};

export const getChannelIconElement = (chan: string | ConversationChannel) => {
  switch (chan) {
    case ConversationChannel.SMS:
      return SmsIcon;
    case ConversationChannel.WHATSAPP:
      return WhatsappIcon;
    case ConversationChannel.FACEBOOK:
      return MessengerIcon;
    case ConversationChannel.INSTAGRAM:
      return InstagramIcon;
    case ConversationChannel.EMAIL:
      return EmailIcon;
    case ConversationChannel.ZAPIER_FACEBOOK:
      return ZapierFacebookIcon;
    case ConversationChannel.NO_CHANNEL:
    default:
      return QuestionIcon;
  }
};

export const isIconFillable = (channelIcon: string): boolean => {
  switch (channelIcon) {
    case "/facebook-messenger.svg":
      return false;
    case "/instagram.svg":
      return false;
    case "/zapier-facebook.svg":
      return false;
    default:
      return true;
  }
};

export const numberOfAttachmentsPerLoad = 20;
export const numberOfConversationsPerLoad = 100;
export const numberOfCampaignsPerLoad = 40;
export const numberOfInventoryVehiclesPerLoad = 100;
export const numberOfReviewsPerLoad = 40;
export const numberOfAllAgentsPerLoad = 100;
export const numberOfAllMerchantsPerLoad = 100;
export const numberOfMessagesPerLoad = 40;
export const numberOfContactsPerLoad = 100;
export const auth0ReviewLinkKey = "https://fuzey.io/reviews";
export const auth0TemplatesKey = "https://fuzey.io/templates";
export const auth0Templates2Key = "https://fuzey.io/templates2";

export const REVIEW_INVITATION_TEMPLATE_IDS = [
  "review_invitation",
  "review_invitation_whatsapp",
];

class SimpleSubject<T> {
  callbacks: ((value: T) => void)[];

  constructor() {
    this.callbacks = [];
  }

  next(value: T) {
    this.callbacks.forEach((callback) => callback(value));
  }

  subscribe(callback: (value: T) => void) {
    this.callbacks.push(callback);
    return {
      unsubscribe: () => {
        this.callbacks = this.callbacks.filter((cb) => cb !== callback);
      },
    };
  }
}

export const messageTemplateInsertion$ = new SimpleSubject<Template>();

export const colorList = [
  Color.SECONDARY_YELLOW,
  Color.SECONDARY_PINK_RED,
  Color.SECONDARY_DEEP_BLUE,
  Color.SECONDARY_PURPLE,
  Color.SECONDARY_EMERALD_GREEN,
  Color.SECONDARY_ORANGE,
  Color.SECONDARY_TANGO_PINK,
  Color.SECONDARY_BLUE_MIDNIGHT,
  Color.SECONDARY_AUBERGINE,
  Color.SECONDARY_GREEN,
  Color.PRIMARY_LIGHT_BLUE,
];

export const matchTagColorToMerchantTagColor = (
  merchTags: TagsDomain[] | undefined,
  tag: TagsDomain
): string => {
  if (!merchTags) {
    return tag.color;
  }

  const merchantTag = merchTags?.filter((mTag) => mTag.tag === tag.tag)[0];
  return merchantTag ? merchantTag.color : tag.color;
};

export const timeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay",
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const MAX_ALLOWED_BULK_SELECTION = 100;
export const MAX_SAFE_SELECTION_AMOUNT = 20;
