import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";

const ContactNameColumn = ({ item }: { item: ContactDomain }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex justifyContent="start" alignItems="center" gridGap={2} ml={4}>
      <ProfileAvatar
        profilePicture={item.getPicture()}
        name={item.getDisplayName(isBaseSize)}
      />
      <Text noOfLines={1}>{item.getDisplayName(isBaseSize)}</Text>
    </Flex>
  );
};

export default ContactNameColumn;
