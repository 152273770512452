import {
  Button,
  Flex,
  Icon,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import {
  canManageAutomations,
  canManageIntegrations,
  canManageRolePermissions,
} from "util/permissions";
import { ReactComponent as AccountIcon } from "assets/icons/account-avatar-svgrepo-com.svg";
import { ReactComponent as TeamIcon } from "assets/icons/team-svgrepo-com.svg";
import { ReactComponent as TemplateIcon } from "assets/icons/message-square-lines-svgrepo-com.svg";
import { ReactComponent as RadioIcon } from "assets/icons/radio-handheld-svgrepo-com.svg";
import { ReactComponent as AutomationIcon } from "assets/icons/automation-4p-svgrepo-com.svg";
import { ReactComponent as IntegrationIcon } from "assets/icons/integrations-svgrepo-com.svg";
import useAnalytics from "hooks/use-analytics";
import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { LockIcon } from "@chakra-ui/icons";
import { FaBuilding } from "react-icons/fa";

interface DesktopUserSettingsProps {}

enum SettingsRoutes {
  ACCOUNT_OVERVIEW = "account",
  CHANNELS = "channels",
  INTEGRATION_HUB = "integrations",
  TEMPLATE_MESSAGES = "templates",
  AUTOMATIONS = "automations",
  TEAM_MEMBERS = "teammates",
  PERMISSIONS = "permissions",
}

const DESKTOP_ROUTE_BY_TAB_INDEX: { [key in number]: SettingsRoutes } = {
  0: SettingsRoutes.ACCOUNT_OVERVIEW,
  1: SettingsRoutes.CHANNELS,
  2: SettingsRoutes.INTEGRATION_HUB,
  3: SettingsRoutes.AUTOMATIONS,
  4: SettingsRoutes.TEMPLATE_MESSAGES,
  5: SettingsRoutes.TEAM_MEMBERS,
  6: SettingsRoutes.PERMISSIONS,
};

const TAB_INDEX_BY_ROUTE: { [key in SettingsRoutes]: number } = {
  [SettingsRoutes.ACCOUNT_OVERVIEW]: 0,
  [SettingsRoutes.CHANNELS]: 1,
  [SettingsRoutes.INTEGRATION_HUB]: 2,
  [SettingsRoutes.AUTOMATIONS]: 3,
  [SettingsRoutes.TEMPLATE_MESSAGES]: 4,
  [SettingsRoutes.TEAM_MEMBERS]: 5,
  [SettingsRoutes.PERMISSIONS]: 6,
};

const DesktopUserSettings = (_props: DesktopUserSettingsProps) => {
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const { track } = useAnalytics();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").filter((p) => !!p)[2];

  const [tabIndex, setTabIndex] = useState<number>(
    typeof TAB_INDEX_BY_ROUTE[path as SettingsRoutes] !== "undefined"
      ? TAB_INDEX_BY_ROUTE[path as SettingsRoutes]
      : 0
  );

  return (
    <Flex
      pr="1rem"
      direction="row"
      overflow="hidden"
      height="100%"
      width="100%"
      minWidth="100%"
      alignItems="start"
    >
      <VStack
        mt="5rem"
        spacing={2}
        width="12rem"
        minWidth="12rem"
        maxWidth="12rem"
        pr={2}
      >
        <Button
          pl={8}
          variant="ghost"
          colorScheme={colorScheme}
          borderLeftRadius={0}
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[0]}-settings`}
          justifyContent="start"
          alignItems="center"
          isActive={tabIndex === 0}
          w="100%"
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[0]}_settings`);
            setTabIndex(0);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[0]}`
            );
          }}
          color={
            tabIndex !== 0
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          <Icon
            as={FaBuilding}
            mr={2}
            width="1.5rem"
            height="1.5rem"
            padding={1}
            __css={{
              circle: {
                stroke:
                  tabIndex !== 0
                    ? colorMode === "dark"
                      ? "gray.200"
                      : "gray.600"
                    : colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              },
              path: {
                stroke:
                  tabIndex !== 0
                    ? colorMode === "dark"
                      ? "gray.200"
                      : "gray.600"
                    : colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              },
            }}
          />
          <Text>Business</Text>
        </Button>
        <Button
          pl={8}
          w="100%"
          colorScheme={colorScheme}
          borderLeftRadius={0}
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[1]}-settings`}
          variant="ghost"
          isActive={tabIndex === 1}
          justifyContent="start"
          alignItems="center"
          isDisabled={!canManageIntegrations(merchant.id, currentAgent!)}
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[1]}_settings`);
            setTabIndex(1);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[1]}`
            );
          }}
        >
          <Icon
            as={RadioIcon}
            mr={2}
            width="1.5rem"
            height="1.5rem"
            __css={{
              path: {
                stroke:
                  tabIndex !== 1
                    ? colorMode === "dark"
                      ? "gray.200"
                      : "gray.600"
                    : colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              },
            }}
            fill={
              tabIndex !== 1
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text
            color={
              tabIndex !== 1
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          >
            Channels
          </Text>
        </Button>
        <Button
          pl={8}
          w="100%"
          borderLeftRadius={0}
          colorScheme={colorScheme}
          variant="ghost"
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[2]}-settings`}
          isActive={tabIndex === 2}
          justifyContent="start"
          alignItems="center"
          isDisabled={!canManageIntegrations(merchant.id, currentAgent!)}
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[2]}_settings`);
            setTabIndex(2);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[2]}`
            );
          }}
        >
          <Icon
            as={IntegrationIcon}
            mr={2}
            padding="0.15rem"
            width="1.5rem"
            height="1.5rem"
            __css={{
              path: {
                stroke:
                  tabIndex !== 2
                    ? colorMode === "dark"
                      ? "gray.200"
                      : "gray.600"
                    : colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              },
            }}
            fill={
              tabIndex !== 2
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text
            color={
              tabIndex !== 2
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          >
            Integrations
          </Text>
        </Button>
        <Button
          pl={8}
          w="100%"
          colorScheme={colorScheme}
          borderLeftRadius={0}
          variant="ghost"
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[3]}-settings`}
          isActive={tabIndex === 3}
          justifyContent="start"
          alignItems="center"
          isDisabled={!canManageAutomations(merchant.id, currentAgent!)}
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[3]}_settings`);
            setTabIndex(3);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[3]}`
            );
          }}
        >
          <Icon
            as={AutomationIcon}
            mr={2}
            width="1.5rem"
            height="1.5rem"
            fill={
              tabIndex !== 3
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text
            color={
              tabIndex !== 3
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          >
            Automations
          </Text>
        </Button>
        <Button
          pl={8}
          variant="ghost"
          colorScheme={colorScheme}
          borderLeftRadius={0}
          justifyContent="start"
          alignItems="center"
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[4]}-settings`}
          isActive={tabIndex === 4}
          w="100%"
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[4]}_settings`);
            setTabIndex(4);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[4]}`
            );
          }}
          color={
            tabIndex !== 4
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          <Icon
            as={TemplateIcon}
            mr={2}
            width="1.5rem"
            height="1.5rem"
            __css={{
              path: {
                stroke:
                  tabIndex !== 4
                    ? colorMode === "dark"
                      ? "gray.200"
                      : "gray.600"
                    : colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              },
            }}
          />
          <Text>Templates</Text>
        </Button>
        <Button
          pl={8}
          variant="ghost"
          borderLeftRadius={0}
          colorScheme={colorScheme}
          justifyContent="start"
          alignItems="center"
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[5]}-settings`}
          isActive={tabIndex === 5}
          w="100%"
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[5]}_settings`);
            setTabIndex(5);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[5]}`
            );
          }}
          color={
            tabIndex !== 5
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          <Icon
            as={TeamIcon}
            mr={2}
            width="1.5rem"
            height="1.5rem"
            fill={
              tabIndex !== 5
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text>Teammates</Text>
        </Button>
        {canManageRolePermissions(merchant.id, currentAgent!) ? (
          <Button
            pl={8}
            variant="ghost"
            colorScheme={colorScheme}
            borderLeftRadius={0}
            justifyContent="start"
            alignItems="center"
            id={`${DESKTOP_ROUTE_BY_TAB_INDEX[6]}-settings`}
            isActive={tabIndex === 6}
            w="100%"
            onClick={() => {
              track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[6]}_settings`);
              setTabIndex(6);
              navigate(
                `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[6]}`
              );
            }}
            color={
              tabIndex !== 6
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          >
            <Icon
              as={LockIcon}
              mr={2}
              width="1.25rem"
              height="1.25rem"
              fill={
                tabIndex !== 6
                  ? colorMode === "dark"
                    ? "gray.200"
                    : "gray.600"
                  : colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.600`
              }
            />
            <Text>Role Permissions</Text>
          </Button>
        ) : null}
      </VStack>
      <Flex w="100%" h="100%" borderTopRadius="3xl" overflow="hidden">
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default DesktopUserSettings;
