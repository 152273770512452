import NewAgentDomain from "entities/domain/agents/new-agent-domain";

export enum UserPermissionsKey {
  MANAGE_INBOX_TEAM = "manage:team_inbox",
  IMPORT_CONTACTS = "import:contacts",
  DELETE_CONTACTS = "delete:contacts",
  VIEW_PAYMENTS = "view:payments",
  SEND_PAYMENTS = "send:payments",
  MANAGE_INTEGRATIONS = "manage:integrations",
  MANAGE_AUTOMATIONS = "manage:automations",
  MANAGE_CAMPAIGNS = "manage:campaigns",
  MANAGE_TEAM_MEMBERS = "manage:team_members",
  MANAGE_ROLE_PERMISSIONS = "manage:role_permissions",
  MANAGE_ACCOUNT = "manage:account",
  FUZEY_SUPER_USER = "super_user",
  VIEW_AGENTS_ANALYTICS = "view:agents_analytics",
}

export type UserPermissions = UserPermissionsKey[];

export function atLeastOnePermissionExists(
  merchantId: number,
  currentAgent: NewAgentDomain,
  permissionKeys: UserPermissionsKey[]
) {
  const permissions = currentAgent.getPermissions(merchantId);
  if (!permissions || permissions.length === 0) {
    return false;
  }

  for (const key of permissionKeys) {
    if (permissions.includes(key)) {
      return true;
    }
  }

  return false;
}

export function allPermissionsExist(
  merchantId: number,
  currentAgent: NewAgentDomain,
  permissionKeys: UserPermissionsKey[]
) {
  const permissions = currentAgent.getPermissions(merchantId);

  if (!permissions || permissions.length === 0) {
    return false;
  }

  for (const key of permissionKeys) {
    if (!permissions.includes(key)) {
      return false;
    }
  }

  return true;
}

export function canViewPayments(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.VIEW_PAYMENTS,
  ]);
}

export function canSendPayments(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.SEND_PAYMENTS,
  ]);
}

export function canManageTeamInbox(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.MANAGE_INBOX_TEAM,
  ]);
}

export function canImportContacts(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.IMPORT_CONTACTS,
  ]);
}

export function canManageAccount(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.MANAGE_ACCOUNT,
  ]);
}

export function canDeleteContacts(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.DELETE_CONTACTS,
  ]);
}

export function canManageIntegrations(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.MANAGE_INTEGRATIONS,
  ]);
}

export function canManageAutomations(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.MANAGE_AUTOMATIONS,
  ]);
}

export function canViewAgentsAnalytics(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.VIEW_AGENTS_ANALYTICS,
  ]);
}

export function canManageCampaigns(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.MANAGE_CAMPAIGNS,
  ]);
}

export function canManageTeamMembers(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.MANAGE_TEAM_MEMBERS,
  ]);
}

export function canManageRolePermissions(
  merchantId: number,
  currentAgent: NewAgentDomain
) {
  return allPermissionsExist(merchantId, currentAgent, [
    UserPermissionsKey.MANAGE_ROLE_PERMISSIONS,
  ]);
}
