import Spinner from "components/spinner";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Box, Flex, Heading, useToast } from "@chakra-ui/react";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import useMerchantStore from "hooks/use-merchant-store";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  SetUpXeroPayload,
  fetchTenants,
  fetchTenantsFail,
  fetchTenantsSuccess,
  selectTenant,
  setSelectedTenant,
  setUpXero,
  setUpXeroFail,
  setUpXeroSuccess,
} from "redux/features/xero";
import TenantDomain from "entities/domain/xero/tenant-domain";
import InvoiceService from "services/invoice";
import { useAuth0 } from "@auth0/auth0-react";
import Form from "./xero-setup/form";

const XeroPage = () => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const dispatch = useAppDispatch();
  const { loading, tenants, selectedTenantId, errors } = useAppSelector(
    (state) => state.xero
  );
  const toast = useToast();
  const selectedTenant = selectTenant(selectedTenantId, tenants);

  const xero_redirect_url = new URLSearchParams(useLocation().search);
  const xero_authorization_code = xero_redirect_url.get("code");
  const state = xero_redirect_url.get("state");
  const merchantIdFromState = state ? parseInt(state, 10) : null;

  const fetchTenantsAsync = async () => {
    try {
      dispatch(fetchTenants());

      const tenantsResponse = await InvoiceService.getAllXeroTenants(
        auth0Context,
        merchant.id
      );

      dispatch(fetchTenantsSuccess(tenantsResponse));
    } catch (err) {
      dispatch(fetchTenantsFail(["Oops. Please try again."]));
    }
  };

  const setUpXeroAccountAsync = async (payload: SetUpXeroPayload) => {
    try {
      dispatch(setUpXero());

      const xeroResponse = await InvoiceService.setUpXeroAccount(
        auth0Context,
        payload,
        merchant.id
      );

      dispatch(setUpXeroSuccess(xeroResponse));
      return xeroResponse;
    } catch (err) {
      dispatch(setUpXeroFail(["Oops. Please try again."]));
      return undefined;
    }
  };

  useEffect(() => {
    if (!merchant) {
      return;
    }

    if (!merchantIdFromState || typeof merchantIdFromState !== "number") {
      // eslint-disable-next-line
      console.error("Merchant ID didn't come with the sate to Xero callback", {
        url: xero_redirect_url,
        passedMerchantId: state,
      });
      localStorage.setItem(
        "callback_fail",
        JSON.stringify({
          title: "We couldn't connect your Xero account...",
          description: "Please try again later or contact our support team.",
        })
      );
      window.location.assign(`/public/callback/fail`);

      return;
    }

    const merchantIsAvailable = currentAgent!.merchants
      .map((m: AgentMerchantDomain) => m.id)
      .includes(merchantIdFromState);

    if (merchantIdFromState !== merchant.id) {
      if (!merchantIsAvailable) {
        // eslint-disable-next-line
        console.error(
          "Merchant ID is not available to agent during Xero callback processing",
          {
            url: xero_redirect_url,
            passedMerchantId: state,
            agentId: currentAgent!.id,
          }
        );
        localStorage.setItem(
          "callback_fail",
          JSON.stringify({
            title: "We couldn't connect your Xero account...",
            description: "Please try again later or contact our support team.",
          })
        );
        window.location.assign(`/public/callback/fail`);

        return;
      }

      localStorage.setItem("fuzey:merchant:id", merchantIdFromState.toString());
      window.location.assign(window.location.href);

      return;
    }

    if (xero_authorization_code) {
      InvoiceService.sendXeroCode(
        auth0Context,
        xero_authorization_code,
        merchant.id
      ).then(() => {
        fetchTenantsAsync();
      });
    }
  }, [merchant]);

  const confirmXeroSetup = (tenant: TenantDomain | undefined) => {
    setUpXeroAccountAsync({ tenant_id: tenant!.id })
      .then((res) => {
        window.location.assign(
          `/${merchant.id}/settings?redirect_location=xero`
        );
      })
      .catch((e) => {
        window.location.assign(`/${merchant.id}/settings`);
      });
    if (errors.length) {
      toast({ status: "error", title: errors[0] });
    } else {
      toast({ status: "success", title: "Invoicing set up successfully" });
    }
  };

  useEffect(() => {
    dispatch(
      setSelectedTenant(tenants.length === 1 ? tenants[0].id : undefined)
    );
  }, [tenants]);

  const renderStep = () => {
    if (loading) {
      return <Spinner />;
    }
    return (
      <>
        <Form
          heading="Select Account:"
          onClick={() => confirmXeroSetup(selectedTenant)}
          options={tenants}
          selectValue={(value) => dispatch(setSelectedTenant(value))}
          isValueSelected={!selectedTenant?.id}
          defaultValue={selectedTenant?.id}
        />
      </>
    );
  };

  return (
    <>
      <Box height="100%" width="100%">
        <Flex
          minHeight="660px"
          width="792px"
          margin="30px auto"
          alignItems="center"
          direction="column"
          __css={{
            "@media screen and (max-width: 1024px)": {
              width: "100%",
              margin: "0",
              height: "100%",
            },
          }}
        >
          <Heading as="h2" size="lg" textAlign="center" margin="60px 0">
            Set up Xero Account
          </Heading>
          {renderStep()}
        </Flex>
      </Box>
    </>
  );
};

export default XeroPage;
