import { useBreakpointValue, useColorMode, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BulkContactsUpdatedMessage,
  BulkConversationsUpdatedMessage,
  ConversationCreatedMessage,
  ConversationUpdatedMessage,
  CustomerUpdatedMessage,
  SocketMessage,
  TeamCreatedMessage,
  TeamDeletedMessage,
  TeamUpdatedMessage,
} from "entities/ISocketArgs";
import { contactTransformFromDtoToDomain } from "entities/transformers/contact-transformer";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useWebSocket } from "hooks/use-socket";
import {
  bulkUpdateContacts,
  cleanContactsToastMessages,
} from "redux/features/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useConversationsStore from "hooks/use-conversations-store";
import ContactsService from "services/contacts";
import { batch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  canSendBrowserNotification,
  sendBrowserNotification,
} from "util/browser_notifications";
import { messageTransformFromDtoToDomain } from "entities/transformers/conversationTransformers";
import MessageDomain from "entities/domain/conversations/message-domain";
import { canManageTeamInbox } from "util/permissions";
import useAnalytics from "hooks/use-analytics";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import { conversationTransformFromDtoToDomain } from "entities/transformers/conversation-transformer";
import {
  appendOrReplaceConversation,
  bulkAppendOrReplaceConversations,
} from "redux/features/conversations";
import InboxService from "services/inbox";
import TeamDomain from "entities/domain/team";
import { teamTransformFromDtoToDomain } from "entities/transformers/team-transformer";
import {
  addTeam,
  propagateTeamUpdate,
  removeTeam,
} from "redux/features/agents";
import DesktopChat from "./desktop";
import MobileChat from "./mobile";

const Chat = () => {
  const auth0Context = useAuth0();
  const { conversationId: idParam } = useParams();
  const conversationId =
    idParam !== undefined ? parseInt(idParam, 10) : undefined;
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { track } = useAnalytics();
  const {
    getConversation,
    updateConversationWithNewMessage,
    setActiveConversation,
    updateConversationsWithCustomerUpdate,
  } = useConversationsStore();

  useEffect(() => {
    setActiveConversation(conversationId);
  }, [conversationId]);

  const { toastMessage } = useAppSelector((state) => state.contacts);

  const [bulkContactsUpdate, setBulkContactsUpdate] = useState(
    {} as BulkContactsUpdatedMessage
  );
  const [customerUpdate, setCustomerUpdate] = useState(
    {} as CustomerUpdatedMessage
  );
  const [newInboundMessage, setNewInboundMessage] = useState(
    {} as SocketMessage
  );
  const [conversationCreate, setConversationCreate] = useState(
    {} as ConversationCreatedMessage
  );
  const [conversationUpdate, setConversationUpdate] = useState(
    {} as ConversationUpdatedMessage
  );
  const [bulkConversationsUpdate, setBulkConversationsUpdate] = useState(
    {} as BulkConversationsUpdatedMessage
  );
  const [teamCreate, setTeamCreate] = useState<TeamCreatedMessage>(
    {} as TeamCreatedMessage
  );
  const [teamUpdate, setTeamUpdate] = useState<TeamUpdatedMessage>(
    {} as TeamUpdatedMessage
  );
  const [teamDelete, setTeamDelete] = useState<TeamDeletedMessage>(
    {} as TeamDeletedMessage
  );

  const { addEventHandler, removeEventHandler } = useWebSocket();

  // remove this fucking shit?
  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast({
          status: "success",
          title: toastMessage.success,
        });
      } else if (toastMessage.errors) {
        toast({
          status: "error",
          title: toastMessage.errors[0],
        });
      }
      dispatch(cleanContactsToastMessages());
    }
  }, [toastMessage]);

  const handleCustomerUpdated = (args: CustomerUpdatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setCustomerUpdate(args);
  };

  const handleBulkContactsUpdated = (args: BulkContactsUpdatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setBulkContactsUpdate(args);
  };

  const handleInboundMessage = (args: SocketMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setNewInboundMessage(args);
  };

  const handleConversationCreated = (args: ConversationCreatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setConversationCreate(args);
  };

  const handleConversationUpdated = (args: ConversationUpdatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setConversationUpdate(args);
  };

  const handleBulkConversationsUpdated = (
    args: BulkConversationsUpdatedMessage
  ) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setBulkConversationsUpdate(args);
  };

  const handleTeamCreated = (args: TeamCreatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setTeamCreate(args);
  };

  const handleTeamUpdated = (args: TeamUpdatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setTeamUpdate(args);
  };

  const handleTeamDeleted = (args: TeamDeletedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setTeamDelete(args);
  };

  useEffect(() => {
    addEventHandler("customer_updated", handleCustomerUpdated);
    addEventHandler("team_created", handleTeamCreated);
    addEventHandler("team_updated", handleTeamUpdated);
    addEventHandler("team_deleted", handleTeamDeleted);
    addEventHandler("bulk_customers_updated", handleBulkContactsUpdated);
    addEventHandler("inbound_message", handleInboundMessage);
    addEventHandler("conversation_created", handleConversationCreated);
    addEventHandler("conversation_updated", handleConversationUpdated);
    addEventHandler(
      "bulk_conversations_updated",
      handleBulkConversationsUpdated
    );

    return () => {
      removeEventHandler("bulk_customers_updated", handleBulkContactsUpdated);
      removeEventHandler("customer_updated", handleCustomerUpdated);
      removeEventHandler("inbound_message", handleInboundMessage);
      removeEventHandler("conversation_created", handleConversationCreated);
      removeEventHandler("conversation_updated", handleConversationUpdated);
      removeEventHandler(
        "bulk_conversations_updated",
        handleBulkConversationsUpdated
      );
      removeEventHandler("team_created", handleTeamCreated);
      removeEventHandler("team_updated", handleTeamUpdated);
      removeEventHandler("team_deleted", handleTeamDeleted);
    };
  }, [addEventHandler, removeEventHandler]);

  useDeepCompareEffect(() => {
    if (Object.keys(newInboundMessage).length !== 0) {
      const message: MessageDomain = messageTransformFromDtoToDomain(
        newInboundMessage.message
      );

      updateConversationWithNewMessage(message);
    }
  }, [newInboundMessage]);

  useEffect(() => {
    if (Object.keys(teamCreate).length !== 0) {
      const team: TeamDomain = teamTransformFromDtoToDomain(teamCreate.team);

      dispatch(addTeam(team));
    }
  }, [teamCreate]);

  useEffect(() => {
    if (Object.keys(teamUpdate).length !== 0) {
      const team: TeamDomain = teamTransformFromDtoToDomain(teamUpdate.team);

      dispatch(propagateTeamUpdate(team));
    }
  }, [teamUpdate]);

  useEffect(() => {
    if (Object.keys(teamDelete).length !== 0) {
      dispatch(removeTeam(teamDelete.team_id));
    }
  }, [teamDelete]);

  useDeepCompareEffect(() => {
    if (Object.keys(newInboundMessage).length !== 0) {
      if (!canSendBrowserNotification()) {
        return;
      }

      const message: MessageDomain = messageTransformFromDtoToDomain(
        newInboundMessage.message
      );

      getConversation(message.conversationId, currentAgent!.id).then(
        (conversation) => {
          if (!conversation) {
            return;
          }

          const agentAssignedToConversation =
            conversation.assignedAgentId === currentAgent!.id;
          const isManager = canManageTeamInbox(merchant.id, currentAgent!);
          const noAgentAssigned = !conversation.assignedAgentId;
          const managerShouldSeeUnassigned = isManager && noAgentAssigned;

          if (agentAssignedToConversation || managerShouldSeeUnassigned) {
            const contactName = conversation.displayName;

            sendBrowserNotification(
              `${contactName}: ${message.body}`,
              conversation.id,
              track
            );
          }
        }
      );
    }
  }, [newInboundMessage]);

  useDeepCompareEffect(() => {
    if (Object.keys(conversationCreate).length === 0) {
      return;
    }

    const conversation: ConversationDomain =
      conversationTransformFromDtoToDomain(conversationCreate.conversation);

    dispatch(
      appendOrReplaceConversation({
        conversation,
        currentAgentId: currentAgent!.id,
      })
    );
  }, [conversationCreate]);

  useDeepCompareEffect(() => {
    if (Object.keys(conversationUpdate).length !== 0) {
      const conversation: ConversationDomain =
        conversationTransformFromDtoToDomain(conversationUpdate.conversation);

      dispatch(
        appendOrReplaceConversation({
          conversation,
          currentAgentId: currentAgent!.id,
        })
      );
    }
  }, [conversationUpdate]);

  useEffect(() => {
    // Don't use deepCompare because all bulk actions return same result
    if (Object.keys(bulkContactsUpdate).length !== 0) {
      const updatedConversationIds = bulkContactsUpdate.customer_ids;

      ContactsService.getContactsByIds(
        auth0Context,
        updatedConversationIds,
        merchant.id
      ).then((contactsToUpdate) => {
        batch(() => {
          contactsToUpdate.forEach((contactToUpdate) => {
            updateConversationsWithCustomerUpdate(contactToUpdate.id!, {
              displayName: contactToUpdate.fullName,
              picture: contactToUpdate.getPicture(),
              tagIds: contactToUpdate.tagIds,
            });
          });
          dispatch(bulkUpdateContacts(contactsToUpdate));
        });
      });
    }
  }, [bulkContactsUpdate]);

  useDeepCompareEffect(() => {
    if (Object.keys(customerUpdate).length !== 0) {
      const customer = contactTransformFromDtoToDomain(customerUpdate.customer);
      updateConversationsWithCustomerUpdate(customer.id!, {
        displayName: customer.fullName,
        picture: customer.getPicture(),
        tagIds: customer.tagIds,
      });
    }
  }, [customerUpdate]);

  useEffect(() => {
    if (Object.keys(bulkConversationsUpdate).length !== 0) {
      const updatedConversationIds = bulkConversationsUpdate.conversation_ids;

      InboxService.getConversationsByIds(
        auth0Context,
        merchant.id,
        updatedConversationIds
      ).then((conversations) => {
        dispatch(
          bulkAppendOrReplaceConversations({
            conversations,
            currentAgentId: currentAgent!.id,
          })
        );
      });
    }
  }, [bulkConversationsUpdate]);

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Why???
  }, []);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return isBaseSize ? <MobileChat /> : <DesktopChat />;
};

export default Chat;
