import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import Select from "react-select";
import AdminService from "services/admin";
import { getReactSelectStyles } from "util/methods";
import AdminKeyloopAccountDomain from "entities/domain/admin/merchants/admin-keyloop-account-domain";
import SelectMerchant from "./select-merchant";

interface KeyloopAccountSelectOption {
  value: string;
  label: string;
}

const UpdateKeyloopAccount = () => {
  const auth0Context = useAuth0();
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [cache, setCache] = useState<object>({});
  const [accountName, setAccountName] = useState("");
  const [accounts, setAccounts] = useState<AdminKeyloopAccountDomain[]>([]);
  const [accountOptions, setAccountOptions] = useState<
    KeyloopAccountSelectOption[]
  >([]);
  const [selectedKeyloopAccountValue, setSelectedKeyloopAccountValue] =
    useState<KeyloopAccountSelectOption | null>(null);
  const [selectedMerchants, setSelectedMerchants] = useState<
    AdminMerchantDomain[]
  >([]);

  const resetCache = () => {
    setCache({});
  };

  const resetValues = () => {
    setSelectedKeyloopAccountValue(null);
    resetCache();
  };

  const updateKeyloopAccount = async () => {
    if (!selectedKeyloopAccountValue) {
      toast({
        status: "error",
        title:
          "Couldn't update keyloop account. Can't find enterprise id and store id",
      });
      return;
    }

    try {
      await AdminService.updateKeyloopAccount(auth0Context, {
        accountName,
        enterpriseId: selectedKeyloopAccountValue.value.split("-")[0],
        storeId: selectedKeyloopAccountValue.value.split("-")[1],
        merchantIds: selectedMerchants.map((m) => m.id),
      });
      toast({
        status: "success",
        title: "Keyloop account updated successfully!",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't update keyloop account", e);
      toast({
        status: "error",
        title: "Couldn't update keyloop account!",
      });
    } finally {
      resetValues();
    }
  };

  useEffect(() => {
    setAccountOptions(
      accounts.map((a) => ({
        value: `${a.enterpriseId}-${a.storeId}`,
        label: a.accountName || `${a.enterpriseId} - ${a.storeId}`,
      }))
    );
  }, [accounts]);

  useEffect(() => {
    AdminService.fetchKeyloopAccounts(auth0Context).then((newAccounts) => {
      setAccounts(newAccounts);
    });
  }, [cache]);

  useEffect(() => {
    if (selectedKeyloopAccountValue) {
      const account = accounts.find((a) => {
        return (
          `${a.enterpriseId}-${a.storeId}` === selectedKeyloopAccountValue.value
        );
      })!;

      AdminService.getAllMerchants(
        auth0Context,
        "",
        0,
        account.merchantIds
      ).then((merchants) => {
        setAccountName(account.accountName || "");
        setSelectedMerchants(merchants);
      });
    } else {
      setAccountName("");
      setSelectedMerchants([]);
    }
  }, [selectedKeyloopAccountValue]);

  return (
    <Stack spacing={3}>
      <FormControl isRequired>
        <FormLabel>Choose Account</FormLabel>
        <Select
          placeholder="Select Account"
          onChange={(v) => setSelectedKeyloopAccountValue(v || null)}
          options={accountOptions}
          value={selectedKeyloopAccountValue}
          styles={getReactSelectStyles(colorMode, colorScheme)}
          onMenuOpen={() => {
            resetCache();
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Account Name</FormLabel>
        <Input
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          isDisabled={!selectedKeyloopAccountValue}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Choose Merchants</FormLabel>
        <SelectMerchant
          isDisabled={!selectedKeyloopAccountValue}
          isMulti={true}
          selectedMerchants={selectedMerchants}
          setSelectedMerchants={setSelectedMerchants}
        />
      </FormControl>
      <Button
        colorScheme={colorScheme}
        onClick={updateKeyloopAccount}
        isDisabled={!accountName || !selectedKeyloopAccountValue}
        mt={8}
        mb={8}
        size="lg"
      >
        Update Account
      </Button>
    </Stack>
  );
};

export default UpdateKeyloopAccount;
