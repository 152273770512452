import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Text,
  Input,
  FormControl,
  FormLabel,
  useColorMode,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { ReactComponent as AutoTraderIcon } from "assets/icons/auto-trader.svg";
import Integration from "components/user-settings/shared/Integration";
import { useAppSelector } from "redux/hooks";

const AutoTraderIntegrationItem = () => {
  const merchant = useAppSelector((state) => state.merchant.merchant);

  if (merchant.country !== "GB") {
    return null;
  }

  const {
    isOpen,
    onOpen: originalOnOpen,
    onClose: originalOnClose,
  } = useDisclosure();

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [dealershipId, setDealershipId] = useState("");
  const [autoTraderLink, setAutoTraderLink] = useState("");
  const [postCode, setPostCode] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      dealershipId !== "" && autoTraderLink !== "" && postCode !== ""
    );
  }, [dealershipId, autoTraderLink, postCode]);

  const resetForm = () => {
    setDealershipId("");
    setAutoTraderLink("");
    setPostCode("");
    setIsFormValid(false);
  };

  const onClose = () => {
    resetForm();
    originalOnClose();
  };

  const onOpen = () => {
    resetForm();
    originalOnOpen();
  };

  const handleConnect = () => {
    const subject = encodeURIComponent(
      "AutoTrader Integration Request (Fuzey)"
    );
    const body = encodeURIComponent(
      `Hi,\nCould you please add ${merchant.name} dealership to our Fuzey integration please?\n\nDealer ID: ${dealershipId}\nAutoTrader Link: ${autoTraderLink}\nPost Code: ${postCode}\n\nMany Thanks!`
    );
    const cc = encodeURIComponent("support@getfuzey.com");
    window.location.href = `mailto:technical.support@autotrader.co.uk?subject=${subject}&body=${body}&cc=${cc}`;
    onClose();
  };

  const renderContent = () => {
    if (merchant.externalMetadata.autoTraderId) {
      return (
        <Integration icon={<AutoTraderIcon />} isConnected={true}>
          <Text mt={2}>
            Your AutoTrader account is connected and syncing data automatically.
          </Text>
        </Integration>
      );
    }

    return (
      <Integration
        icon={<AutoTraderIcon />}
        primaryButton={
          <Button onClick={onOpen} colorScheme={colorScheme}>
            Connect
          </Button>
        }
      >
        <Text mt={2}>
          Connect your AutoTrader account to automatically sync your leads.
        </Text>
        <Text mt={2} fontSize="sm" color="gray.500">
          Note: This process requires sending an email to AutoTrader support.
        </Text>
      </Integration>
    );
  };

  return (
    <>
      {renderContent()}

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent borderRadius="xl">
          <ModalHeader textAlign="center">Connect to AutoTrader</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={4}>
            <Alert status="info" mb={3} px={3} py={2}>
              <AlertIcon />
              <Text fontSize="sm">
                To enable the integration, we`ll need to send an email to
                AutoTrader support with your details.
              </Text>
            </Alert>
            <VStack spacing={3} align="stretch">
              <FormControl>
                <FormLabel>Dealership ID</FormLabel>
                <Input
                  value={dealershipId}
                  onChange={(e) => setDealershipId(e.target.value)}
                  placeholder="12345"
                  bg={colorMode === "dark" ? "whiteAlpha.100" : "gray.100"}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Link to your AutoTrader dealerships page</FormLabel>
                <Input
                  value={autoTraderLink}
                  onChange={(e) => setAutoTraderLink(e.target.value)}
                  placeholder="https://www.autotrader.co.uk/dealers/..."
                  bg={colorMode === "dark" ? "whiteAlpha.100" : "gray.100"}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Post Code</FormLabel>
                <Input
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                  placeholder="SW1A 1AA"
                  bg={colorMode === "dark" ? "whiteAlpha.100" : "gray.100"}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleConnect}
              colorScheme={colorScheme}
              width="100%"
              isDisabled={!isFormValid}
            >
              Request Access
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AutoTraderIntegrationItem;
