import { useAuth0 } from "@auth0/auth0-react";
import {
  ChakraProvider,
  Flex,
  Spinner,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import StaleDataManager from "components/modals/stale-data-manager";
import useAnalytics from "hooks/use-analytics";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import FuzeyTheme from "theme";
import PublicRoot from "PublicRoot";
import PrivateRoot from "PrivateRoot";
import ScreenSettingsProvider from "ScreenSettingsProvider";
import AbstractPrivateRoute from "components/Routes/AbstractPrivateRoute";
import Chat from "components/chat";
import MerchantPrivateRoute from "components/Routes/MerchantPrivateRoute";
import ReviewsPage from "components/reviews";
import PaymentsPage from "components/payments";
import Admin from "components/admin";
import Merchants from "components/admin/merchants";
import Agents from "components/admin/agents";
import XeroPage from "components/payments/xero";
import QuickbooksPage from "components/payments/quickbooks";
import Calendar from "components/calendar";
import InvoicingOptions from "components/payments/invoicing-options";
import ContactsPage from "components/contacts";
import EditContactPage from "components/contacts/contact-card/editContact";
import CreateContactPage from "components/contacts/contact-card/createContact";
import NewCampaignForm from "components/campaigns/form";
import NewCampaignsList from "components/campaigns/list";
import GoogleReviewsCallback from "components/google/callback";
import GoogleGmailCallback from "components/google/callbackGmail";
import MicrosoftOutlookCallback from "components/microsoft/callbackOutlook";
import StripeCallback from "components/stripe/callback";
import FacebookMessengerCallback from "components/facebook/callbackMessenger";
import InstagramCallback from "components/facebook/callbackInstagram";
import GoogleLocationsOptions from "components/google/locations";
import UserSettings from "components/user-settings";
import TemplateView from "components/user-settings/TemplateSettings/TemplateView";
import TeamMemberForm from "components/user-settings/TeamMemberSettings/TeamMemberForm";
import RolePermissions from "components/user-settings/RolePermissions";
import ZapierIntegration from "components/user-settings/Integrations/items/zapier/index";
import "./App.css";
import ExternalPayment from "components/public-pages/payments";
import ExternalPaymentCallback from "components/public-pages/payments/callback";
import ContactForm from "components/contact-form";
import ContactFormSuccess from "components/contact-form/ContactFormSuccess";
import CallbackSuccess from "components/CallbackSuccess";
import CallbackFail from "components/CallbackFail";
import MessagesArea from "components/chat/shared/messages-area";
import AdminNavigation from "components/admin/AdminNavigation";
import ContactDetails from "components/shared/contact-details";
import ReviewPage from "components/reviews/ReviewPage";
import AccountOverview from "components/user-settings/AccountOverview/AccountOverview";
import AutomationSettings from "components/user-settings/Automations";
import TemplateSettings from "components/user-settings/TemplateSettings/TemplateSettings";
import TeamMemberSettings from "components/user-settings/TeamMemberSettings";
import { WebSocketProvider } from "hooks/use-socket";
import NewIntegrations from "components/user-settings/Integrations";
import Channels from "components/user-settings/Channels";
import Keyloop from "components/user-settings/Integrations/keyloop";
import WhatsappIntegration from "components/user-settings/Integrations/WhatsappIntegration";
import AI from "components/admin/ai";
import AnalyticsPage from "components/analytics";
import CreateWhatsappTemplate from "components/user-settings/TemplateSettings/create-whatsapp-template";
import ViewWhatsappTemplate from "components/user-settings/TemplateSettings/view-whatsapp-template";

function App() {
  const auth0Context = useAuth0();
  const {
    isLoading: authLoading,
    isAuthenticated,
    loginWithRedirect,
  } = auth0Context;
  const [isLoading, setIsLoading] = useState(true);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { init: analyticsInit } = useAnalytics();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [extendedFuzeyTheme, setExtendedFuzeyTheme] = useState(FuzeyTheme);

  useEffect(() => {
    const initialColorMode = "system";
    const useSystemColorMode = true;

    setExtendedFuzeyTheme(
      extendTheme(FuzeyTheme, {
        ...withDefaultColorScheme({ colorScheme }),
        config: {
          initialColorMode,
          useSystemColorMode,
        },
      })
    );
  }, [colorScheme, merchant]);

  analyticsInit();

  const isAuthorizationRequired = () => {
    if (window.location.pathname.includes("public")) {
      return false;
    }

    if (
      !authLoading &&
      (window.location.pathname.includes("public") || isAuthenticated)
    ) {
      return false;
    }

    if (!authLoading && !isAuthenticated) {
      return true;
    }

    return null;
  };

  useEffect(() => {
    if (!isAuthorizationRequired()) {
      setIsLoading(false);
      return;
    }

    (async () => {
      // If user is not authenticated and all app data is loaded
      if (isAuthorizationRequired()) {
        await loginWithRedirect();

        return;
      }

      // If user is authenticated and all app data is loaded
      setIsLoading(false);
    })();
  }, [authLoading, isAuthenticated]);

  if (isLoading || authLoading) {
    return (
      <ChakraProvider theme={extendedFuzeyTheme}>
        <Flex alignItems="center" justifyContent="center" h="100%" w="100%">
          <Spinner size="xl" />
        </Flex>
      </ChakraProvider>
    );
  }

  const router = createBrowserRouter([
    {
      path: "/",
      Component: PrivateRoot,
      children: [
        {
          path: ":merchantId",
          element: <MerchantPrivateRoute />,
          children: [
            {
              path: "inbox",
              element: <Chat />,
              children: [
                {
                  path: ":conversationId",
                  element: <MessagesArea />,
                },
              ],
            },
            {
              path: "reviews",
              element: <ReviewsPage />,
              children: [
                {
                  path: ":reviewId",
                  element: <ReviewPage />,
                },
              ],
            },
            {
              path: "payments",
              element: <PaymentsPage />,
            },
            {
              path: "calendar",
              element: <Calendar />,
            },
            {
              path: "analytics",
              element: <AnalyticsPage />,
            },
            {
              path: "invoicing",
              element: <InvoicingOptions />,
            },
            {
              path: "campaigns/new",
              element: <NewCampaignForm />,
            },
            {
              path: "campaigns/edit/:campaignId",
              element: <NewCampaignForm />,
            },
            {
              path: "campaigns/details/:campaignId",
              element: <NewCampaignForm />,
            },
            {
              path: "campaigns",
              element: <NewCampaignsList />,
            },
            {
              path: "settings",
              element: <UserSettings />,
              children: [
                {
                  index: true,
                  element: <AccountOverview />,
                },
                {
                  path: "channels",
                  element: <Channels />,
                },
                {
                  path: "integrations",
                  element: <NewIntegrations />,
                  children: [
                    {
                      path: "keyloop",
                      element: <Keyloop />,
                    },
                  ],
                },
                {
                  path: "account",
                  element: <AccountOverview />,
                },
                {
                  path: "templates",
                  element: <TemplateSettings />,
                  children: [
                    {
                      path: "whatsapp/:templateId",
                      element: <ViewWhatsappTemplate />,
                    },
                  ],
                },
                {
                  path: "automations",
                  element: <AutomationSettings />,
                },
                {
                  path: "teammates",
                  element: <TeamMemberSettings />,
                },
                {
                  path: "permissions",
                  element: <RolePermissions />,
                },
              ],
            },
            {
              path: "settings/integrations/zapier",
              element: <ZapierIntegration />,
            },
            {
              path: "settings/integrations/whatsapp",
              element: <WhatsappIntegration />,
            },
            {
              path: "settings/templates/create",
              element: <TemplateView />,
            },
            {
              path: "settings/templates/whatsapp/create",
              element: <CreateWhatsappTemplate />,
            },
            {
              path: "settings/templates/:templateId",
              element: <TemplateView />,
            },
            {
              path: "settings/teammates/:memberId",
              element: <TeamMemberForm />,
            },
            {
              path: "contacts",
              element: <ContactsPage />,
              children: [
                {
                  path: ":contactId",
                  element: <ContactDetails />,
                },
              ],
            },
            {
              path: "contacts/edit/:contactId",
              element: <EditContactPage />,
            },
            {
              path: "contacts/create",
              element: <CreateContactPage />,
            },
            {
              path: "admin",
              element: <Admin />,
              children: [
                {
                  index: true,
                  element: <AdminNavigation />,
                },
                {
                  path: "merchants",
                  element: <Merchants />,
                },
                {
                  path: "agents",
                  element: <Agents />,
                },
                {
                  path: "ai",
                  element: <AI />,
                },
              ],
            },
            {
              index: true,
              element: <Chat />,
            },
          ],
        },
        {
          index: true,
          element: (
            <AbstractPrivateRoute>
              <Chat />
            </AbstractPrivateRoute>
          ),
        },
        {
          path: "reviews/google/locations",
          element: (
            <AbstractPrivateRoute>
              <GoogleLocationsOptions />
            </AbstractPrivateRoute>
          ),
        },
        {
          path: "settings/integrations/keyloop",
          element: (
            <AbstractPrivateRoute>
              <Keyloop />
            </AbstractPrivateRoute>
          ),
        },
        {
          path: "reviews/google/callback",
          element: (
            <AbstractPrivateRoute>
              <GoogleReviewsCallback />
            </AbstractPrivateRoute>
          ),
        },
        {
          path: "google/gmail/callback",
          element: (
            <AbstractPrivateRoute>
              <GoogleGmailCallback />
            </AbstractPrivateRoute>
          ),
        },
        {
          path: "microsoft/callback",
          element: (
            <AbstractPrivateRoute>
              <MicrosoftOutlookCallback />
            </AbstractPrivateRoute>
          ),
        },
        {
          path: "stripe/callback",
          element: (
            <AbstractPrivateRoute>
              <StripeCallback />
            </AbstractPrivateRoute>
          ),
        },
        {
          path: "facebook/callback/messenger",
          element: (
            <AbstractPrivateRoute>
              <FacebookMessengerCallback />
            </AbstractPrivateRoute>
          ),
        },
        {
          path: "facebook/callback/instagram",
          element: (
            <AbstractPrivateRoute>
              <InstagramCallback />
            </AbstractPrivateRoute>
          ),
        },
      ],
    },
    {
      path: "/xero",
      element: (
        <WebSocketProvider>
          <AbstractPrivateRoute>
            <XeroPage />
          </AbstractPrivateRoute>
        </WebSocketProvider>
      ),
    },
    {
      path: "/quickbooks",
      element: (
        <WebSocketProvider>
          <AbstractPrivateRoute>
            <QuickbooksPage />
          </AbstractPrivateRoute>
        </WebSocketProvider>
      ),
    },
    {
      path: "/public",
      Component: PublicRoot,
      children: [
        {
          path: "pay/:payment_id",
          element: <ExternalPayment />,
        },
        {
          path: "payments/callback",
          element: <ExternalPaymentCallback />,
        },
        {
          path: "contact-form",
          element: <ContactForm />,
        },
        {
          path: "contact-form/success",
          element: <ContactFormSuccess />,
        },
        {
          path: "callback/success",
          element: <CallbackSuccess />,
        },
        {
          path: "callback/fail",
          element: <CallbackFail />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/inbox" replace={true} />,
    },
  ]);

  return (
    <ChakraProvider
      theme={extendedFuzeyTheme}
      toastOptions={{
        defaultOptions: {
          position: "bottom-right",
          duration: 3000,
          isClosable: true,
        },
      }}
    >
      <ScreenSettingsProvider>
        <RouterProvider router={router} />
        <StaleDataManager />
      </ScreenSettingsProvider>
    </ChakraProvider>
  );
}

export default App;
