import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import BackIconButton from "components/shared/BackButton";
import Topbar from "components/shared/topbar/TopBar";
import {
  WabaTemplateCategory,
  WhatsappButton,
} from "entities/domain/whatsapp-template";
import React, { useCallback, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";
import TemplatesService from "services/templates";
import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import useTemplatesStore from "hooks/use-templates-store";
import HeaderBuildingBlock from "./HeaderBuildingBlock";
import BodyBuildingBlock from "./BodyBuildingBlock";
import FooterBuildingBlock from "./FooterBuildingBlock";
import ButtonsBuildingBlock from "./ButtonsBuildingBlock";
import ValidationErrors from "./ValidationErrors";
import Preview from "./Preview";

interface SelectCategoryOption {
  value: WabaTemplateCategory;
  label: string;
}

const REPLY_TEXT = "Reply UNSUB to unsubscribe";

const categoryOptions: SelectCategoryOption[] = [
  {
    value: WabaTemplateCategory.MARKETING,
    label: "Marketing",
  },
  {
    value: WabaTemplateCategory.UTILITY,
    label: "Utility",
  },
];

const CreateWhatsappTemplate = () => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const toast = useToast();
  const navigate = useNavigate();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { templates } = useAppSelector((state) => state.templates);
  const { fetchTemplates } = useTemplatesStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTemplateValid, setIsTemplateValid] = useState<boolean>(false);

  const [templateCategory, setTemplateCategory] =
    useState<WabaTemplateCategory>(WabaTemplateCategory.UTILITY);
  const [templateName, setTemplateName] = useState<string>("");
  const [headerText, setHeaderText] = useState<string>("");
  const [bodyText, setBodyText] = useState<string>("");
  const [footerText, setFooterText] = useState<string>("");
  const [buttons, setButtons] = useState<WhatsappButton[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [nameError, setNameError] = useState<string>("");

  const memoizedSetHeaderText = useCallback(setHeaderText, []);
  const memoizedSetBodyText = useCallback(setBodyText, []);
  const memoizedSetFooterText = useCallback(setFooterText, []);
  const memoizedSetButtons = useCallback(setButtons, []);
  const memoizedSetFile = useCallback(setFile, []);
  const memoizedSetIsTemplateValid = useCallback(setIsTemplateValid, []);

  const [selectedCategoryValue, setSelectedCategoryValue] = useState<
    SingleValue<SelectCategoryOption>
  >(categoryOptions.find(({ value }) => value === templateCategory) || null);

  const handleCategoryChange = (
    selectedOption: SingleValue<SelectCategoryOption>
  ) => {
    if (!selectedOption) {
      return;
    }

    setSelectedCategoryValue(selectedOption);
    setTemplateCategory(selectedOption.value);
  };

  useEffect(() => {
    if (!templates.length) {
      fetchTemplates(undefined, false);

      return;
    }

    if (templates[0] && !templates[0].isWhatsappTemplate()) {
      fetchTemplates(undefined, false);

      return;
    }
  }, []);

  useEffect(() => {
    setSelectedCategoryValue(
      categoryOptions.find(({ value }) => value === templateCategory) || null
    );
    if (templateCategory === WabaTemplateCategory.MARKETING) {
      setFooterText(REPLY_TEXT);
    } else if (footerText === REPLY_TEXT) {
      setFooterText("");
    }
  }, [templateCategory]);

  const createWhatsappTemplate = useCallback(async () => {
    setIsLoading(true);
    try {
      await TemplatesService.createWhatsappTemplate(
        auth0Context,
        {
          name: templateName,
          category: templateCategory,
          headerText,
          bodyText,
          footerText,
          file,
          buttons: buttons.map((button) => ({
            type: button.type,
            text: button.text,
            url: button.url,
            phone_number: button.phoneNumber,
          })),
        },
        merchant.id
      );

      toast({
        status: "success",
        title: "WhatsApp Template created successfully!",
      });

      await setTimeout(() => {
        navigate(`/${merchant.id}/settings/templates`);
      }, 1000);
    } catch (error: unknown) {
      let description;

      if (error instanceof AxiosError) {
        if (error.response?.data?.message) {
          description = error.response.data.message;
        }
      }

      toast({
        status: "error",
        title:
          "Couldn't create WhatsApp Template! Please try again later or contact our customer support.",
        description,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    templateName,
    templateCategory,
    headerText,
    bodyText,
    footerText,
    buttons,
    file,
  ]);

  useEffect(() => {
    if (!templateName) {
      setNameError("Name is required");
    } else if (!/^[a-z_0-9]+$/.test(templateName)) {
      setNameError(
        "Name should contain only lowercase letters and underscores"
      );
    } else {
      setNameError("");
    }

    // we shouldn't check for the template name uniqueness at all times
    if (templates.length && templates[0].isWhatsappTemplate()) {
      if (templates.some((template) => template.name === templateName)) {
        setNameError("A template with this name already exists");
      }
    }
  }, [templateName]);

  return (
    <VStack w="100%" h="100%" spacing={0}>
      <Topbar
        title="Create WhatsApp Template"
        leftChildrenMobile={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate(`/${merchant.id}/settings/templates`);
            }}
          />
        }
        leftChildren={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate(`/${merchant.id}/settings/templates`);
            }}
          />
        }
      />
      <HStack
        w="100%"
        h="100%"
        alignItems="start"
        justifyContent="space-between"
        spacing={8}
        p={isBaseSize ? 4 : 6}
        overflowY="auto"
        pb="25rem"
      >
        <VStack
          w={isBaseSize ? "100%" : "60%"}
          h="100%"
          spacing={4}
          pb={isBaseSize ? "40rem" : "25rem"}
        >
          <Flex
            w="100%"
            alignItems="start"
            justifyContent="start"
            gridGap={6}
            direction={isBaseSize ? "column" : "row"}
          >
            <FormControl isInvalid={!!nameError} isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                id="template-name-input"
                placeholder="e.g. discount_offer_september"
                isDisabled={isLoading}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
              <FormErrorMessage>{nameError}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Select
                id="template-category-select"
                placeholder="Select category"
                isDisabled={isLoading}
                onChange={handleCategoryChange}
                isClearable={false}
                options={categoryOptions}
                value={selectedCategoryValue}
                styles={getReactSelectStyles(colorMode, colorScheme)}
              />
            </FormControl>
          </Flex>
          <HeaderBuildingBlock
            isDisabled={isLoading}
            onTextChange={memoizedSetHeaderText}
            onSetFile={memoizedSetFile}
          />
          <BodyBuildingBlock
            isDisabled={isLoading}
            onTextChange={memoizedSetBodyText}
          />
          <FooterBuildingBlock
            isDisabled={isLoading}
            category={templateCategory}
            text={footerText}
            onTextChange={memoizedSetFooterText}
          />
          <ButtonsBuildingBlock
            isDisabled={isLoading}
            onButtonsChange={memoizedSetButtons}
          />
          <ValidationErrors
            onValidationComplete={memoizedSetIsTemplateValid}
            templateName={templateName}
            templateCategory={templateCategory}
            headerText={headerText}
            bodyText={bodyText}
            footerText={footerText}
            buttons={buttons}
            file={file}
          />
        </VStack>
        {isBaseSize ? null : (
          <VStack w="40%" h="100%">
            <Text>Preview</Text>
            <Preview
              headerText={headerText}
              bodyText={bodyText}
              footerText={footerText}
              file={file}
              buttons={buttons}
            />
          </VStack>
        )}
      </HStack>
      <Flex
        justifyContent={isBaseSize ? "center" : "end"}
        alignItems="center"
        position="sticky"
        bottom={0}
        p={5}
        w="100%"
        bgColor={colorMode === "dark" ? "gray.800" : "gray.50"}
        boxShadow={`0 -0.25rem 1rem ${
          colorMode === "dark" ? "black" : "lightgray"
        }`}
      >
        <Button
          id="whatsapp-template-form-save-button"
          size={isBaseSize ? "sm" : "md"}
          colorScheme={colorScheme}
          onClick={createWhatsappTemplate}
          isLoading={isLoading}
          isDisabled={isLoading || !isTemplateValid || !!nameError}
        >
          Create
        </Button>
      </Flex>
    </VStack>
  );
};

export default CreateWhatsappTemplate;
