import WarningTextComponent, {
  WarningTextProps,
} from "components/shared/WarningTextComponent";
import {
  BodySize,
  BodyText,
  Color,
  LHeight,
} from "theme/old-design-system/styled-components";
import React from "react";
import CurrencyInput from "react-currency-input-field";
import { ReactComponent as UploadIcon } from "assets/icons/Union-ds.svg";
import { Icon, Button, Text, Flex } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import { AmountType } from ".";

interface CurrencyInputOnChangeValues {
  float: number | null;
  formatted: string;
  value: string;
}

const BaseInputStyles = {
  width: "100%",
  border: `1px solid ${Color.LIGHT_GREY.value}`,
  backgroundColor: "inherit",
  borderRadius: "18px",
  fontSize: "1rem",
  height: "30px",
  textAlign: "center",
};

interface PaymentsStepOneProps {
  proceed: () => void;
  amount: AmountType;
  currencySymbol: string | undefined;
  handleOnAmountChange: (
    amount: string | undefined,
    name?: string,
    values?: CurrencyInputOnChangeValues
  ) => void;
  servicesRendered: string;
  setServicesRendered: (servicesRendered: string) => void;
  warning: WarningTextProps | undefined;
  setProceedWithInvoice: (value: boolean) => void;
  proceedWithInvoice: boolean;
  showInvoice: boolean;
}

const PaymentsStepOne = ({
  proceed,
  amount,
  warning,
  currencySymbol,
  handleOnAmountChange,
  servicesRendered,
  setServicesRendered,
  setProceedWithInvoice,
  proceedWithInvoice,
  showInvoice,
}: PaymentsStepOneProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Flex
        width="100%"
        flexFlow="column nowrap"
        alignItems="center"
        mb="1rem"
        sx={{
          "#amount-input": BaseInputStyles,
        }}
      >
        <Text display="block" mb="4px" fontSize="md">
          Amount
        </Text>
        {warning && warning.key === "invalid_payment_amount" && (
          <WarningTextComponent
            isValid={false}
            text={warning.description}
            id="invalid-payment-amount"
          />
        )}
        <CurrencyInput
          placeholder={`${currencySymbol}0.00`}
          prefix={currencySymbol}
          value={amount}
          onValueChange={(value, name, values) =>
            handleOnAmountChange(value, name, values)
          }
          id="amount-input"
        />
      </Flex>

      <Flex width="100%" flexFlow="column nowrap" alignItems="center" mb="1rem">
        <Text display="block" mb="4px" fontSize="md">
          Services rendered
        </Text>
        {warning && warning.key === "invalid_description" && (
          <WarningTextComponent
            isValid={false}
            text={warning.description}
            id="invalid-payment-description"
          />
        )}
        <input
          style={BaseInputStyles as any}
          placeholder="Details Here"
          value={servicesRendered}
          onChange={(e) => setServicesRendered(e.target.value)}
          id="amount-description"
        />
      </Flex>

      {showInvoice && (
        <Flex width="100%" alignItems="center" justifyContent="center">
          <BodyText size={BodySize.FOUR} lheight={LHeight.EIGHT}>
            Send with invoice
          </BodyText>

          <input
            style={{
              marginLeft: "5px",
            }}
            checked={proceedWithInvoice}
            type="checkbox"
            onChange={(e) => {
              return e.target.checked
                ? setProceedWithInvoice(true)
                : setProceedWithInvoice(false);
            }}
          />
        </Flex>
      )}

      {!warning && (
        <>
          <Button
            colorScheme={colorScheme}
            mt={2}
            onClick={proceed}
            w="100%"
            id="payment-step-one-confirm"
          >
            Confirm and Send
            <Icon
              position="absolute"
              right={3}
              as={UploadIcon}
              __css={{
                path: {
                  fill: "white",
                },
              }}
            />
          </Button>
        </>
      )}
    </>
  );
};

export default PaymentsStepOne;
