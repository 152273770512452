import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchMerchant,
  fetchMerchantFail,
  fetchMerchantSuccess,
  fetchMerchantUsageFail,
  fetchMerchantUsageSuccess,
  updateMerchant,
  updateMerchantFail,
  updateMerchantSuccess,
} from "redux/features/merchant";
import { useCallback } from "react";
import MerchantService, { UpdateMerchantPayload } from "services/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TagsDomain from "entities/domain/tags/tags-domain";
import { addTag } from "redux/features/tags";
import TagsService from "services/tags";

export default function useMerchantStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();

  const { merchant } = useAppSelector((state) => state.merchant);

  const fetchMerchantWaterfall = (id: number) => async () => {
    try {
      dispatch(fetchMerchant());

      const merchantResponse = await MerchantService.getMerchant(
        auth0Context,
        id
      );

      dispatch(fetchMerchantSuccess(merchantResponse));
    } catch (err) {
      dispatch(
        fetchMerchantFail(["Oops. We couldn't get merchant information"])
      );
    }
  };

  const fetchMerchantAsync = useCallback(
    (id: number) => dispatch(fetchMerchantWaterfall(id)),
    [dispatch]
  );

  const updateMerchantWaterfall =
    (payload: UpdateMerchantPayload) => async () => {
      try {
        dispatch(updateMerchant());

        const merchantResponse = await MerchantService.updateMerchant(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(updateMerchantSuccess(merchantResponse));
      } catch (err) {
        dispatch(
          updateMerchantFail(["Oops. We couldn't get merchant information"])
        );
      }
    };

  const updateMerchantAsync = useCallback(
    (payload: UpdateMerchantPayload) =>
      dispatch(updateMerchantWaterfall(payload)),
    [dispatch, merchant]
  );

  const fetchMerchantUsageWaterfall = () => async () => {
    try {
      const merchantUsage = await MerchantService.getMerchantUsage(
        auth0Context,
        merchant.id
      );

      dispatch(fetchMerchantUsageSuccess(merchantUsage));
    } catch (err) {
      // eslint-disable-next-line
      console.error("Error fetching merchant usage", err);
      dispatch(
        fetchMerchantUsageFail([
          "Oops. We couldn't get merchant usage information",
        ])
      );
    }
  };

  const addMerchantTagsWaterfall =
    (tags: string[]) => async (): Promise<TagsDomain[] | undefined> => {
      try {
        const merchantTags = await TagsService.addTags(
          auth0Context,
          merchant.id,
          tags
        );

        merchantTags.forEach((newTag) =>
          dispatch(
            addTag({
              id: newTag.id,
              name: newTag.tag,
            })
          )
        );

        return merchantTags;
      } catch (err) {
        // eslint-disable-next-line
        console.error("Error updating merchant tags", err);
      }
    };

  const fetchMerchantUsageAsync = useCallback(
    () => dispatch(fetchMerchantUsageWaterfall()),
    [dispatch, merchant]
  );

  const addMerchantTagsAsync = useCallback(
    (tags: string[]) => addMerchantTagsWaterfall(tags)(),
    [dispatch, merchant]
  );

  return {
    fetchMerchant: fetchMerchantAsync,
    updateMerchant: updateMerchantAsync,
    fetchMerchantUsage: fetchMerchantUsageAsync,
    addMerchantTags: addMerchantTagsAsync,
  };
}
