import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as EmojiButtonIcon } from "assets/icons/fi-rr-smile.svg";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { ReactComponent as BracesIcon } from "assets/icons/braces-svgrepo-com.svg";
import React, { useState } from "react";
import { CustomFields } from "entities/domain/templates";
import { useAppSelector } from "redux/hooks";

interface ToolbarProps {
  isDisabled: boolean;
  onEmojiSelect?: (emojiData: { native: string }) => void;
  onCustomFieldSelect?: (cf: string) => void;
}

export const customFieldsDefinition: CustomFields = {
  contact_first_name: "Contact First Name",
  contact_last_name: "Contact Last Name",
  merchant_name: "Merchant Name",
  review_link: "Review Link",
};

const Toolbar = ({
  isDisabled,
  onEmojiSelect,
  onCustomFieldSelect,
}: ToolbarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);

  return (
    <HStack alignItems="center">
      {onCustomFieldSelect === undefined ? null : (
        <Menu>
          {isBaseSize ? (
            <MenuButton
              isDisabled={isDisabled}
              colorScheme="green"
              as={IconButton}
              icon={
                <Icon
                  as={BracesIcon}
                  __css={{
                    "path:last-child": {
                      fill: colorMode === "dark" ? "black" : "white",
                    },
                  }}
                />
              }
            />
          ) : (
            <MenuButton
              isDisabled={isDisabled}
              className="menu-custom-field-button"
              colorScheme="green"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              leftIcon={
                <Icon
                  as={BracesIcon}
                  __css={{
                    "path:last-child": {
                      fill: colorMode === "dark" ? "black" : "white",
                    },
                  }}
                />
              }
            >
              Add Custom Field
            </MenuButton>
          )}
          <Portal>
            <MenuList>
              {Object.entries(customFieldsDefinition).map(([key, value]) => (
                <MenuItem
                  key={key}
                  onClick={() => {
                    if (isDisabled) {
                      return;
                    }
                    onCustomFieldSelect(`{${key}}`);
                  }}
                >
                  {value}
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        </Menu>
      )}
      {isBaseSize || onEmojiSelect === undefined ? null : (
        <Popover
          closeOnBlur={true}
          isOpen={openEmojiPopover}
          onClose={() => {
            setOpenEmojiPopover(false);
          }}
        >
          <Portal>
            <PopoverContent>
              <Picker
                data={data}
                theme={colorMode}
                onEmojiSelect={onEmojiSelect}
              />
            </PopoverContent>
          </Portal>
          <PopoverTrigger>
            <IconButton
              aria-label="Select Emoji"
              isDisabled={isDisabled}
              variant="unstyled"
              colorScheme={colorScheme}
              onClick={() => {
                setOpenEmojiPopover(!openEmojiPopover);
              }}
              display="flex"
              alignItems="center"
              icon={
                <Icon
                  as={EmojiButtonIcon}
                  __css={{
                    height: "2.1rem",
                    width: "2.1rem",
                    g: {
                      path: {
                        fill:
                          colorMode === "dark" ? "yellow.200" : "yellow.500",
                      },
                    },
                  }}
                />
              }
            />
          </PopoverTrigger>
        </Popover>
      )}
    </HStack>
  );
};

export default Toolbar;
