import { Text, useColorMode } from "@chakra-ui/react";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";

const ContactLastInteractionColumn = ({ item }: { item: ContactDomain }) => {
  const { colorMode } = useColorMode();

  return (
    <Text
      textAlign="center"
      color={colorMode === "dark" ? "gray.400" : "gray.600"}
    >
      {item.getLastContactedDate()}
    </Text>
  );
};

export default ContactLastInteractionColumn;
