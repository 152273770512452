import {
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Icon,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { canImportContacts } from "util/permissions";
import ImportContacts from "components/modals/import-contacts/ImportContacts";
import { useAppSelector } from "redux/hooks";
import { ReactComponent as ImportIcon } from "assets/icons/import-svgrepo-com.svg";
import { ReactComponent as ExportIcon } from "assets/icons/export-svgrepo-com.svg";
import ContactsService from "services/contacts";
import { useAuth0 } from "@auth0/auth0-react";
import ExportContactsModal from "../../ExportContactsModal";

interface ImportExportProps {}

const ImportExportButtons: React.FC<ImportExportProps> = (
  _props: ImportExportProps
) => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);

  const [isUploadContacts, setIsUploadContacts] = useState<boolean>(false);
  const [isExportingCsv, setIsExportingCsv] = useState<boolean>(false);
  const [showExportContactsModal, setShowExportContactsModal] =
    useState<boolean>(false);

  const handleExportCsv = async (
    unsubOnly?: boolean,
    from?: Date,
    to?: Date
  ) => {
    setIsExportingCsv(true);

    try {
      await ContactsService.exportCustomersAsCsv(
        auth0Context,
        merchant.id,
        unsubOnly,
        from,
        to
      );
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Error exporting contacts as CSV", e);
    } finally {
      setIsExportingCsv(false);
    }
  };

  return (
    <ButtonGroup isAttached colorScheme="gray">
      <Button
        onClick={() => setIsUploadContacts(true)}
        borderRightColor={colorMode === "dark" ? "black" : "white"}
        borderRightStyle="solid"
        borderRightWidth="1px"
        isDisabled={!canImportContacts(merchant.id, currentAgent!)}
        leftIcon={
          <Icon
            as={ImportIcon}
            __css={{
              path: {
                ":first-child": {
                  fill: colorMode === "dark" ? "gray.900" : "white",
                },
                ":last-child": {
                  stroke: colorMode === "dark" ? "gray.900" : "white",
                },
              },
            }}
          />
        }
      >
        <Text>Import</Text>
      </Button>
      <Button
        onClick={() => setShowExportContactsModal(true)}
        isDisabled={!canImportContacts(merchant.id, currentAgent!)}
        leftIcon={
          <Icon
            as={ExportIcon}
            __css={{
              path: {
                ":first-child": {
                  fill: colorMode === "dark" ? "gray.900" : "white",
                },
                ":last-child": {
                  stroke: colorMode === "dark" ? "gray.900" : "white",
                },
              },
            }}
          />
        }
      >
        <Text>Export</Text>
      </Button>
      <ExportContactsModal
        handleExportAll={(from?: Date | null, to?: Date | null) =>
          handleExportCsv(false, from!, to!)
        }
        handleExportUnsub={(from?: Date | null, to?: Date | null) =>
          handleExportCsv(true, from!, to!)
        }
        isOpen={showExportContactsModal}
        isExportingCsv={isExportingCsv}
        setIsOpen={setShowExportContactsModal}
      />
      <ImportContacts
        isOpen={isUploadContacts}
        onClose={() => setIsUploadContacts(false)}
      />
    </ButtonGroup>
  );
};

export default ImportExportButtons;
