import { IconButton } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { Icon } from "@chakra-ui/icon";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { Flex, Text } from "@chakra-ui/layout";
import { useAppSelector } from "redux/hooks";
import React, { memo, useEffect, useState } from "react";

interface MarketingPrefixProps {
  prefix: string;
  setPrefix: (newPrefix: string) => void;
  isDisabled?: boolean;
}

const MarketingPrefix: React.FC<MarketingPrefixProps> = ({
  prefix,
  setPrefix,
  isDisabled = false,
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [localPrefix, setLocalPrefix] = useState<string>(prefix);

  useEffect(() => {
    setLocalPrefix(prefix);
  }, [prefix]);

  return (
    <Flex
      alignItems="center"
      justifyContent="start"
      gridGap={2}
      borderRadius="md"
      borderColor={colorMode === "dark" ? "gray.900" : "gray.50"}
      borderStyle="solid"
      borderWidth="1px"
      width="fit-content"
      p={2}
      mb={2}
    >
      {isEditing ? (
        <>
          <Input
            border="none"
            value={localPrefix}
            onChange={(e) => {
              setLocalPrefix(e.target.value);
            }}
            placeholder="Enter the name of your business"
          />
          <IconButton
            variant="ghost"
            aria-label="Cancel"
            colorScheme="red"
            icon={<Icon as={CloseIcon} boxSize={2} />}
            onClick={() => {
              setLocalPrefix(prefix);
              setIsEditing(false);
            }}
          />
          <IconButton
            variant="ghost"
            aria-label="Edit prefix"
            colorScheme="green"
            icon={<Icon as={CheckIcon} boxSize={3} />}
            onClick={() => {
              if (localPrefix.trim().length === 0) {
                setPrefix("");
                setIsEditing(false);

                return;
              }

              if (/.+:$/.test(localPrefix)) {
                setPrefix(localPrefix);
              } else {
                setPrefix(`${localPrefix}:`);
              }

              setIsEditing(false);
            }}
          />
        </>
      ) : (
        <>
          <Text textColor={colorMode === "dark" ? "gray.100" : "gray.600"}>
            {prefix || "Add company prefix"}
          </Text>
          <IconButton
            isDisabled={isDisabled}
            variant="ghost"
            aria-label="Edit prefix"
            icon={<Icon as={EditIcon} boxSize={4} />}
            colorScheme={colorScheme}
            onClick={() => setIsEditing(true)}
          />
        </>
      )}
    </Flex>
  );
};

export default memo(MarketingPrefix);
