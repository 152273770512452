import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import axios, { AxiosError } from "axios";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import { CustomFields } from "entities/domain/templates";
import {
  conversationsTransformFromDtoToDomain,
  conversationTransformFromDtoToDomain,
} from "entities/transformers/conversation-transformer";
import { AssignAgentPayload } from "redux/features/conversations";
import { ConversationDTO, MessageDTO } from "entities/dto/ConversationDTO";
import {
  messagesTransformFromDtoToDomain,
  messageTransformFromDtoToDomain,
} from "entities/transformers/conversationTransformers";
import { ReviewProvider } from "entities/domain/reviews/review-domain";
import { PromptDTO } from "entities/dto/PromptDTO";
import PromptDomain from "entities/domain/prompt";
import { promptTransformFromDtoToDomain } from "entities/transformers/prompt-transformer";
import { UnreadCountDTO } from "entities/dto/UnreadCountDTO";
import { RequestType } from "./request-type";
import { apiUrl, numberOfConversationsPerLoad } from "../util/constants";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  postRequest,
  putRequest,
  request,
} from "../util/methods";

class InboxService {
  public static async getInstagramAccounts(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<
    {
      id: string;
      name: string;
    }[]
  > {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await request<
        {
          id: string;
          name: string;
          logo?: string;
        }[]
      >(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/instagram/accounts`
      )
    ).data;

    return response;
  }

  public static async submitSelectedInstagramAccounts(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently }: Auth0ContextInterface,
    selectedAccounts: string[],
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await postRequest(
        accessToken,
        `/merchants/${merchantId}/instagram/accounts`,
        {
          account_ids: selectedAccounts,
        }
      )
    ).data;

    return response;
  }

  public static async requestReview(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number,
    provider: ReviewProvider
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await postRequest(
      accessToken,
      `/merchants/${merchantId}/conversations/${conversationId}/messages/review`,
      { provider }
    );

    return;
  }

  public static async submitSelectedFacebookPages(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently }: Auth0ContextInterface,
    selectedPages: string[],
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await postRequest(
        accessToken,
        `/merchants/${merchantId}/facebook/pages`,
        {
          page_ids: selectedPages,
        }
      )
    ).data;

    return response;
  }

  public static async getFacebookPages(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<
    {
      id: string;
      name: string;
      logo?: string;
    }[]
  > {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await request<
        {
          id: string;
          name: string;
        }[]
      >(RequestType.GET, accessToken, `/merchants/${merchantId}/facebook/pages`)
    ).data;

    return response;
  }

  public static async getAutoFixedMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    text: string = ""
  ): Promise<{
    text: string;
    prettified_text: string;
  }> {
    const accessToken = await getAccessTokenSilently();

    const autoFixedTextResponse = (
      await postRequest(accessToken, "/text-prettifier", { text })
    ).data;

    return autoFixedTextResponse;
  }

  public static async getAutoReplyMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number
  ): Promise<{
    conversation_id: number;
    suggested_reply: string;
  }> {
    const accessToken = await getAccessTokenSilently();

    const autoReplyMessageResponse = (
      await postRequest(
        accessToken,
        `/merchants/${merchantId}/suggested-reply`,
        {
          conversation_id: conversationId,
        }
      )
    ).data;

    return autoReplyMessageResponse;
  }

  public static async getSuggestedCampaign(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    merchantId: number,
    textPrompt: string,
    channel: ConversationChannel
  ): Promise<{
    suggestion: string;
  }> {
    const accessToken = await getAccessTokenSilently();

    const suggestedCampaignResponse = (
      await postRequest(
        accessToken,
        `merchants/${merchantId}/suggestion/campaign`,
        {
          input: textPrompt,
          channel,
        }
      )
    ).data;

    return suggestedCampaignResponse;
  }

  public static async getSuggestedTemplate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    merchantId: number,
    textPrompt: string
  ): Promise<{
    suggestion: string;
  }> {
    const accessToken = await getAccessTokenSilently();

    const suggestedCampaignResponse = (
      await postRequest(
        accessToken,
        `merchants/${merchantId}/suggestion/template`,
        {
          input: textPrompt,
        }
      )
    ).data;

    return suggestedCampaignResponse;
  }

  public static async getConversations(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    status: string,
    searchText: string,
    offset: number,
    merchantId: number,
    channels?: string[],
    agents?: string[],
    tagIds?: string[],
    teamInbox?: string | null
  ): Promise<ConversationDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParam: string[] = [];
    if (searchText) {
      queryParam.push(`q=${searchText}`);
    }
    if (status) {
      queryParam.push(`status=${status}`);
    }
    if (teamInbox) {
      queryParam.push(`team_id=${teamInbox}`);
    }

    queryParam.push(`maxPageSize=${numberOfConversationsPerLoad}`);
    queryParam.push(`offset=${offset}`);

    if (channels && channels.length > 0) {
      queryParam.push(`channels=${channels}`);
    }
    if (agents && agents.length > 0) {
      queryParam.push(`agents=${agents}`);
    }
    if (tagIds && tagIds.length > 0) {
      queryParam.push(`tag_ids=${tagIds}`);
    }

    const queryString = queryParam.join("&").replace("+", "%2B");
    const url = `/merchants/${merchantId}/conversations?${queryString}`;

    // todo: use actual user id from db/auth0
    const conversationsResponse = (
      await request<ConversationDTO[]>(RequestType.GET, accessToken, url)
    ).data;

    return conversationsTransformFromDtoToDomain(conversationsResponse);
  }

  public static async getConversationsByIds(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    merchantId: number,
    conversationIds: number[]
  ): Promise<ConversationDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const url = `/merchants/${merchantId}/conversations?ids=${conversationIds.join(
      ","
    )}`;

    const conversationsResponse = (
      await request<ConversationDTO[]>(RequestType.GET, accessToken, url)
    ).data;

    return conversationsTransformFromDtoToDomain(conversationsResponse);
  }

  public static async getPersonalConversations(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    status: string,
    searchText: string,
    offset: number,
    merchantId: number,
    channels?: string[],
    tagIds?: string[],
    teamInbox?: string | null
  ): Promise<ConversationDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParam: string[] = [];
    if (searchText) {
      queryParam.push(`q=${searchText}`);
    }
    if (status) {
      queryParam.push(`status=${status}`);
    }
    if (teamInbox) {
      queryParam.push(`team_id=${teamInbox}`);
    }

    queryParam.push(`maxPageSize=${numberOfConversationsPerLoad}`);
    queryParam.push(`offset=${offset}`);

    if (channels && channels.length > 0) {
      queryParam.push(`channels=${channels}`);
    }
    if (tagIds && tagIds.length > 0) {
      queryParam.push(`tag_ids=${tagIds}`);
    }

    const queryString = queryParam.join("&").replace("+", "%2B");
    const url = `/merchants/${merchantId}/conversations/personal?${queryString}`;

    // todo: use actual user id from db/auth0
    const conversationsResponse = (
      await request<ConversationDTO[]>(RequestType.GET, accessToken, url)
    ).data;

    return conversationsTransformFromDtoToDomain(conversationsResponse);
  }

  public static async getUnassignedConversations(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    status: string,
    searchText: string,
    offset: number,
    merchantId: number,
    channels?: string[],
    tagIds?: string[],
    teamInbox?: string | null
  ): Promise<ConversationDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParam: string[] = ["agents=unassigned"];
    if (searchText) {
      queryParam.push(`q=${searchText}`);
    }
    if (status) {
      queryParam.push(`status=${status}`);
    }
    if (teamInbox) {
      queryParam.push(`team_id=${teamInbox}`);
    }

    queryParam.push(`maxPageSize=${numberOfConversationsPerLoad}`);
    queryParam.push(`offset=${offset}`);

    if (channels && channels.length > 0) {
      queryParam.push(`channels=${channels}`);
    }
    if (tagIds && tagIds.length > 0) {
      queryParam.push(`tag_ids=${tagIds}`);
    }

    const queryString = queryParam.join("&").replace("+", "%2B");
    const url = `/merchants/${merchantId}/conversations?${queryString}`;

    // todo: use actual user id from db/auth0
    const conversationsResponse = (
      await request<ConversationDTO[]>(RequestType.GET, accessToken, url)
    ).data;

    return conversationsTransformFromDtoToDomain(conversationsResponse);
  }

  public static async getConversation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    conversationId: number,
    merchantId: number
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();
    const url = `/merchants/${merchantId}/conversations/${conversationId}`;

    const conversationsResponse = (
      await request<ConversationDTO>(RequestType.GET, accessToken, url)
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async getUnreadCount(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<UnreadCountDTO> {
    const accessToken = await getAccessTokenSilently();
    const url = `/merchants/${merchantId}/conversations/unread-count`;

    const unreadCountResponse = (
      await request<UnreadCountDTO>(RequestType.GET, accessToken, url)
    ).data;

    return unreadCountResponse;
  }

  public static async getConversationByChannelId(
    { getAccessTokenSilently }: Auth0ContextInterface,
    channelId: string,
    merchantId: number
  ): Promise<ConversationDomain | null> {
    const accessToken = await getAccessTokenSilently();
    const url = `/merchants/${merchantId}/channels/${channelId}/conversation`;

    try {
      const conversationResponse = (
        await request<ConversationDTO>(RequestType.GET, accessToken, url)
      ).data;

      return conversationTransformFromDtoToDomain(conversationResponse);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response!.status === 404) {
          return null;
        }
      }

      throw e;
    }
  }

  public static async getMessagesInConversation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number,
    offset: number = 0
  ): Promise<MessageDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const messagesResponse = (
      await request<MessageDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}/messages?maxPageSize=200&offset=${offset}`
      )
    ).data;

    return messagesTransformFromDtoToDomain(messagesResponse);
  }

  public static async getPrompts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number
  ): Promise<PromptDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const promptsResponse = (
      await request<PromptDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}/prompts`
      )
    ).data;

    return promptsResponse.map(promptTransformFromDtoToDomain);
  }

  public static async deletePrompt(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number,
    promptId: string
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/conversations/${conversationId}/prompts`,
      { prompt_id: promptId }
    );
  }

  public static async assignAgentOrTeam(
    { getAccessTokenSilently }: Auth0ContextInterface,
    assignAgentPayload: AssignAgentPayload,
    merchantId: number
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();

    const { conversationId, agentId, teamId } = assignAgentPayload;

    const actualPayload: { [key: string]: string | number | null } = {};

    if (typeof agentId !== "undefined") {
      actualPayload.agent_id = agentId;
    }

    if (typeof teamId !== "undefined") {
      actualPayload.team_id = teamId;
    }

    const conversationsResponse = (
      await patchRequest<ConversationDTO>(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}`,
        actualPayload
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async updateConversation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    assignAgentpayload: {
      conversationId: number;
      /* eslint-disable camelcase */
      is_open: boolean;
    },
    merchantId: number
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();

    /* eslint-disable camelcase */
    const { conversationId, is_open } = assignAgentpayload;

    const conversationsResponse = (
      await patchRequest<ConversationDTO>(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}`,
        {
          is_open,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async exportConversation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    conversationId: number,
    exportAddress: string,
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await postRequest<ConversationDTO>(
      accessToken,
      `/merchants/${merchantId}/conversations/${conversationId}/export`,
      {
        export_address: exportAddress,
      }
    );
  }

  public static async updateMessagesReadStatus(
    { getAccessTokenSilently }: Auth0ContextInterface,
    conversationId: number,
    offsetMessageId: number,
    isRead: boolean,
    merchantId: number
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();

    const conversationResponse = (
      await putRequest<ConversationDTO>(
        RequestType.PUT,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}/read-status`,
        {
          is_read: isRead,
          offset_message_id: offsetMessageId,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationResponse);
  }

  public static async sendAttachment(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    formData: FormData,
    merchantId: number,
    conversationId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    const message = (
      await axios.post(
        `${apiUrl}/merchants/${merchantId}/conversations/${conversationId}/messages`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      )
    ).data;

    return messageTransformFromDtoToDomain(message);
  }

  public static async sendMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    sendMessagePayLoad: {
      conversation_id: number;
      body: string;
      reply_to_message_id?: number;
      title?: string;
      custom_fields?: CustomFields;
      template_name?: string;
    },
    merchantId: number,
    conversationId: number
  ): Promise<MessageDomain> {
    const accessToken = await getAccessTokenSilently();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      conversation_id,
      body,
      reply_to_message_id,
      title,
      custom_fields,
      template_name,
    } = sendMessagePayLoad;

    const message = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}/messages`,
        {
          body,
          conversation_id,
          reply_to_message_id,
          title,
          custom_fields,
          template_name,
        }
      )
    ).data;

    return messageTransformFromDtoToDomain(message);
  }

  public static async sendTestMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    sendTestMessagePayLoad: {
      handle: string;
      messageBody?: string;
      templateName?: string;
      channel: string;
    },
    merchantId: number
  ): Promise<MessageDomain> {
    const accessToken = await getAccessTokenSilently();
    // todo: use actual user id from db/auth0

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      channel,
      handle,
      messageBody: body,
      templateName,
    } = sendTestMessagePayLoad;

    if (!templateName && !body) {
      throw new Error("Either templateId or body is required");
    }

    const payload: {
      handle: string;
      channel: string;
      body?: string;
      template_name?: string;
    } = {
      handle,
      channel,
    };

    if (body) {
      payload.body = body;
    }

    if (templateName) {
      payload.template_name = templateName;
    }

    const message = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/messages/simple`,
        payload
      )
    ).data;

    return messageTransformFromDtoToDomain(message);
  }

  public static async createConversation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    createConversationCommand: {
      channel: string;
      recipient: string;
      first_name?: string;
      last_name?: string;
    },
    merchantId: number
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { channel, recipient, first_name, last_name } =
      createConversationCommand;

    const conversationsResponse = (
      await mutationRequest<ConversationDTO>(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/conversations`,
        {
          recipient,
          channel_name: channel,
          first_name,
          last_name,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async createConversationWithChannelId(
    { getAccessTokenSilently }: Auth0ContextInterface,
    customer_channel_id: string,
    merchantId: number
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();

    const conversationsResponse = (
      await mutationRequest<ConversationDTO>(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/conversations`,
        {
          customer_channel_id,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async addFacebookMessengerIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    redirect_uri: string,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/facebook/authorize`,
      {
        authorization_code,
        redirect_uri,
      }
    );

    return undefined;
  }

  public static async removeFacebookMessengerIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/facebook/authorize`
    );

    return undefined;
  }

  public static async addInstagramIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    redirect_uri: string,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/instagram/authorize`,
      {
        authorization_code,
        redirect_uri,
      }
    );

    return undefined;
  }

  public static async removeInstagramIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/instagram/authorize`
    );

    return undefined;
  }

  public static async getFacebookImage(
    { getAccessTokenSilently }: Auth0ContextInterface,
    messageId: number
  ): Promise<MessageDomain> {
    const accessToken = await getAccessTokenSilently();

    const messageResponse = (
      await request<MessageDTO>(
        RequestType.GET,
        accessToken,
        `facebook/messages/${messageId}/attachments`
      )
    ).data;

    return messageTransformFromDtoToDomain(messageResponse);
  }

  public static async bulkOpenOrCloseConversations(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationIds: number[],
    shouldOpen: boolean
  ): Promise<number[]> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await patchRequest<{ updated_conversations: number[] }>(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchantId}/conversations/bulk/${
          shouldOpen ? "open" : "close"
        }`,
        {
          conversation_ids: conversationIds,
        }
      )
    ).data;

    return response.updated_conversations;
  }

  public static async bulkMarkReadOrUnread(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationIds: number[],
    shouldMarkAsRead: boolean
  ): Promise<number[]> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await patchRequest<{ updated_conversations: number[] }>(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchantId}/conversations/bulk/${
          shouldMarkAsRead ? "read" : "unread"
        }`,
        {
          conversation_ids: conversationIds,
        }
      )
    ).data;

    return response.updated_conversations;
  }

  public static async bulkAssignConversations(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationIds: number[],
    agentId?: number | null,
    teamId?: string | null
  ): Promise<number[]> {
    const accessToken = await getAccessTokenSilently();

    const payload: { [key: string]: string | number | number[] | null } = {
      conversation_ids: conversationIds,
    };

    if (typeof agentId !== "undefined") {
      payload.agent_id = agentId;
    }

    if (typeof teamId !== "undefined") {
      payload.team_id = teamId;
    }

    const response = (
      await patchRequest<{ updated_conversations: number[] }>(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchantId}/conversations/bulk/assign`,
        payload
      )
    ).data;

    return response.updated_conversations;
  }
}

export default InboxService;
