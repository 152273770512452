import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { ReactComponent as SettingsIcon } from "assets/icons/Settings-ds.svg";
import { canViewAgentsAnalytics, canViewPayments } from "util/permissions";
import { isFuzeyAndCoMerchant, isIOSMobileApp } from "util/methods";
import { FaBriefcase, FaChartArea, FaRegEnvelope } from "react-icons/fa";
import {
  CalendarLink,
  PaymentsLink,
  ReviewsLink,
} from "components/navigation-bar/MobileNavButton";
import { useAppSelector } from "redux/hooks";
import { ReactComponent as Bullhorn } from "assets/icons/bullhorn-regular.svg";
import { useLocation } from "react-router-dom";
import FooterNavLink from "./FooterNavLink";
import FooterIcon from "./FooterIcon";
import FooterNestedNavLinks from "./FooterNestedNavLinks";

const Footer = () => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { unreadCount } = useAppSelector((state) => state.conversations);
  const { pathname } = useLocation();
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isCustomerManagementActive, setIsCustomerManagementActive] =
    useState<boolean>(false);

  useEffect(() => {
    setIsCustomerManagementActive(
      pathname.includes("calendar") ||
        pathname.includes("payments") ||
        pathname.includes("reviews")
    );
  }, [pathname]);

  if (activeConversationId) {
    return null;
  }

  return (
    <Flex
      display="flex"
      justifyContent="space-between"
      alignItems="start"
      width="100%"
      position="sticky"
      bgColor={`${colorScheme}.900`}
      px={4}
      pt={5}
      pb={isIOSMobileApp() ? 12 : 5}
    >
      <FooterNavLink
        to={`/${merchant.id}/inbox`}
        hasNotifications={!!unreadCount.personal || !!unreadCount.shared}
      >
        <FooterIcon as={FaRegEnvelope} />
      </FooterNavLink>

      <FooterNavLink to={`/${merchant.id}/contacts`}>
        <FooterIcon
          src="/user.svg"
          w={7}
          h={7}
          width="1.75rem"
          height="1.75rem"
        />
      </FooterNavLink>

      <FooterNavLink to={`/${merchant.id}/campaigns`}>
        <FooterIcon
          as={Bullhorn}
          w={7}
          h={7}
          width="1.75rem"
          height="1.75rem"
        />
      </FooterNavLink>

      <FooterNestedNavLinks
        mainIcon={<FooterIcon as={FaBriefcase} />}
        isActive={isCustomerManagementActive}
        hasNotifications={false}
      >
        <CalendarLink />
        {!canViewPayments(merchant.id, currentAgent!) ? null : <PaymentsLink />}
        <ReviewsLink />
      </FooterNestedNavLinks>

      {canViewAgentsAnalytics(merchant.id, currentAgent!) ? (
        <FooterNavLink to={`/${merchant.id}/analytics`}>
          <FooterIcon
            as={FaChartArea}
            w={7}
            h={7}
            width="1.75rem"
            height="1.75rem"
          />
        </FooterNavLink>
      ) : null}

      <FooterNavLink to={`/${merchant.id}/settings`}>
        <FooterIcon as={SettingsIcon} />
      </FooterNavLink>
    </Flex>
  );
};

export default Footer;
