import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import ContactDomain from "entities/domain/customers/contact-domain";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import {
  contactListTransformFromDtoToDomain,
  contactTransformFromDtoToDomain,
} from "entities/transformers/contact-transformer";
import { GetContactsFilter, GetContactsSorting } from "util/ContactsFilter";
import { ContactDTO, ImportContactFromCsvDTO } from "entities/dto/ContactDTO";
import { numberOfContactsPerLoad } from "util/constants";
import axios, { AxiosError } from "axios";
import VehicleDomain from "entities/domain/vehicle";
import { vehicleTransformFromDtoToDomain } from "entities/transformers/vehicleTransformer";
import { Criteria, stringifyCriteria } from "entities/domain/criteria";
import { RequestType } from "./request-type";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  putRequest,
  request,
  requestBlob,
} from "../util/methods";

export interface ContactAddressPayload {
  first_line: string;
  second_line: string;
  city: string;
  state?: string;
  country: string;
  postcode: string;
}

export interface ContactChannelPayload {
  id?: string;
  type: string;
  handle: string;
  is_active: boolean;
}

export interface EditContactDetailsPayload {
  id: number;
  name?: string;
  surname?: string;
  tags: string[];
  tagIds: string[];
  address?: ContactAddressPayload;
  channels: ContactChannelPayload[];
  notes: string | null;
}

export interface MergeContactDetailsPayload {
  id: number;
  contactIds: number[];
}

export interface FetchContactsPayload {
  searchText?: string;
  sort?: string;
  filterText?: string;
}

export interface CreateContactPayload {
  name?: string;
  surname?: string;
  tags: string[];
  tagIds: string[];
  address?: ContactAddressPayload;
  channels: ContactChannelPayload[];
  notes: string | null;
}

class ContactsService {
  public static async deleteContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    contactId: number,
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest<void>(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/customers/${contactId}`
    );
  }

  public static async bulkAddTags(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    contactIds: number[],
    tags: string[],
    tagIds: string[]
  ): Promise<number[]> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await patchRequest<{ updated_customers: number[] }>(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchantId}/customers/bulk/tag`,
        {
          customer_ids: contactIds,
          tags,
          tag_ids: tagIds,
        }
      )
    ).data;

    return response.updated_customers;
  }

  public static async bulkRemoveTags(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    contactIds: number[],
    tags: string[],
    tagIds: string[]
  ): Promise<number[]> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await deleteRequest<{ updated_customers: number[] }>(
        RequestType.DELETE,
        accessToken,
        `/merchants/${merchantId}/customers/bulk/tag`,
        {
          customer_ids: contactIds,
          tags,
          tag_ids: tagIds,
        }
      )
    ).data;

    return response.updated_customers;
  }

  public static async updateContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditContactDetailsPayload,
    merchantId: number
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();

    const { id, name, surname, tags, tagIds, address, channels, notes } =
      payload;

    const contactResponse = await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/customers/${id}`,
      {
        name,
        surname,
        tags,
        tag_ids: tagIds,
        address,
        channels,
        notes,
      }
    );

    const contact: ContactDTO = contactResponse.data;

    return contactTransformFromDtoToDomain(contact);
  }

  public static async getAllContacts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    offset: number,
    merchantId: number,
    filter?: GetContactsFilter,
    sorting?: GetContactsSorting,
    criteria?: Criteria,
    customNumberOfContactsPerLoad: number = numberOfContactsPerLoad
  ): Promise<ContactListDomain> {
    const accessToken = await getAccessTokenSilently();

    const filterQueryParam = filter?.toQueryParam()
      ? `&${filter.toQueryParam()}`
      : "";
    const sortingQueryParam = sorting?.toQueryParam()
      ? `&${sorting.toQueryParam()}`
      : "";

    let criteriaQueryParam = "";

    if (criteria) {
      criteriaQueryParam = `&criteria=${encodeURI(
        stringifyCriteria(criteria)
      )}`;
    }

    const contactsResponse = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/customers?maxPageSize=${customNumberOfContactsPerLoad}&offset=${offset}${filterQueryParam}${sortingQueryParam}${criteriaQueryParam}`
    );

    const contacts = contactsResponse.data;
    const totalCount = contactsResponse.headers["total-count"];

    return contactListTransformFromDtoToDomain(contacts, totalCount);
  }

  public static async getContactsByIds(
    { getAccessTokenSilently }: Auth0ContextInterface,
    ids: number[],
    merchantId: number
  ): Promise<ContactDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const contactsResponse = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/customers?ids=${ids.join(",")}`
    );

    const contacts = contactsResponse.data;

    return contacts.map(contactTransformFromDtoToDomain);
  }

  public static async getVehicles(
    { getAccessTokenSilently }: Auth0ContextInterface,
    contactId: number,
    merchantId: number
  ): Promise<VehicleDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const vehiclesResponse = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/customers/${contactId}/vehicles`
    );

    const vehicles = vehiclesResponse.data;

    return vehicles.map(vehicleTransformFromDtoToDomain);
  }

  public static async getContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number,
    merchantId: number
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/customers/${id}`
    );

    const contact: ContactDTO = data.data;

    return contactTransformFromDtoToDomain(contact);
  }

  public static async getMeargeableContacts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number,
    merchantId: number,
    name?: string
  ): Promise<ContactDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const contacts = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/customers/${id}/merge?name=${name}`
    );
    return contacts.data.map(contactTransformFromDtoToDomain);
  }

  public static async mergeContacts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: MergeContactDetailsPayload,
    merchantId: number
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();

    const { contactIds, id } = payload;

    const contacts = await mutationRequest(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/customers/${id}/merge`,
      {
        customers: contactIds,
      }
    );

    const contact: ContactDTO = contacts.data;
    return contactTransformFromDtoToDomain(contact);
  }

  public static async createNewContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: CreateContactPayload,
    idempotencyKey: string,
    merchantId: number
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();

    const { name, surname, tags, tagIds, address, channels, notes } = payload;

    const contactResponse = await mutationRequest(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/customers`,
      {
        name,
        surname,
        tags,
        tag_ids: tagIds,
        address,
        channels,
        notes,
      },
      "application/json;charset=UTF-8",
      {
        "Idempotency-Key": idempotencyKey,
      }
    );

    const contact: ContactDTO = contactResponse.data;

    return contactTransformFromDtoToDomain(contact);
  }

  public static async importCustomers(
    { getAccessTokenSilently }: Auth0ContextInterface,
    file: FormData,
    merchantId: number
  ): Promise<ImportContactFromCsvDTO> {
    const accessToken = await getAccessTokenSilently();

    try {
      const contacts = await mutationRequest<ImportContactFromCsvDTO>(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/customers/import`,
        file,
        "multipart/form-data"
      );
      return contacts.data;
    } catch (e: any | AxiosError) {
      if (axios.isAxiosError(e) && e.response?.data) {
        return e.response.data as ImportContactFromCsvDTO;
      }
      throw e;
    }
  }

  public static async exportCustomersAsCsv(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    unsubOnly?: boolean,
    from?: Date,
    to?: Date
  ) {
    const accessToken = await getAccessTokenSilently();

    const queryParams = new URLSearchParams();

    if (unsubOnly) {
      queryParams.append("only_unsubscribed", "true");
    }

    if (from) {
      queryParams.append("from", Math.floor(from.getTime() / 1000).toString());
    }

    if (to) {
      queryParams.append("to", Math.floor(to.getTime() / 1000).toString());
    }

    const response = await requestBlob(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/customers/export?${queryParams.toString()}`
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contacts.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static async removeTagFromContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number,
    tag: string,
    tagId: string,
    merchantId: number
  ): Promise<string[]> {
    const accessToken = await getAccessTokenSilently();

    const tagResponse = await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/customers/${id}/tags`,
      { tag, tag_id: tagId }
    );

    return tagResponse.data;
  }

  public static async addTagToContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number,
    tags: string[],
    tagIds: string[],
    merchantId: number
  ): Promise<string[]> {
    const accessToken = await getAccessTokenSilently();

    const tagResponse = await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/customers/${id}/tags`,
      {
        tags,
        tag_ids: tagIds,
      }
    );

    return tagResponse.data;
  }
}

export default ContactsService;
