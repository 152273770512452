import { OptionTypes } from "components/shared/filter";
import DropdownOptionLabel from "components/shared/filter/OptionLabel";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useCalendarStore from "hooks/use-calendar-store";
import React, { ChangeEvent, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { MultiValue, SingleValue } from "react-select";

import { transformFromAgentDomainToOptionType } from "entities/transformers/agent-transformer";
import { updateFormatAccordingToCountry } from "util/methods";
import AgentDomain from "entities/domain/agents/agent-domain";
import { differenceInMilliseconds, format } from "date-fns";
import ContactsService from "services/contacts";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import EventForm from "./EventForm";

interface AgentOptionTypes {
  value: number;
}
interface CustomerOptionTypes {
  value: number;
  label?: string;
}
interface ChannelOptionTypes {
  value: string;
  label?: string;
}

interface NewEventFormProps {
  closeNewEventForm: () => void;
  currentEvent: CalendarBookingDomain;
}

const EditEventModal = ({
  closeNewEventForm,
  currentEvent,
}: NewEventFormProps) => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { agents } = useAppSelector((state) => state.agents);
  const toast = useToast();
  const { updateEvent } = useCalendarStore();
  const { errors } = useAppSelector((state) => state.calendar);

  const [title, setTitle] = useState<string>(currentEvent.title);
  const [description, setDescription] = useState<string>(
    currentEvent.description
  );
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    new Date(Date.parse(currentEvent.startAt))
  );
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    new Date(Date.parse(currentEvent.endAt))
  );
  const currentEventTimeDuration = differenceInMilliseconds(
    new Date(currentEvent.endAt),
    new Date(currentEvent.startAt)
  );
  const [selectedAgents, setSelectedAgents] = useState<
    MultiValue<AgentOptionTypes>
  >([]);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ContactDomain | null>(null);
  const [selectedChannel, setSelectedChannel] =
    useState<SingleValue<ChannelOptionTypes>>(null);
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(false);

  const resetValues = () => {
    setTitle("");
    setDescription("");
    setSelectedAgents([]);
    setSelectedCustomer(null);
  };

  useEffect(() => {
    resetValues();
  }, []);

  const setEditedCustomerAsSelected = (newCustomers: ContactDomain) => {
    setSelectedCustomer(newCustomers);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value);

  const handleDescChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleSelectedAgents = (e: MultiValue<AgentOptionTypes>) => {
    setSelectedAgents(e);
  };

  const currentlySelectedAgents: AgentOptionTypes[] | null =
    currentEvent.agentIds.flatMap((agentId) =>
      agents
        .filter((a: AgentDomain) => a.id === agentId)
        .map(transformFromAgentDomainToOptionType)
    );

  const handleSelectedChannel = (e: SingleValue<ChannelOptionTypes>) => {
    setSelectedChannel(e);
    return e?.value
      ? setNotifyCustomer(true)
      : setNotifyCustomer(notifyCustomer);
  };

  const updateBooking = () => {
    updateEvent({
      bookingId: currentEvent.id,
      startAt: format(
        startDateTime!,
        updateFormatAccordingToCountry("yyyy-MM-dd-HH:mm:ss")
      ),
      endAt: format(
        endDateTime!,
        updateFormatAccordingToCountry("yyyy-MM-dd-HH:mm:ss")
      ),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      title,
      description,
      agentIds:
        selectedAgents.map((val) => Number(val.value)) || currentEvent.agentIds,
      customerId: selectedCustomer?.id || currentEvent.customerId,
      notifyCustomer,
      notificationCustomerChannelId: notifyCustomer
        ? selectedChannel?.value
        : undefined,
    });
  };

  const createCalendarEvent = () => {
    updateBooking();
    resetValues();
    closeNewEventForm();
    if (errors.length) {
      toast({ status: "error", title: errors[0] });
    } else {
      toast({ status: "success", title: "Event updated successfully!" });
    }
  };

  useEffect(() => {
    setTitle(currentEvent.title);
    setDescription(currentEvent.description);
    setSelectedAgents(currentlySelectedAgents);
    if (currentEvent.customerId) {
      ContactsService.getContact(
        auth0Context,
        currentEvent.customerId,
        merchant.id
      ).then((fetchedContact) => {
        if (!fetchedContact) {
          setSelectedCustomer(null);

          return;
        }

        setSelectedCustomer(fetchedContact);
      });
    }
  }, []);

  const isSameCustomer: boolean =
    currentEvent.customerId === selectedCustomer?.id;

  return (
    <>
      <EventForm
        isEdit={true}
        customerEdited={!isSameCustomer}
        openEventForm={!!currentEvent}
        closeNewEventForm={closeNewEventForm}
        titleValue={title}
        handleTitleChange={(e) => handleTitleChange(e)}
        selectedCustomer={selectedCustomer}
        handleSelectedCustomer={setSelectedCustomer}
        selectedChannel={selectedChannel}
        handleSelectedChannel={(e) => handleSelectedChannel(e)}
        agentValue={selectedAgents}
        handleSelectedAgents={(e) => handleSelectedAgents(e)}
        handleDescChange={(e) => handleDescChange(e)}
        descValue={description}
        onFormSubmit={() => createCalendarEvent()}
        SubmitButtonText="Save Changes"
        startDateTime={startDateTime}
        onStartDateChange={(date) => {
          setStartDateTime(date);
          setEndDateTime(
            date ? new Date(date.getTime() + currentEventTimeDuration) : null
          );
        }}
        endDateTime={endDateTime}
        onEndDateChange={(date) => setEndDateTime(date)}
        notifyCustomer={notifyCustomer}
        handleNotifyCustomer={(e) => {
          return e.target.checked
            ? setNotifyCustomer(true)
            : setNotifyCustomer(false);
        }}
        setNewCustomer={setEditedCustomerAsSelected}
        getOptionLabels={(e: OptionTypes) => <DropdownOptionLabel option={e} />}
        notificationChannelSelected={currentEvent.notifyChannelSelected}
        isDisabled={!title}
      />
    </>
  );
};

export default EditEventModal;
