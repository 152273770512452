import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import NavigationBar from "components/navigation-bar";
import React, { useEffect, useState } from "react";
import InboxService from "services/inbox";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setUnreadCount } from "redux/features/conversations";
import { useWebSocket } from "hooks/use-socket";
import { SocketConversationUnreadCountChanged } from "entities/ISocketArgs";
import PageBanner from "./PageBanner";

interface PrivatePageWrapperProps {
  children: React.ReactNode;
}

const PrivatePageWrapper = ({ children }: PrivatePageWrapperProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { unreadCount } = useAppSelector((state) => state.conversations);
  const [conversationUnreadCountChanged, setConversationUnreadCountChanged] =
    useState({} as SocketConversationUnreadCountChanged);

  useEffect(() => {
    InboxService.getUnreadCount(auth0Context, merchant.id).then(
      (newUnreadCount) => {
        dispatch(setUnreadCount(newUnreadCount));
      }
    );
  }, []);

  const handleConversationUnreadCountChanged = (
    args: SocketConversationUnreadCountChanged
  ) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setConversationUnreadCountChanged(args);
  };

  useEffect(() => {
    addEventHandler(
      "conversation_unread_count_changed",
      handleConversationUnreadCountChanged
    );

    return () => {
      removeEventHandler(
        "conversation_unread_count_changed",
        handleConversationUnreadCountChanged
      );
    };
  }, [addEventHandler, removeEventHandler]);

  useEffect(() => {
    if (Object.keys(conversationUnreadCountChanged).length === 0) {
      return;
    }

    const unreadCountChanges = conversationUnreadCountChanged.unread_count;

    dispatch(
      setUnreadCount({
        shared: unreadCountChanges.shared,
        personal: unreadCountChanges.personal[currentAgent!.id.toString()] || 0,
        unassigned: unreadCountChanges.unassigned,
        teams:
          unreadCountChanges.teams &&
          Object.keys(unreadCountChanges.teams).reduce(
            (changedTeams, currentTeamId) => {
              const teamUnreadCount = unreadCountChanges.teams![currentTeamId];

              changedTeams[currentTeamId] = {
                shared: teamUnreadCount.shared,
                personal:
                  teamUnreadCount.personal[currentAgent!.id.toString()] || 0,
                unassigned: teamUnreadCount.unassigned,
              };

              return changedTeams;
            },
            {} as {
              [key: string]: {
                personal: number;
                shared: number;
                unassigned: number;
              };
            }
          ),
      })
    );
  }, [conversationUnreadCountChanged]);

  return (
    <>
      <PageBanner />
      {!isBaseSize ? (
        <Flex h="100%" maxHeight="100%">
          <Box
            flexGrow={0}
            flexBasis="1px"
            flexShrink={0}
            h="100%"
            minHeight="100%"
          >
            <NavigationBar />
          </Box>
          <Flex
            flexGrow={1}
            flexBasis="1px"
            flexShrink={0}
            h="100%"
            minWidth={0}
          >
            {children}
          </Flex>
        </Flex>
      ) : (
        <Flex h="100%" w="100%" maxHeight="100%" direction="column">
          <Flex
            w="100%"
            flexDirection="column"
            flexGrow={1}
            flexBasis="1px"
            flexShrink={0}
            overflow="hidden"
          >
            {children}
          </Flex>
          <Box w="100%" flexGrow={0} flexBasis="1px" flexShrink={0}>
            <NavigationBar />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default PrivatePageWrapper;
