import ChannelDomain from "entities/domain/contacts/contact-domain";
import MerchantUserGuidesDomain from "entities/domain/admin/merchants/merchant-user-guides";
import BankAccountDomain from "./bank-account-domain";
import ExternalMetadataDomain from "./external-metadata-domain";
import MerchantDomainBase, {
  StrictnessType,
  TierType,
} from "./merchant-domain";
import MerchantTierLimitsDomain from "./merchant-tier-limits-domain";
import { MerchantIntegrations } from "./merchant-integrations";

class AdminMerchantDomain extends MerchantDomainBase {
  bankAccount?: BankAccountDomain;
  logo?: string | null;

  constructor(
    id: number,
    public_id: string,
    name: string,
    registeredCompanyId: string,
    subaccountSid: string,
    externalMetadata: ExternalMetadataDomain,
    channels: ChannelDomain[],
    hasStripeAccount: boolean,
    hasInvoiceAccount: boolean,
    hasOpenBanking: boolean,
    hasReviewsEnabled: boolean,
    country: string,
    userGuides: MerchantUserGuidesDomain,
    businessDescription: string | null,
    tier: TierType | null,
    strictness: StrictnessType | null,
    limits: MerchantTierLimitsDomain | null,
    bankAccount?: BankAccountDomain,
    logo?: string | null
  ) {
    super(
      id,
      public_id,
      name,
      registeredCompanyId,
      subaccountSid,
      externalMetadata,
      channels,
      hasStripeAccount,
      hasInvoiceAccount,
      hasOpenBanking,
      hasReviewsEnabled,
      {} as MerchantIntegrations,
      country,
      userGuides,
      businessDescription,
      tier,
      strictness,
      limits
    );

    this.id = id;
    this.public_id = public_id;
    this.name = name;
    this.registeredCompanyId = registeredCompanyId;
    this.subaccountSid = subaccountSid;
    this.externalMetadata = externalMetadata;
    this.channels = channels;
    this.hasStripeAccount = hasStripeAccount;
    this.hasInvoiceAccount = hasInvoiceAccount;
    this.bankAccount = bankAccount;
    this.hasOpenBanking = hasOpenBanking;
    this.hasReviewsEnabled = hasReviewsEnabled;
    this.userGuides = userGuides;
    this.logo = logo;
  }
}

export default AdminMerchantDomain;
