import { Box } from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import AdminGrid from "./AdminGrid";
import TopbarMenuBtn from "./TopbarMenuBtn";

const Admin = () => {
  return (
    <Box
      width="100%"
      maxHeight="100%"
      overflowY="auto"
      boxShadow="0 2px 11px 0 rgba(5, 59, 113, 0.5)"
      pb="20px"
    >
      <Topbar
        rightChildrenMobile={<TopbarMenuBtn />}
        leftChildren={<TopbarMenuBtn />}
      />
      <AdminGrid>
        <Outlet />
      </AdminGrid>
    </Box>
  );
};

export default Admin;
