import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Box,
  FormControl,
  FormLabel,
  Stack,
  useColorMode,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import { components, GroupBase, OptionProps } from "react-select";
import { getReactSelectStyles } from "util/methods";
import AdminService from "services/admin";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import ProfileAvatar from "components/profile/profile-avatar";
import { numberOfAllAgentsPerLoad } from "util/constants";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import SelectMerchant from "./select-merchant";

interface AgentOptionTypes {
  value: number;
  label: string;
  avatar: string;
  fullName: string;
  merchants: AgentMerchantDomain[]; // This is just convenient
}

const AgentColumn = ({ item }: { item: AgentOptionTypes }) => {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      gridGap={4}
      px={8}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <ProfileAvatar profilePicture={item.avatar} name={item.fullName} />
      <Text>{item.label}</Text>
    </Flex>
  );
};

const AgentOption = function (props: OptionProps<AgentOptionTypes>) {
  return (
    <components.Option {...props}>
      <AgentColumn item={props.data} />
    </components.Option>
  );
};

const DisconnectFromMerchantForm = ({
  cache,
  clearCache,
}: {
  cache: object;
  clearCache: () => void;
}) => {
  const auth0 = useAuth0();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [selectedAgentIdValue, setSelectedAgentIdValue] = useState<
    number | null
  >();
  const [selectedMerchants, setSelectedMerchants] = useState<
    AdminMerchantDomain[]
  >([]);

  const [agentOptions, setAgentOptions] = useState<AgentOptionTypes[]>([]);

  const hasMoreAgents = (
    agentsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (agentsAmount < numberOfAllAgentsPerLoad) {
      return false;
    }

    if (!currentPageAmount || currentPageAmount < numberOfAllAgentsPerLoad) {
      return false;
    }

    return true;
  };

  const fetchMoreAgents: LoadOptions<
    AgentOptionTypes,
    GroupBase<AgentOptionTypes>,
    unknown
  > = async (searchText = "", loadedOptions) => {
    const nextPage = (
      await AdminService.getAllAgents(
        auth0,
        searchText,
        loadedOptions?.length || 0
      )
    ).map<AgentOptionTypes>((agent) => ({
      value: agent.id,
      label: `${agent.getFullName()} (${agent.email})`,
      avatar: agent.getPicture(),
      fullName: agent.getFullName(),
      merchants: agent.merchants,
    }));

    const newAgents: AgentOptionTypes[] = agentOptions?.length
      ? agentOptions.concat(nextPage)
      : nextPage;

    setAgentOptions(newAgents);

    return {
      options: nextPage,
      hasMore: hasMoreAgents(newAgents.length, nextPage?.length || 0),
    };
  };

  useEffect(() => {
    setSelectedMerchants([]);
  }, [selectedAgentIdValue]);

  useEffect(() => {
    setAgentOptions([]);
  }, [cache]);

  const resetValues = () => {
    setSelectedAgentIdValue(null);
    setSelectedMerchants([]);
    clearCache();
  };

  const disconnectAgent = async () => {
    try {
      await AdminService.disconnectAgentFromMerchants(auth0, {
        agentId: selectedAgentIdValue!,
        merchantIds: selectedMerchants!.map((m) => m.id),
      });
      toast({
        status: "success",
        title: "Agent was successfully disconnected from selected merchants",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't disconnect agent from selected merchants", e);
      toast({
        status: "error",
        title: "Couldn't disconnect agent from selected merchants",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <Box>
      <Stack spacing={3}>
        <FormControl isRequired>
          <FormLabel>Select Agent</FormLabel>
          <AsyncPaginate
            cacheUniqs={[cache]}
            loadOptions={fetchMoreAgents}
            placeholder="Select Agent"
            isMulti={false}
            onChange={(a) => setSelectedAgentIdValue(a?.value || null)}
            value={
              agentOptions?.find((a) => a.value === selectedAgentIdValue) ||
              null
            }
            components={{
              Option: AgentOption,
            }}
            styles={getReactSelectStyles(colorMode, colorScheme)}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Choose Merchants</FormLabel>
          <SelectMerchant
            isMulti={true}
            selectedMerchants={selectedMerchants}
            setSelectedMerchants={setSelectedMerchants}
            isDisabled={!selectedAgentIdValue}
            filterCallback={(merchantId) => {
              const selectedAgentOption = agentOptions?.find(
                (a) => a.value === selectedAgentIdValue
              );

              if (!selectedAgentOption) {
                return true;
              }

              return selectedAgentOption.merchants
                .map((m) => m.id)
                .includes(merchantId);
            }}
          />
        </FormControl>
      </Stack>
      <Button
        colorScheme={colorScheme}
        onClick={disconnectAgent}
        isDisabled={!selectedMerchants.length || !selectedAgentIdValue}
        mt={8}
        mb={8}
        size="lg"
      >
        Disconnect Agent
      </Button>
    </Box>
  );
};

export default DisconnectFromMerchantForm;
