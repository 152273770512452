import { useAuth0 } from "@auth0/auth0-react";
import {
  Flex,
  Icon,
  ModalFooter,
  Button,
  useColorMode,
  Text,
  VStack,
} from "@chakra-ui/react";
import ContactDomain from "entities/domain/customers/contact-domain";
import useAnalytics from "hooks/use-analytics";
import React from "react";
import { ReactSVG } from "react-svg";
import { getChannelIcon } from "util/constants";
import { useAppSelector } from "redux/hooks";

interface selectedContactChannelsProps {
  selectedContact: ContactDomain;
  setChannelId: (id: string) => void;
  channelId: string | undefined;
}

const SelectedContactChannels = ({
  selectedContact,
  setChannelId,
  channelId,
}: selectedContactChannelsProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  return (
    <VStack spacing={2} w="100%">
      {selectedContact.channels
        .filter((chan) => merchant.isMerchantChannelEnabled(chan.type))
        .map((chan) => {
          const hasMultipleChannelOfSameType =
            selectedContact.channels.filter((c) => c.type === chan.type)
              .length > 1;

          return (
            <Flex
              key={chan.id}
              id={chan.id}
              width="100%"
              py={4}
              px={8}
              gridGap={4}
              bgColor={
                channelId === chan.id
                  ? colorMode === "dark"
                    ? `${colorScheme}.400`
                    : `${colorScheme}.500`
                  : "inherit"
              }
              _hover={{
                bgColor:
                  channelId === chan.id
                    ? colorMode === "dark"
                      ? `${colorScheme}.500`
                      : `${colorScheme}.400`
                    : colorMode === "dark"
                    ? "gray.600"
                    : "gray.50",
              }}
              mx={4}
              alignItems="center"
              cursor="pointer"
              onClick={() => setChannelId(chan.id!)}
              data-testid="select-contact-channel"
            >
              <Icon
                as={ReactSVG}
                src={getChannelIcon(chan.type)}
                width="1rem"
                height="1rem"
              />
              <Text color={channelId === chan.id ? "white" : "inherit"}>
                {chan.getDisplayChannelName()}
              </Text>
              {hasMultipleChannelOfSameType && (
                <Text color={channelId === chan.id ? "white" : "inherit"}>
                  {chan.handle}
                </Text>
              )}
            </Flex>
          );
        })}
    </VStack>
  );
};

export default SelectedContactChannels;
