/* eslint-disable import/prefer-default-export */
import KeyloopPrivacyStatement from "entities/domain/keyloop-privacy-statement";
import { KeyloopPrivacyStatementDTO } from "entities/dto/KeyloopPrivacyStatementDTO";

export const keyloopPrivacyStatementTransformFromDtoToDomain = (
  ps: KeyloopPrivacyStatementDTO
): KeyloopPrivacyStatement =>
  new KeyloopPrivacyStatement(
    ps.id,
    ps.description,
    ps.channels,
    ps.data_channels,
    ps.level,
    ps.provider_name
  );
