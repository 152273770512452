import { Box, Button, ButtonGroup, HStack, Textarea } from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from "lexical";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import TextLimits from "./TextLimits";
import Toolbar, { customFieldsDefinition } from "./Toolbar";
import BuildingBlockWrapper from "./BuildingBlockWrapper";
import BuildingBlockHeaderWrapper from "./BuildingBlockHeaderWrapper";

interface BodyBuildingBlockProps {
  text?: string;
  isDisabled: boolean;
  onTextChange: (text: string) => void;
}

const MAX_BODY_LENGTH = 1024;

const BodyBuildingBlock = ({
  text = "",
  isDisabled,
  onTextChange,
}: BodyBuildingBlockProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [localText, setLocalText] = useState<string>(text);

  const insertEmojiIntoText = useCallback(
    ({ native }: { native: string }) => {
      insertIntoText(native);
    },
    [textAreaRef]
  );

  const wrapTextInBold = useCallback(() => {
    wrapText("*", "*");
  }, [textAreaRef]);

  const wrapTextInItalic = useCallback(() => {
    wrapText("_", "_");
  }, [textAreaRef]);

  const wrapTextInCode = useCallback(() => {
    wrapText("```", "```");
  }, [textAreaRef]);

  const wrapTextInStrikethrough = useCallback(() => {
    wrapText("~", "~");
  }, [textAreaRef]);

  const wrapText = useCallback(
    (leftText: string, rightText: string) => {
      if (!textAreaRef || !textAreaRef.current) {
        return;
      }

      const cursorPosition = textAreaRef.current.selectionStart;
      const selectionEnd = textAreaRef.current.selectionEnd;

      setLocalText((oldText) => {
        const newText =
          oldText.substring(0, cursorPosition) +
          leftText +
          oldText.substring(cursorPosition, selectionEnd) +
          rightText +
          oldText.substring(selectionEnd);

        onTextChange(newText);

        return newText;
      });

      textAreaRef.current.focus();
    },
    [textAreaRef]
  );

  const insertIntoText = useCallback(
    (someText: string) => {
      if (!textAreaRef || !textAreaRef.current) {
        return;
      }

      const cursorPosition = textAreaRef.current.selectionStart;

      setLocalText((oldText) => {
        const newText =
          oldText.substring(0, cursorPosition) +
          someText +
          oldText.substring(cursorPosition);

        onTextChange(newText);

        return newText;
      });

      textAreaRef.current.focus();
    },
    [textAreaRef]
  );

  return (
    <BuildingBlockWrapper>
      <BuildingBlockHeaderWrapper name="Body" isOpen={true}>
        <Toolbar
          isDisabled={isDisabled}
          onEmojiSelect={insertEmojiIntoText}
          onCustomFieldSelect={insertIntoText}
        />
        <HStack>
          <ButtonGroup variant="outline" spacing={2}>
            <Button
              id="bold-button"
              borderRadius="full"
              height="1rem"
              w="1rem"
              p={4}
              colorScheme="gray"
              onClick={wrapTextInBold}
            >
              B
            </Button>
            <Button
              id="italic-button"
              borderRadius="full"
              height="1rem"
              w="1rem"
              p={4}
              colorScheme="gray"
              fontStyle="italic"
              onClick={wrapTextInItalic}
            >
              I
            </Button>
            <Button
              id="strikethrough-button"
              borderRadius="full"
              height="1rem"
              w="1rem"
              p={4}
              colorScheme="gray"
              onClick={wrapTextInStrikethrough}
            >
              <s>S</s>
            </Button>
            <Button
              id="code-button"
              borderRadius="full"
              height="1rem"
              w="1rem"
              p={4}
              colorScheme="gray"
              onClick={wrapTextInCode}
            >
              {"{ }"}
            </Button>
          </ButtonGroup>
        </HStack>
      </BuildingBlockHeaderWrapper>
      <Box width="100%" pt="1rem" pb="2rem" px="1.5rem" overflowY="auto">
        <Textarea
          ref={textAreaRef}
          border="none"
          p={0}
          id="whatsapp-template-body-textarea"
          isDisabled={isDisabled}
          placeholder="e.g. We have discounts on some of our products!"
          value={localText}
          onChange={(e) => {
            setLocalText(e.target.value);
            onTextChange(e.target.value);
          }}
          maxLength={MAX_BODY_LENGTH}
        />
      </Box>
      <TextLimits maxCharacters={MAX_BODY_LENGTH} text={localText} />
    </BuildingBlockWrapper>
  );
};

export default BodyBuildingBlock;
