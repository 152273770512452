import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TagsDomain from "entities/domain/tags/tags-domain";
import { colorList } from "util/constants";

interface TagsState {
  tags: TagsDomain[];
}

const initialState: TagsState = {
  tags: [],
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTags(state, action: PayloadAction<TagsDomain[]>) {
      state.tags = action.payload;
    },
    addTag(state, action: PayloadAction<{ id: string; name: string }>) {
      if (state.tags.find((tag) => tag.id === action.payload.id)) {
        return;
      }

      const color = colorList[state.tags.length % colorList.length].value;

      state.tags.push(
        new TagsDomain(action.payload.name, color, action.payload.id)
      );
    },
    removeTag(state, action: PayloadAction<string>) {
      state.tags = state.tags.filter((tag) => tag.id !== action.payload);
    },
  },
});

export const { setTags, addTag, removeTag } = tagsSlice.actions;

export default tagsSlice.reducer;
