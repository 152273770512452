import {
  Box,
  Button,
  Flex,
  VStack,
  useBreakpointValue,
  useColorMode,
  Heading,
  useToast,
} from "@chakra-ui/react";
import * as linkify from "linkifyjs";
import FullTemplateDomain, {
  TemplateCategory,
} from "entities/domain/templates/full_template";
import { useAppSelector } from "redux/hooks";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  containsSuffix,
  removePrefixAndSuffix,
  containsPrefix,
} from "util/methods";
import { useNavigate } from "react-router-dom";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";

import TemplateSettings from "./TemplateSettings";
import TemplateText from "./TemplateText";

const UNSUBSCRIBE_SUFFIX = "Reply UNSUB to unsubscribe";
const MAX_AMOUNT_OF_LINKS_PER_WHATSAPP_TEMPALTE = 1;

function areStringArraysEqual(array1: string[], array2: string[]): boolean {
  const sortedArray1 = [...array1].sort();
  const sortedArray2 = [...array2].sort();

  if (sortedArray1.length !== sortedArray2.length) {
    return false;
  }

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
}

interface TemplateFormProps {
  templateToEdit: FullTemplateDomain | null;
  setDisplayBackButton: (val: boolean) => void;
}

const draftTemplate = {
  customFields: {},
  text: "",
  id: "",
  title: "",
  shortcut: null,
  subject: null,
  channels: ["sms"],
  favourite: false,
  category: TemplateCategory.UTILITY,
  mediaUrl: null,
  mediaType: null,
};

const removeExtraSpacesFromText = (text: string) => {
  return text.trim().replace(/ {2,}/g, " ");
};

const TemplateForm = ({
  templateToEdit,
  setDisplayBackButton,
}: TemplateFormProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const navigate = useNavigate();
  const { createTemplate, editTemplate } = useTemplatesStore();
  const { modalLoading } = useAppSelector((state) => state.templates);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const toast = useToast();

  const mandatoryPrefixDefault = `${merchant.name}:`;

  const [mandatoryPrefix, setMandatoryPrefix] = useState<string>("");
  const optionalSuffix = `\n${UNSUBSCRIBE_SUFFIX}`;

  const [templateText, setTemplateText] = useState<string>(
    templateToEdit
      ? removePrefixAndSuffix(
          templateToEdit.text,
          mandatoryPrefix,
          optionalSuffix
        )
      : draftTemplate.text
  );
  const [templateTitle, setTemplateTitle] = useState<string>(
    templateToEdit?.title || draftTemplate.title
  );
  const [templateShortcut, setTemplateShortcut] = useState<string | null>(
    templateToEdit?.shortcut || draftTemplate.shortcut
  );
  const [templateChannels, setTemplateChannels] = useState<string[]>(
    templateToEdit?.channels || draftTemplate.channels
  );
  const [templateCategory, setTemplateCategory] =
    useState<TemplateCategory | null>(
      templateToEdit?.category || draftTemplate.category
    );
  const [templateSubject, setTemplateSubject] = useState<string | null>(
    templateToEdit?.subject || draftTemplate.subject
  );
  const [templateMediaUrl, setTemplateMediaUrl] = useState<string | null>(
    templateToEdit?.mediaUrl || draftTemplate.mediaUrl
  );
  const [templateMediaType, setTemplateMediaType] = useState<string | null>(
    templateToEdit?.mediaType || draftTemplate.mediaType
  );
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const wereChannelsAltered = useRef<boolean>(false);
  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: templateToEdit
      ? removePrefixAndSuffix(
          templateToEdit.text,
          mandatoryPrefix,
          optionalSuffix
        )
      : removePrefixAndSuffix(templateText, mandatoryPrefix, optionalSuffix),
  });
  const [isSuffixIncluded, setIsSuffixIncluded] = useState<boolean>(
    templateToEdit
      ? containsSuffix(templateToEdit.text, optionalSuffix)
      : templateChannels.includes(ConversationChannel.WHATSAPP)
  );

  useEffect(() => {
    setDisplayBackButton(true);

    return () => {
      setDisplayBackButton(false);
    };
  }, []);

  const memoizedSetTemplateText = useCallback((newText: string) => {
    setTemplateText(newText);
  }, []);
  const memoizedSetTemplateTitle = useCallback((newTitle: string) => {
    setTemplateTitle(newTitle);
  }, []);
  const memoizedSetTemplateShortcut = useCallback(
    (newShortcut: string | null) => {
      setTemplateShortcut(newShortcut);
    },
    []
  );
  const memoizedSetTemplateSubject = useCallback(
    (newSubject: string | null) => {
      setTemplateSubject(newSubject);
    },
    []
  );
  const memoizedSetTemplateChannels = useCallback(
    (newChannels: string[]) => {
      setTemplateChannels((oldChannels: string[]) => {
        const wasOnlyWhatsapp =
          oldChannels.length === 1 &&
          oldChannels.includes(ConversationChannel.WHATSAPP);
        const isNotOnlyWhstapp =
          newChannels.length !== 1 ||
          !newChannels.includes(ConversationChannel.WHATSAPP);

        if (wasOnlyWhatsapp && isNotOnlyWhstapp) {
          const newText = removePrefixAndSuffix(
            templateText,
            mandatoryPrefix,
            optionalSuffix
          );
          setEditorText({
            value: newText,
          });
        }
        return newChannels;
      });
    },
    [templateText, mandatoryPrefix]
  );
  const memoizedSetTemplateCategory = useCallback(
    (newCategory: TemplateCategory | null) => {
      setTemplateCategory(newCategory);
    },
    []
  );
  const memoizedSetMandatoryPrefix = useCallback((newPrefix: string) => {
    setMandatoryPrefix(newPrefix);
  }, []);
  const memoizedSetAttachedFile = useCallback((newFile: File | null) => {
    setAttachedFile(newFile);
  }, []);
  const memoizedSetEditorText = useCallback(
    (newEditorText: { value: string }) => {
      setEditorText(newEditorText);
    },
    []
  );
  const memoizedSetIsSuffixIncluded = useCallback(
    (newIsSuffixIncluded: boolean) => {
      setIsSuffixIncluded(newIsSuffixIncluded);
    },
    []
  );
  const memoizedSetTemplateMediaUrl = useCallback(
    (newMediaUrl: string | null) => {
      setTemplateMediaUrl(newMediaUrl);
    },
    []
  );
  const memoizedSetTemplateMediaType = useCallback(
    (newMediaType: string | null) => {
      setTemplateMediaType(newMediaType);
    },
    []
  );

  useEffect(() => {
    const hasPrefixAlready = (templateToEdit?.text.split(":").length || 0) > 1;
    const shouldNotHavePrefix =
      templateToEdit && templateToEdit.id && !hasPrefixAlready;

    if (shouldNotHavePrefix) {
      setMandatoryPrefix("");
      return;
    }

    setMandatoryPrefix(
      hasPrefixAlready
        ? `${templateToEdit!.text.split(":")[0]}:`
        : mandatoryPrefixDefault
    );
  }, [templateToEdit]);

  useEffect(() => {
    setIsSuffixIncluded(
      templateToEdit
        ? containsSuffix(templateToEdit.text, optionalSuffix)
        : false
    );
  }, [templateToEdit]);

  useEffect(() => {
    const shouldCheckForSuffix =
      wereChannelsAltered.current ||
      !templateToEdit ||
      !areStringArraysEqual(templateChannels, templateToEdit.channels);

    if (templateToEdit && !wereChannelsAltered.current) {
      setIsSuffixIncluded(containsSuffix(templateToEdit.text, optionalSuffix));
    } else if (shouldCheckForSuffix) {
      const isWhatsappIncluded = templateChannels.includes(
        ConversationChannel.WHATSAPP
      );
      setIsSuffixIncluded(
        isWhatsappIncluded || containsSuffix(templateText, optionalSuffix)
      );
    } else {
      setIsSuffixIncluded(false);
    }

    if (shouldCheckForSuffix && templateToEdit) {
      wereChannelsAltered.current = true;
    }
  }, [templateChannels]);

  useEffect(() => {
    if (templateCategory !== TemplateCategory.MARKETING) {
      setIsSuffixIncluded(false);

      return;
    }

    setIsSuffixIncluded(
      templateChannels.includes(ConversationChannel.WHATSAPP) ||
        containsSuffix(templateText, optionalSuffix)
    );
  }, [templateCategory]);

  const addPrefixAndSuffixIfNeeded = (text: string) => {
    if (templateCategory !== TemplateCategory.MARKETING) {
      return text;
    }

    if (!containsPrefix(text, mandatoryPrefix)) {
      text = `${mandatoryPrefix} ${text}`;
    }

    if (isSuffixIncluded && !containsSuffix(text, optionalSuffix)) {
      text = `${text}\n${UNSUBSCRIBE_SUFFIX}`;
    }

    return text;
  };

  useEffect(() => {
    const updatedValues = templateToEdit || draftTemplate;

    const shouldRemovePrefixAndSuffix =
      updatedValues.category === TemplateCategory.MARKETING;

    let newEditorText = updatedValues.text;

    if (shouldRemovePrefixAndSuffix) {
      newEditorText = removePrefixAndSuffix(
        newEditorText,
        mandatoryPrefix,
        optionalSuffix
      );
    }

    setTemplateCategory(updatedValues.category);
    setTemplateTitle(updatedValues.title);
    setTemplateShortcut(updatedValues.shortcut);
    setTemplateChannels(updatedValues.channels);
    setTemplateSubject(updatedValues.subject);
    setTemplateMediaUrl(updatedValues.mediaUrl);
    setTemplateMediaType(updatedValues.mediaType);
    setEditorText({
      value: newEditorText,
    });
    setTemplateText(newEditorText);
  }, [templateToEdit]);

  const prepareTextBeforeSending = (text: string) => {
    let finalizedText = addPrefixAndSuffixIfNeeded(text);
    finalizedText = removeExtraSpacesFromText(finalizedText);

    return finalizedText;
  };

  const addTemplate = async () => {
    try {
      await createTemplate({
        title: templateTitle.trim(),
        text: prepareTextBeforeSending(templateText),
        channels: templateChannels || [],
        favourite: false,
        shortcut: templateShortcut?.trim() || null,
        subject: templateSubject?.trim() || null,
        category: templateCategory || null,
        file: attachedFile || undefined,
      });

      navigate(`/${merchant.id}/settings/templates`);
      toast({ status: "success", title: "Template created successfully" });
    } catch (_error: unknown) {
      toast({ status: "error", title: "Could not create template" });
    }
  };

  const isTemplateValid = () => {
    if (templateChannels.includes(ConversationChannel.WHATSAPP)) {
      const amountOfLinksInMessage =
        linkify.find(templateText).filter((l) => l.type === "url").length || 0;

      if (amountOfLinksInMessage > MAX_AMOUNT_OF_LINKS_PER_WHATSAPP_TEMPALTE) {
        toast({
          status: "error",
          title: "WhatsApp template can only contain one link",
        });
        return false;
      }
    }

    return true;
  };

  const editSelectedTemplate = async () => {
    if (!templateToEdit) {
      return;
    }

    try {
      await editTemplate({
        id: templateToEdit.id,
        title: templateTitle.trim(),
        text: prepareTextBeforeSending(templateText),
        channels: templateChannels || [],
        favourite: templateToEdit.favourite,
        shortcut: templateShortcut?.trim() || null,
        subject: templateSubject?.trim() || null,
        category: templateCategory || null,
        file: attachedFile || undefined,
        mediaType: attachedFile ? null : templateMediaType,
        mediaUrl: attachedFile ? null : templateMediaUrl,
      });

      navigate(`/${merchant.id}/settings/templates`);
    } catch (_error: unknown) {
      toast({ status: "error", title: "Could not edit template" });
    }
  };

  return (
    <Box w="100%" h="100%">
      <Flex
        w="100%"
        mx="auto"
        h="100%"
        my={isBaseSize ? 4 : 12}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
      >
        <VStack
          width="100%"
          height="100%"
          px={isBaseSize ? 4 : 36}
          overflowY="auto"
          id="template-form"
          spacing={4}
          mb={12}
        >
          <TemplateSettings
            isDisabled={false}
            templateTitle={templateTitle}
            templateShortcut={templateShortcut}
            templateSubject={templateSubject}
            templateChannels={templateChannels}
            templateCategory={templateCategory}
            setTemplateChannels={memoizedSetTemplateChannels}
            setTemplateShortcut={memoizedSetTemplateShortcut}
            setTemplateCategory={memoizedSetTemplateCategory}
            setTemplateTitle={memoizedSetTemplateTitle}
            setTemplateSubject={memoizedSetTemplateSubject}
          />
          <TemplateText
            isDisabled={false}
            editorText={editorText}
            templateMediaType={templateMediaType}
            templateCategory={templateCategory}
            templateChannels={templateChannels}
            templateMediaUrl={templateMediaUrl}
            isSuffixIncluded={isSuffixIncluded}
            mandatoryPrefix={mandatoryPrefix}
            attachedFile={attachedFile}
            setMandatoryPrefix={memoizedSetMandatoryPrefix}
            setEditorText={memoizedSetEditorText}
            setIsSuffixIncluded={memoizedSetIsSuffixIncluded}
            setAttachedFile={memoizedSetAttachedFile}
            setTemplateMediaUrl={memoizedSetTemplateMediaUrl}
            setTemplateMediaType={memoizedSetTemplateMediaType}
            setTemplateText={memoizedSetTemplateText}
          />
        </VStack>
        <Flex
          justifyContent="center"
          alignItems="center"
          position="sticky"
          bottom={0}
          p={5}
          w="100%"
          bgColor={colorMode === "dark" ? "gray.800" : "gray.50"}
          boxShadow={`0 -0.25rem 1rem ${
            colorMode === "dark" ? "black" : "lightgray"
          }`}
        >
          <Button
            id="template-form-save-button"
            colorScheme={colorScheme}
            onClick={() => {
              if (!isTemplateValid()) {
                return;
              }

              if (templateToEdit) {
                editSelectedTemplate();
              } else {
                addTemplate();
              }
            }}
            isLoading={modalLoading}
            isDisabled={
              (templateToEdit && !templateToEdit.canEdit()) ||
              !!(
                templateChannels.includes(ConversationChannel.WHATSAPP) &&
                templateToEdit
              )
            }
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TemplateForm;
