import { Button, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import useMerchantStore from "hooks/use-merchant-store";
import React, { memo, useEffect, useState } from "react";
import PhoneInput, { Country } from "react-phone-number-input";

interface TestMessageSenderProps {
  campaignMessage: string | null;
  campaignTemplateName: string | null;
  onSendTestMessage: (handle: string, text: string) => void;
  isSendingTestMessage: boolean;
}

const TestMessageSender = ({
  campaignMessage,
  campaignTemplateName,
  isSendingTestMessage,
  onSendTestMessage,
}: TestMessageSenderProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { templates } = useAppSelector((state) => state.templates);
  const [testPhoneNumber, setTestPhoneNumber] = useState<string>(
    currentAgent!.notificationConfig?.handle || ""
  );

  const getMessageWithoutPrefix = (m: string) => m.trim().split(":").pop();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    setMessage(
      getMessageWithoutPrefix(campaignMessage || "")
        ? campaignMessage || ""
        : templates
            .find((t) => t.name === campaignTemplateName)
            ?.getWholeText() || ""
    );
  }, [campaignMessage, campaignTemplateName, templates]);

  return (
    <InputGroup
      size="md"
      sx={{
        width: "100%",
        lineHeight: "1rem",
        ".PhoneInput": {
          width: "100%",
        },
        ".PhoneInputInput": {
          borderRadius: "999px",
          width: "100%",
          paddingRight: "4.5rem",
          borderColor: `${colorScheme}.400`,
          backgroundColor: "inherit!important",
          opacity: isSendingTestMessage ? "0.5" : "1",
          _placeholder: {
            fontStyle: "italic",
          },
        },
      }}
    >
      <PhoneInput
        id="phoneInput"
        placeholder="Enter phone number"
        onChange={(newNumber) => setTestPhoneNumber(newNumber as string)}
        defaultCountry={merchant.getMerchantLocation() as Country}
        value={testPhoneNumber}
        autoFocus={false}
        disabled={isSendingTestMessage}
      />
      <InputRightElement height="100%" width="4.5rem">
        <Button
          size="sm"
          colorScheme="green"
          isLoading={isSendingTestMessage}
          isDisabled={
            !getMessageWithoutPrefix(message) ||
            isSendingTestMessage ||
            !testPhoneNumber
          }
          onClick={() => {
            onSendTestMessage(message, testPhoneNumber);
          }}
        >
          {isSendingTestMessage ? "Sending..." : "Send"}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default memo(TestMessageSender);
