import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import {
  customerGroup,
  vehicleGroup,
} from "components/campaigns/form/steps/audience/QueryFields";
import QueryBuilder from "components/shared/QueryBuilder";
import { GroupConfig } from "components/shared/QueryBuilder/QueryCondition";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { AudienceCriteria } from "entities/domain/audience";
import {
  ConditionType,
  LogicalOperationType,
  hasEmptyConditions,
  stringifyCriteria,
} from "entities/domain/criteria";
import useDebounce from "hooks/use-debounce";
import React, { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import {
  setAdvancedFilter,
  setShouldUseCriteriaForSearch,
} from "redux/features/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";

interface AdvancedFilterModalProps {}

const DEFAULT_CRITERIA: AudienceCriteria = {
  operation: LogicalOperationType.AND,
  conditions: [
    {
      operation: LogicalOperationType.AND,
      conditions: [
        {
          key: "tag_id",
          type: ConditionType.CONTAINS,
          value: "",
        },
      ],
    },
  ],
};

const AdvancedFilterModal = (_props: AdvancedFilterModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [audienceCriteria, setAudienceCriteria] = useState<AudienceCriteria>(
    JSON.parse(JSON.stringify(DEFAULT_CRITERIA))
  );
  const [isCriteriaConfirmed, setIsCriteriaConfirmed] =
    useState<boolean>(false);
  const [confirmedCriteriaString, setConfirmedCriteriaString] =
    useState<string>("");
  const [enabledGroups, setEnabledGroups] = useState<GroupConfig[]>([
    customerGroup,
  ]);

  const debouncedAudienceCriteria = useDebounce<string>(
    stringifyCriteria(audienceCriteria),
    500
  );

  const trySearching = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      await ContactsService.getAllContacts(
        auth0Context,
        0,
        merchant.id,
        undefined,
        undefined,
        audienceCriteria
      );

      setIsCriteriaConfirmed(true);
      setConfirmedCriteriaString(JSON.stringify(audienceCriteria));
    } catch (err: unknown) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
      } else if (err instanceof AxiosError) {
        setErrorMessage(
          err.response?.data.message || "Failed to search by this criteria."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  // every time audience object changes we should reset isCriteriaConfirmed
  // it will be confirmed later with trySearching
  useEffect(() => {
    if (confirmedCriteriaString === JSON.stringify(audienceCriteria)) {
      return;
    }

    setIsCriteriaConfirmed(false);
  }, [audienceCriteria]);

  // we use stringified criteria for debouncing instead of object
  useEffect(() => {
    if (audienceCriteria && !hasEmptyConditions(audienceCriteria)) {
      trySearching();
    }
  }, [debouncedAudienceCriteria]);

  useEffect(() => {
    if (!isOpen) {
      setErrorMessage(null);
      if (!isCriteriaConfirmed) {
        setAudienceCriteria(JSON.parse(JSON.stringify(DEFAULT_CRITERIA)));
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (merchant.isIntegrationEnabled(IntegrationName.KEYLOOP)) {
      setEnabledGroups([customerGroup, vehicleGroup]);
    } else {
      setEnabledGroups([customerGroup]);
    }
  }, [merchant]);

  return (
    <>
      <IconButton
        aria-label="Filter"
        colorScheme={isBaseSize ? colorScheme : "gray"}
        variant={isBaseSize ? "ghost" : "solid"}
        icon={<Icon as={IoFilter} />}
        onClick={() => setIsOpen(true)}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        size={isBaseSize ? "full" : "xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Advanced Customers Filter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <QueryBuilder
              entityNamePlural="customers"
              groups={enabledGroups}
              criteria={audienceCriteria}
              setCriteria={setAudienceCriteria}
            />

            {errorMessage ? (
              <Alert
                mt={4}
                status="error"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
                borderRadius="xl"
              >
                <AlertIcon boxSize={8} mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Error occured when searching with this criteria
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  Try using different criterias for search. {errorMessage}
                </AlertDescription>
              </Alert>
            ) : null}
          </ModalBody>

          <ModalFooter display="flex" gridGap={2}>
            <Button
              variant="ghost"
              colorScheme={colorScheme}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
            <Button
              aria-label="Search by Criteria"
              isDisabled={isLoading || !isCriteriaConfirmed}
              isLoading={isLoading}
              colorScheme={colorScheme}
              onClick={() => {
                dispatch(
                  setAdvancedFilter(
                    JSON.parse(JSON.stringify(audienceCriteria))
                  )
                );
                dispatch(setShouldUseCriteriaForSearch(true));
                setIsOpen(false);
              }}
            >
              Search
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdvancedFilterModal;
