import { Box, Textarea, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { WabaTemplateCategory } from "entities/domain/whatsapp-template";
import TextLimits from "./TextLimits";
import BuildingBlockWrapper from "./BuildingBlockWrapper";
import BuildingBlockHeaderWrapper from "./BuildingBlockHeaderWrapper";

interface FooterBuildingBlockProps {
  text: string;
  category: WabaTemplateCategory;
  isDisabled: boolean;
  onTextChange: (text: string) => void;
}

const MAX_FOOTER_LENGTH = 60;

const FooterBuildingBlock = ({
  text,
  category,
  isDisabled,
  onTextChange,
}: FooterBuildingBlockProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [localText, setLocalText] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(
    category === WabaTemplateCategory.MARKETING
  );

  useEffect(() => {
    if (!isOpen) {
      setLocalText("");
      onTextChange("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (category === WabaTemplateCategory.MARKETING) {
      setIsOpen(true);
      setLocalText(text);
    }
  }, [category, text]);

  return (
    <BuildingBlockWrapper>
      <BuildingBlockHeaderWrapper
        name="Footer"
        isCloseable={category !== WabaTemplateCategory.MARKETING}
        isOpen={isOpen}
        setIsOpen={
          category === WabaTemplateCategory.MARKETING || isDisabled
            ? undefined
            : setIsOpen
        }
      />
      {isOpen ? (
        <>
          <Box
            width="100%"
            height={isBaseSize ? "8rem" : "4rem"}
            p="1rem"
            px="1.5rem"
            overflow="hidden"
          >
            <Textarea
              border="none"
              p={0}
              id="whatsapp-template-footer-textarea"
              isDisabled={isDisabled}
              placeholder="e.g. Reply UNSUB to unsubscribe"
              value={localText}
              onChange={(e) => {
                setLocalText(e.target.value);
                onTextChange(e.target.value);
              }}
              maxLength={MAX_FOOTER_LENGTH}
              noOfLines={1}
            />
          </Box>
          <TextLimits maxCharacters={MAX_FOOTER_LENGTH} text={localText} />
        </>
      ) : null}
    </BuildingBlockWrapper>
  );
};

export default FooterBuildingBlock;
