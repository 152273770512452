import { Box, FormControl, FormLabel, useColorMode } from "@chakra-ui/react";
import MessageInputCoWriter from "components/campaigns/form/steps/message/MessageInputCoWriter";
import MessageInputOverlay from "components/campaigns/form/steps/message/MessageInputOverlay";
import { TemplateCategory } from "entities/domain/templates/full_template";
import { useAppSelector } from "redux/hooks";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MessageInputToolbar, {
  CustomFieldDefinition,
} from "components/campaigns/form/steps/message/MessageInputToolbar";
import {
  LexicalEditor,
  $getSelection,
  $getRoot,
  $isRangeSelection,
} from "lexical";
import InboxService from "services/inbox";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { useAuth0 } from "@auth0/auth0-react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import { CustomFields } from "entities/domain/templates";
import MarketingPrefix from "../MarketingPrefix";
import TemplateAttachment from "../TemplateAttachment";
import { UnsubscribeSuffix } from "../UnsubscribeSuffix";

const customFieldsDefinition: CustomFieldDefinition[] = [
  {
    key: "contact_first_name",
    value: "{contact_first_name}",
    description: "Contact First Name",
  },
  {
    key: "contact_last_name",
    value: "{contact_last_name}",
    description: "Contact Last Name",
  },
  {
    key: "merchant_name",
    value: "{merchant_name}",
    description: "Merchant Name",
  },
  {
    key: "review_link",
    value: "{review_link}",
    description: "Review Link",
  },
];

const customFieldsSampleValues: CustomFields = {
  contact_first_name: "Contact First Name",
  contact_last_name: "Contact Last Name",
  merchant_name: "Merchant Name",
  review_link: "Review Link",
  email_address: "Email Address",
  phone_number: "Phone Number",
  agent_first_name: "Agent First Name",
  agent_last_name: "Agent Last Name",
  address: "Address",
};

interface TemplateTextProps {
  isDisabled: boolean;
  templateCategory: TemplateCategory | null;
  templateChannels: string[];
  templateMediaUrl: string | null;
  templateMediaType: string | null;
  isSuffixIncluded: boolean;
  editorText: { value: string };
  mandatoryPrefix: string;
  attachedFile: File | null;
  setIsSuffixIncluded: (isIncluded: boolean) => void;
  setMandatoryPrefix: (newPrefix: string) => void;
  setEditorText: (newEditorText: { value: string }) => void;
  setTemplateText: (text: string) => void;
  setTemplateMediaUrl: (mediaUrl: string | null) => void;
  setTemplateMediaType: (mediaType: string | null) => void;
  setAttachedFile: (file: File | null) => void;
}

const MAX_LENGTH_WHATSAPP = 1024;

const TemplateText = ({
  isDisabled,
  templateCategory,
  templateChannels,
  templateMediaUrl,
  templateMediaType,
  attachedFile,
  isSuffixIncluded,
  editorText,
  mandatoryPrefix,
  setIsSuffixIncluded,
  setMandatoryPrefix,
  setEditorText,
  setTemplateText,
  setTemplateMediaUrl,
  setTemplateMediaType,
  setAttachedFile,
}: TemplateTextProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const [addAttachmentOrUndefined, setAddAttachmentOrUndefined] = useState<
    undefined | ((file: File) => Promise<void>)
  >(undefined);
  const [isAskingSuggestion, setIsAskingSuggestion] = useState<boolean>(false);

  const editorReference = useRef<LexicalEditor | undefined>();

  const insertEmoji = useCallback(
    ({ native }: { native: string }) => {
      if (!editorReference || !editorReference.current) {
        return;
      }

      editorReference.current.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return;
        }

        selection.insertText(` ${native} `);

        setTemplateText($getRoot().getTextContent());
      });

      editorReference.current?.focus(() => {}, {
        defaultSelection: "rootEnd",
      });
    },
    [editorReference]
  );

  const insertIntoText = useCallback(
    (someText: string) => {
      if (!editorReference || !editorReference.current) {
        return;
      }

      editorReference.current.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return;
        }

        selection.insertText(` ${someText} `);

        setTemplateText($getRoot().getTextContent());
      });

      editorReference.current?.focus(() => {}, {
        defaultSelection: "rootEnd",
      });
    },
    [editorReference]
  );

  const askAiForHelp = useCallback(async (aiPrompt: string) => {
    setIsAskingSuggestion(true);

    let response: { suggestion: string } | null = null;

    try {
      response = await InboxService.getSuggestedTemplate(
        auth0Context,
        merchant.id,
        aiPrompt
      );
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Could not get AI suggestion for campaigns", e);
    } finally {
      setIsAskingSuggestion(false);
    }

    if (response === null) {
      return;
    }

    setTemplateText(response.suggestion);
    setEditorText({
      value: response!.suggestion,
    });
  }, []);

  const addAttachment = async (file: File) => {
    setAttachedFile(file);
  };

  const setPrefix = useCallback(
    (newPrefix: string) => setMandatoryPrefix(newPrefix),
    []
  );
  const enabledPlugins = useMemo(() => {
    const newEnabledPlugins = [];

    if (templateChannels.includes(ConversationChannel.SMS)) {
      newEnabledPlugins.push(EditorUltraPlugin.MAXLENGTH);
    }

    return newEnabledPlugins;
  }, [templateChannels]);

  useEffect(() => {
    if (
      templateChannels.includes(ConversationChannel.EMAIL) ||
      templateChannels.includes(ConversationChannel.SMS)
    ) {
      setAddAttachmentOrUndefined(() => addAttachment);
    } else {
      setAddAttachmentOrUndefined(undefined);
    }
  }, [templateChannels]);

  return (
    <>
      {isDisabled ? null : (
        <FormControl>
          <MessageInputCoWriter
            askAiForHelp={askAiForHelp}
            motive="template"
            isDisabled={false}
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Message</FormLabel>
        <Box
          position="relative"
          background={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          borderStyle="solid"
          borderWidth="2px"
          borderColor={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
          }
          p="1rem"
          px="1.5rem"
          mb={36}
        >
          <MessageInputOverlay isShown={isAskingSuggestion} />
          {templateCategory === TemplateCategory.MARKETING && (
            <MarketingPrefix
              prefix={mandatoryPrefix}
              setPrefix={setPrefix}
              isDisabled={isDisabled}
            />
          )}
          <EditorUltra
            maxHeight="450px"
            isDisabled={isDisabled}
            isEditable={!isDisabled}
            defaultText={editorText}
            placeholder="Enter some text"
            editorReference={editorReference}
            setText={setTemplateText}
            enabledPlugins={enabledPlugins}
            channels={templateChannels as ConversationChannel[]}
            maxCharLength={MAX_LENGTH_WHATSAPP}
            exceededText="This template message cannot be more than 1,024 characters. Please shorten it before submitting."
            customFields={customFieldsSampleValues}
          />
          <TemplateAttachment
            mediaUrl={templateMediaUrl || null}
            mediaType={templateMediaType || null}
            file={attachedFile}
            onAttachmentRemove={
              isDisabled
                ? undefined
                : () => {
                    setTemplateMediaUrl(null);
                    setTemplateMediaType(null);
                    setAttachedFile(null);
                  }
            }
          />
          {templateCategory === TemplateCategory.MARKETING && (
            <UnsubscribeSuffix
              isIncluded={isSuffixIncluded}
              setIsIncluded={setIsSuffixIncluded}
            />
          )}
          {isDisabled ? null : (
            <MessageInputToolbar
              insertEmoji={insertEmoji}
              insertIntoText={insertIntoText}
              addAttachment={addAttachmentOrUndefined}
              customFields={customFieldsDefinition}
            />
          )}
        </Box>
      </FormControl>
    </>
  );
};

export default memo(TemplateText);
