import {
  AspectRatio,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { ReactComponent as PDFFile } from "assets/icons/file-pdf-box.svg";
import { ReactComponent as File } from "assets/icons/file.svg";
import { DownloadIcon } from "@chakra-ui/icons";
import { useAppSelector } from "redux/hooks";

interface MediaTypeProps {
  type: string;
  url: string;
  id: string;
}

const SUPPORTED_MEDIA_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "video/mp4",
  "audio/mpeg",
  "audio/mp3",
  "audio/mp4",
  "audio/ogg; codecs=opus",
  "application/pdf",
  "image/gif",
];

const MediaType = ({ type, url, id }: MediaTypeProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      {type.includes("image") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Image
          src={url}
          alt={`${type}-${id}`}
          boxSize="2xl"
          objectFit="cover"
          borderRadius="lg"
        />
      )}
      {type.includes("video") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <AspectRatio boxSize="2xl" borderRadius="lg">
          <video
            controls
            style={{
              borderRadius: "0.5rem",
            }}
          >
            <source src={url} type={type} />
          </video>
        </AspectRatio>
      )}
      {type.includes("audio") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <AspectRatio
          borderRadius="lg"
          maxWidth="15rem"
          width="15rem"
          height="15rem"
          maxHeight="15rem"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.50"
        >
          <audio
            controls
            style={{
              borderRadius: "0.5rem",
            }}
          >
            <source src={url} type={type} />
          </audio>
        </AspectRatio>
      )}
      {type.includes("pdf") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Flex
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.50"
          borderRadius="2xl"
          direction="column"
          alignItems="center"
          justifyContent="center"
          p={4}
        >
          <Icon
            as={PDFFile}
            width="15rem"
            height="15rem"
            fill={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
          <Link
            href={url}
            isExternal={true}
            download={true}
            display="inline-flex"
            gridGap={2}
            alignItems="center"
          >
            <Text>Download</Text>
            <Icon
              as={DownloadIcon}
              fill={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
          </Link>
        </Flex>
      )}
      {!SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Flex
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.50"
          borderRadius="2xl"
          direction="column"
          alignItems="center"
          justifyContent="center"
          p={4}
        >
          <Icon
            as={File}
            width="15rem"
            height="15rem"
            borderRadius="lg"
            fill={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
          <Link
            href={url}
            isExternal={true}
            download={true}
            display="inline-flex"
            gridGap={2}
            alignItems="center"
          >
            <Text>Download</Text>
            <Icon
              as={DownloadIcon}
              fill={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
          </Link>
        </Flex>
      )}
    </>
  );
};

export default MediaType;
