import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "components/spinner";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import ReviewsService from "services/reviews";

const MicrosoftOutlookCallback = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const auth0Context = useAuth0();
  const microsoft_redirect_url = new URLSearchParams(useLocation().search);
  const state = microsoft_redirect_url.get("state");
  const merchantIdFromState = state ? parseInt(state, 10) : null;
  const microsoft_authorization_code = microsoft_redirect_url.get("code");
  const { merchant } = useAppSelector((rootState) => rootState.merchant);
  const { currentAgent } = useAppSelector((rootState) => rootState.agents);

  useEffect(() => {
    if (!merchant) {
      return;
    }

    if (!merchantIdFromState || typeof merchantIdFromState !== "number") {
      // eslint-disable-next-line
      console.error(
        "Merchant ID didn't come with the sate to Microsoft Outlook callback",
        {
          url: microsoft_redirect_url,
          passedMerchantId: state,
        }
      );
      localStorage.setItem(
        "callback_fail",
        JSON.stringify({
          title: "We couldn't connect your Microsoft Outlook account...",
          description: "Please try again later or contact our support team.",
        })
      );
      window.location.assign(`/public/callback/fail`);

      return;
    }

    const merchantIsAvailable = currentAgent!.merchants
      .map((m: AgentMerchantDomain) => m.id)
      .includes(merchantIdFromState);

    if (merchantIdFromState !== merchant.id) {
      if (!merchantIsAvailable) {
        // eslint-disable-next-line
        console.error(
          "Merchant ID is not available to agent during Microsoft Outlook callback processing",
          {
            url: microsoft_redirect_url,
            passedMerchantId: state,
            agentId: currentAgent!.id,
          }
        );
        localStorage.setItem(
          "callback_fail",
          JSON.stringify({
            title: "We couldn't connect your Microsoft Outlook account...",
            description: "Please try again later or contact our support team.",
          })
        );
        window.location.assign(`/public/callback/fail`);

        return;
      }

      localStorage.setItem("fuzey:merchant:id", merchantIdFromState.toString());
      window.location.assign(window.location.href);

      return;
    }

    if (microsoft_authorization_code) {
      ReviewsService.addMicrosoftOutlookIntegration(
        auth0Context,
        microsoft_authorization_code,
        merchant.id
      )
        .then(() => {
          localStorage.setItem(
            "callback_success",
            JSON.stringify({
              title: "Outlook was successfully connected!",
              description:
                "You can disconnect any time from your settings page.",
            })
          );
          window.location.assign(`/public/callback/success`);
          setIsLoading(false);
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e);

          localStorage.setItem(
            "callback_fail",
            JSON.stringify({
              title: "We couldn't connect your Outlook account...",
              description:
                "Please try again later or contact our support team.",
            })
          );
          window.location.assign(`/public/callback/fail`);
          setIsLoading(false);
        });
    } else {
      localStorage.setItem(
        "callback_fail",
        JSON.stringify({
          title: "We couldn't connect your Outlook account...",
          description: "Please try again later or contact our support team.",
        })
      );
      window.location.assign(`/public/callback/fail`);
      setIsLoading(false);
    }
  }, [merchant]);

  if (isLoading) {
    return <Spinner />;
  }

  return <div>Redirecting...</div>;
};

export default MicrosoftOutlookCallback;
