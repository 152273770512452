import {
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  Tooltip,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import ContactDomain from "entities/domain/customers/contact-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface ProfileAreaProps {
  contactToDisplay: ContactDomain;
}

function ProfileArea({ contactToDisplay }: ProfileAreaProps) {
  const { editContact, getContactTags } = useContactsStore();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string>("");

  useEffect(() => {
    setDisplayName(contactToDisplay.getDisplayName());
    setProfilePicture(contactToDisplay.getPicture());
    setName(contactToDisplay.name || "");
    setSurname(contactToDisplay.surname || "");
  }, [contactToDisplay]);

  const onSaveInfo = async () => {
    const isEdited: boolean =
      (name === contactToDisplay.name &&
        surname !== contactToDisplay.surname) ||
      (name !== contactToDisplay.name &&
        surname === contactToDisplay.surname) ||
      (name !== contactToDisplay.name && surname !== contactToDisplay.surname);

    if (!isEdited) return;

    editContact({
      id: contactToDisplay!.id!,
      name: name || undefined,
      surname: surname || undefined,
      tags: getContactTags(contactToDisplay).map((tag: TagsDomain) => tag.tag),
      tagIds: contactToDisplay.tagIds,
      address: contactToDisplay.address?.country
        ? {
            first_line: contactToDisplay.address.firstLine,
            second_line: contactToDisplay.address.secondLine,
            city: contactToDisplay.address.city,
            country: contactToDisplay.address.country,
            state: contactToDisplay.address.state,
            postcode: contactToDisplay.address.postcode,
          }
        : undefined,
      channels: contactToDisplay.channels.map((c: CustomerChannelDomain) => ({
        id: c.id,
        type: c.type,
        handle: c.handle,
        is_active: c.isActive,
      })),
      notes: contactToDisplay.notes,
    }).then((res) => {
      setDisplayName(res?.getDisplayName() || `${res?.name} ${res?.surname}`);
      setProfilePicture(res?.getPicture() || "");
    });
  };

  const handleOnBlur = async (event: any) => {
    if (contactToDisplay.isArchived) {
      return;
    }

    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    onSaveInfo();
    setIsEditing(false);
  };

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Flex justify="center">
        <ProfileAvatar profilePicture={profilePicture} name={displayName} />
      </Flex>
      <Flex
        align="center"
        direction="column"
        borderBottom="1px"
        borderColor="gray.50"
      >
        {isEditing ? (
          <HStack py="1.5rem" px="0.5rem">
            <InputGroup size="sm" onBlur={(event) => handleOnBlur(event)}>
              <Input
                colorScheme={colorScheme}
                mr={2}
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
              <Input
                colorScheme={colorScheme}
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                placeholder="Surname"
              />
            </InputGroup>
          </HStack>
        ) : (
          <Button
            variant="unstyled"
            my="1.5rem"
            onClick={() => {
              if (contactToDisplay.isArchived) {
                return;
              }

              setIsEditing(true);
            }}
            isDisabled={contactToDisplay.isArchived}
          >
            <Tooltip
              label="Click to edit contact name"
              backgroundColor="rgba(54, 178, 250, 0.9)"
              px={3}
              py={1}
              isDisabled={contactToDisplay.isArchived}
            >
              <Text
                fontSize="md"
                fontWeight={700}
                _hover={{ color: "gray.300" }}
              >
                {displayName !== ""
                  ? displayName
                  : contactToDisplay.getPhoneNum()}
              </Text>
            </Tooltip>
          </Button>
        )}
      </Flex>
    </>
  );
}

export default ProfileArea;
