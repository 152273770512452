import { Text } from "@chakra-ui/react";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import FullTemplateDomain from "entities/domain/templates/full_template";
import WhatsappTemplate from "entities/domain/whatsapp-template";
import React, { useEffect, useState } from "react";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import { useAppSelector } from "redux/hooks";

const TemplateWhatsAppStatusColumn = ({
  item,
}: {
  item: RegularOrWhatsappTemplate;
}) => {
  const { merchant } = useAppSelector((state) => state.merchant);

  const hasWhatsappChannel = merchant.isMerchantChannelEnabled(
    ConversationChannel.WHATSAPP
  );

  const [status, setStatus] = useState("");

  useEffect(() => {
    if (item instanceof WhatsappTemplate) {
      setStatus(item.status);
    } else if (item instanceof FullTemplateDomain) {
      setStatus(item.whatsappTemplateStatus || "");
    }
  }, [item]);

  return (
    hasWhatsappChannel && (
      <Text
        color={item.getWhatsAppStatusColor()}
        textTransform="capitalize"
        fontWeight={700}
      >
        {status}
      </Text>
    )
  );
};

export default TemplateWhatsAppStatusColumn;
