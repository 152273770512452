import { useAuth0 } from "@auth0/auth0-react";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useDebounce from "hooks/use-debounce";
import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { ContactsSorting } from "util/ContactsFilter";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ReviewsSearch from "components/reviews/ReviewsSearch";
import AudiencesService from "services/audiences";
import ContactsService from "services/contacts";
import { BulkContactsUpdatedMessage } from "entities/ISocketArgs";
import {
  bulkUpdateContacts,
  setAdvancedFilter,
  setShouldUseCriteriaForSearch,
} from "redux/features/contacts";
import QueryBuilder from "components/shared/QueryBuilder";
import {
  customerGroup,
  vehicleGroup,
} from "components/campaigns/form/steps/audience/QueryFields";
import { useWebSocket } from "hooks/use-socket";
import ContactList from "./contacts-list";
import ContactsTopbar from "./top-area";

const ContactsPage = () => {
  const auth0Context = useAuth0();
  const { search } = useLocation();
  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { advancedFilter } = useAppSelector((state) => state.contacts);
  const navigate = useNavigate();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const { contactId } = useParams<{ contactId: string }>();

  const queryParams = new URLSearchParams(search);
  const audienceId = queryParams.get("audience_id");
  const searchText = queryParams.get("search");

  const [currentSearchText, setCurrentSearchText] = useState<string>("");
  const [currentChannelsFilter, setCurrentChannelsFilter] = useState<string[]>(
    []
  );
  const [sorting, setSorting] = useState<ContactsSorting | undefined>(
    undefined
  );
  const [filter, setFilter] = useState<{
    name?: string;
    channels?: string[];
    tagIds?: string[];
  }>({});
  const debouncedSearchText = useDebounce<string>(currentSearchText, 800);
  const [displayBackIcon, setDisplayBackIcon] = useState<boolean>(false);
  const [displayMoreIcon, setDisplayMoreIcon] = useState<boolean>(false);
  const [contactToDisplay, setContactToDisplay] = useState<ContactDomain>();
  const [bulkContactsUpdate, setBulkContactsUpdate] = useState(
    {} as BulkContactsUpdatedMessage
  );
  const [openEditTags, setOpenEditTags] = useState<boolean>(false);

  const [audienceCustomersList, setAudienceCustomersList] =
    useState<ContactListDomain | null>(null);

  const shouldSetFilter: boolean =
    debouncedSearchText !== filter.name ||
    currentChannelsFilter.length !== filter.channels?.length ||
    !currentChannelsFilter.every((chan) => filter.channels?.includes(chan));

  useEffect(() => {
    if (shouldSetFilter) {
      setFilter({
        ...filter,
        name: debouncedSearchText,
        channels: currentChannelsFilter,
      });
    }
  }, [debouncedSearchText, currentChannelsFilter]);

  useEffect(() => {
    if (!contactId) {
      return;
    }

    ContactsService.getContact(
      auth0Context,
      Number(contactId),
      merchant.id
    ).then((contact) => {
      setContactToDisplay(contact);
    });
  }, [contactId]);

  function handleSorting(sorting2: ContactsSorting | undefined) {
    setSorting(sorting2);
  }

  const handleCloseContactDisplay = () => {
    setContactToDisplay(undefined);
    setDisplayBackIcon(false);
    setDisplayMoreIcon(false);
    navigate({
      pathname: `/${merchant.id}/contacts`,
      search: createSearchParams(search).toString(),
    });
  };

  const handleOpenContactDisplay = (selectedContact: ContactDomain) => {
    setContactToDisplay(selectedContact);
    setDisplayBackIcon(true);
    setDisplayMoreIcon(true);
    navigate({
      pathname: `/${merchant.id}/contacts/${selectedContact.id}`,
      search: createSearchParams(search).toString(),
    });
  };

  useEffect(() => {
    if (searchText) {
      setCurrentSearchText(searchText);
      queryParams.delete("search");
      navigate(`/${merchant.id}/contacts?search=${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (audienceId) {
      AudiencesService.fetchAudienceCustomers(auth0Context, merchant.id, {
        id: audienceId,
      }).then(
        (fetchedAudienceCustomersList) => {
          setAudienceCustomersList(fetchedAudienceCustomersList);
        },
        (error) => {
          // eslint-disable-next-line
          console.error("Failed to fetch audience customers:", error);
        }
      );
    }
  }, []);

  const handleBulkContactsUpdated = (args: BulkContactsUpdatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setBulkContactsUpdate(args);
  };

  useEffect(() => {
    addEventHandler("bulk_customers_updated", handleBulkContactsUpdated);

    return () => {
      removeEventHandler("bulk_customers_updated", handleBulkContactsUpdated);
    };
  }, [addEventHandler, removeEventHandler]);

  useEffect(() => {
    // Don't use deepCompare because all bulk actions return same result
    if (Object.keys(bulkContactsUpdate).length !== 0) {
      const updatedConversationIds = bulkContactsUpdate.customer_ids;

      ContactsService.getContactsByIds(
        auth0Context,
        updatedConversationIds,
        merchant.id
      ).then((contactsToUpdate) => {
        dispatch(bulkUpdateContacts(contactsToUpdate));
      });
    }
  }, [bulkContactsUpdate]);

  useEffect(() => {
    return () => {
      dispatch(setAdvancedFilter(null));
      dispatch(setShouldUseCriteriaForSearch(false));
    };
  }, []);

  return (
    <Box
      width="100%"
      minHeight="100%"
      height="100%"
      {...(isBaseSize
        ? {}
        : {
            bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
          })}
    >
      <ContactsTopbar
        hideFilters={!!audienceCustomersList}
        searchText={currentSearchText}
        setSearchText={setCurrentSearchText}
        setSorting={handleSorting}
        displayBackIcon={displayBackIcon}
        displayMoreIcon={displayMoreIcon}
        onBackIconClick={handleCloseContactDisplay}
        contactToDisplay={contactToDisplay}
        setRecentlyCreatedContact={setContactToDisplay}
        setRecentlyUpdatedContact={setContactToDisplay}
        prefilledName={currentSearchText}
      />

      {isBaseSize && !contactId ? (
        <Box mx={4}>
          <ReviewsSearch
            placeholder="John Doe"
            value={currentSearchText}
            onChange={(e) => setCurrentSearchText(e.target.value)}
          />
        </Box>
      ) : null}

      <Flex
        direction="row"
        overflow="hidden"
        height="100%"
        width="100%"
        minWidth="100%"
        alignItems="start"
      >
        <Box
          height="100%"
          w="100%"
          backgroundColor={colorMode === "dark" ? "gray.800" : "white"}
          borderTopRadius={isBaseSize ? 0 : "3xl"}
          overflow="hidden"
          mx={4}
        >
          {advancedFilter ? (
            <Flex
              gridGap={2}
              justifyContent="start"
              alignItems="center"
              w="100%"
              p={4}
              borderBottomWidth="1px"
              borderBottomStyle="solid"
              borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
            >
              <Alert status="info" borderRadius="xl" width="auto" p={2}>
                <VStack alignItems="start" position="relative" spacing={0}>
                  <AlertTitle mb={2}>🔍 Search result</AlertTitle>
                  <CloseButton
                    position="absolute"
                    size="sm"
                    right={0}
                    top={0}
                    onClick={() => {
                      dispatch(setAdvancedFilter(null));
                      dispatch(setShouldUseCriteriaForSearch(false));
                    }}
                  />
                  <AlertDescription fontSize="sm">
                    <QueryBuilder
                      entityNamePlural="customers"
                      groups={[customerGroup, vehicleGroup]}
                      criteria={advancedFilter}
                      setCriteria={() => {}}
                      isDisabled={true}
                    />
                  </AlertDescription>
                </VStack>
              </Alert>
            </Flex>
          ) : null}
          <ContactList
            audience={
              audienceCustomersList ? audienceCustomersList.contacts : null
            }
            filter={filter}
            sorting={sorting}
            setSorting={handleSorting}
            setDisplayBackIcon={setDisplayBackIcon}
            setDisplayMoreIcon={setDisplayMoreIcon}
            contactToDisplay={contactToDisplay}
            handleOpenContactDisplay={handleOpenContactDisplay}
            handleCloseContactDisplay={handleCloseContactDisplay}
            setOpenEditTags={setOpenEditTags}
            openEditTags={openEditTags}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactsPage;
