import { Flex, Text, List } from "@chakra-ui/react";
import ReviewDomain from "entities/domain/reviews/review-domain";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SmartList from "components/shared/SmartList";
import { useAuth0 } from "@auth0/auth0-react";
import ReviewsService from "services/reviews";
import { numberOfReviewsPerLoad } from "util/constants";
import { useWebSocket } from "hooks/use-socket";
import { reviewTransformDtoToDomain } from "entities/transformers/reviewTransformer";
import useDebounce from "hooks/use-debounce";
import {
  ReviewCreatedMessage,
  ReviewDeletedMessage,
  ReviewUpdatedMessage,
} from "entities/ISocketArgs";
import ReviewCard from "./ReviewCard";
import SkeletonOverlay from "./SkeletonOverlay";

interface ReviewsListProps {}

export const insertReviewAccordingToPrettyStatus = (
  review: ReviewDomain,
  reviews: ReviewDomain[]
): ReviewDomain[] => {
  const newReviews = reviews.filter((r) => r.id !== review.id);
  const groupBeginsAt = newReviews.findIndex(
    (r) => r.prettyStatus === review.prettyStatus
  );

  let whereToInsert = groupBeginsAt;

  for (let i = groupBeginsAt; i < newReviews.length - 1; i++) {
    const isOlderDisplayDate =
      new Date(review.displayDateRaw) <= new Date(reviews[i].displayDateRaw);

    if (isOlderDisplayDate) {
      whereToInsert = i + 1;
      break;
    }
  }

  return [
    ...newReviews.slice(0, whereToInsert),
    review,
    ...newReviews.slice(whereToInsert),
  ];
};

const ReviewsList = (_props: ReviewsListProps) => {
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const { merchant } = useAppSelector((state) => state.merchant);

  const [reviews, setReviews] = useState<ReviewDomain[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isIntitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const debouncedIsInitialLoading = useDebounce(isIntitialLoading, 50);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [reviewCreate, setReviewCreate] = useState<ReviewCreatedMessage>();
  const [reviewUpdate, setReviewUpdate] = useState<ReviewUpdatedMessage>();
  const [reviewDelete, setReviewDelete] = useState<ReviewDeletedMessage>();

  const hasMoreReviews = (
    reviewsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (reviewsAmount < numberOfReviewsPerLoad) {
      return false;
    }

    if (!currentPageAmount) {
      return false;
    }

    return true;
  };

  const fetchMoreReviews = async () => {
    try {
      setIsLoading(true);

      const nextPage = (
        await ReviewsService.getReviews(
          auth0Context,
          merchant.id,
          reviews.length || 0
        )
      ).reviews;

      const newReviews: ReviewDomain[] = reviews.length
        ? reviews.concat(nextPage)
        : nextPage;

      setReviews(newReviews);
      setHasNextPage(hasMoreReviews(newReviews.length, nextPage?.length || 0));
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Failed to fetch more reviews", e);
    } finally {
      setIsLoading(false);
      if (isIntitialLoading) {
        setIsInitialLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchMoreReviews();
  }, []);

  const handleReviewCreated = (args: ReviewCreatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setReviewCreate(args);
  };

  const handleReviewUpdated = (args: ReviewUpdatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setReviewUpdate(args);
  };

  const handleReviewDeleted = (args: ReviewDeletedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setReviewDelete(args);
  };

  useEffect(() => {
    if (reviewCreate && Object.keys(reviewCreate).length !== 0) {
      const review: ReviewDomain = reviewTransformDtoToDomain(
        reviewCreate.review
      );

      const newReviews = insertReviewAccordingToPrettyStatus(review, reviews);

      setReviews(newReviews);
    }
  }, [reviewCreate]);

  useEffect(() => {
    if (reviewUpdate && Object.keys(reviewUpdate).length !== 0) {
      const review: ReviewDomain = reviewTransformDtoToDomain(
        reviewUpdate.review
      );

      const newReviews = insertReviewAccordingToPrettyStatus(review, reviews);

      setReviews(newReviews);
    }
  }, [reviewUpdate]);

  useEffect(() => {
    if (reviewDelete && Object.keys(reviewDelete).length !== 0) {
      setReviews(reviews.filter((r) => r.id !== reviewDelete.review.id));
    }
  }, [reviewDelete]);

  useEffect(() => {
    addEventHandler("review_created", handleReviewCreated);
    addEventHandler("review_updated", handleReviewUpdated);
    addEventHandler("review_deleted", handleReviewDeleted);

    return () => {
      removeEventHandler("review_created", handleReviewCreated);
      removeEventHandler("review_updated", handleReviewUpdated);
      removeEventHandler("review_deleted", handleReviewDeleted);
    };
  }, [addEventHandler, removeEventHandler]);

  const scrollContainerRef = useRef<HTMLUListElement | null>(null);

  return reviews.length === 0 && isLoading === false ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <Text> No Reviews Found</Text>
    </Flex>
  ) : (
    <>
      {debouncedIsInitialLoading ? <SkeletonOverlay /> : null}
      <List
        w="100%"
        ref={scrollContainerRef}
        display="block"
        maxHeight="100%"
        overflow="auto"
        listStyleType="none"
      >
        <SmartList
          hasHeader={false}
          items={reviews}
          itemIdentifier="id"
          hasNextPage={hasNextPage}
          getDifferentiator={(review: ReviewDomain | undefined) =>
            review?.prettyStatus || ""
          }
          canFetchMore={true}
          fetchMore={fetchMoreReviews}
          containerRef={scrollContainerRef}
          onItemClick={(review: ReviewDomain) =>
            navigate(`/${merchant.id}/reviews/${encodeURIComponent(review.id)}`)
          }
          columns={[
            {
              label: "Review",
              component: ReviewCard,
            },
          ]}
        />
      </List>
    </>
  );
};

export default ReviewsList;
