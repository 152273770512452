/* eslint-disable import/prefer-default-export */
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import BankAccountDetailsDomain from "entities/domain/admin/merchants/bank-account-details-domain";
import BankAccountDomain from "entities/domain/admin/merchants/bank-account-domain";
import ExternalMetadataDomain from "entities/domain/admin/merchants/external-metadata-domain";
import MerchantDomainBase, {
  StrictnessType,
  TierType,
} from "entities/domain/admin/merchants/merchant-domain";
import MerchantIntegration, {
  MerchantIntegrations,
} from "entities/domain/admin/merchants/merchant-integrations";
import MerchantTierLimitsDomain from "entities/domain/admin/merchants/merchant-tier-limits-domain";
import { MerchantUsageDomain } from "entities/domain/admin/merchants/merchant-usage";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { ChannelsDto } from "entities/dto/ChannelsDTO";
import {
  AdminMerchantDto,
  BankAccountType,
  BankDetailsType,
  ExternalMetadataType,
  IntegrationsDto,
  MerchantDto,
  MerchantUsageDto,
} from "entities/dto/MerchantDTO";
import { getChannelIcon } from "util/constants";

interface ChannelOptionTypes {
  value: string;
  label: string;
  icon?: string;
  color?: string;
}

export const transformFromAvailableChannelsToOptions = (
  channel: ChannelDomain
): ChannelOptionTypes => ({
  value: channel.channelName,
  label: channel.getDisplayChannelName(),
  icon: getChannelIcon(channel.channelName),
});

const externalMetadataTransformerFromDtoToDomain = (
  data: ExternalMetadataType
): ExternalMetadataDomain =>
  new ExternalMetadataDomain(
    data.google_account_id,
    data.google_location_id,
    data.google_place_id,
    data.google_review_link,
    data.facebook_business_id,
    data.stripe_account_id,
    data.short_review_link,
    data.auto_trader_id,
    data.keyloop
      ? {
          enterpriseId: data.keyloop.enterprise_id,
          storeId: data.keyloop.store_id,
        }
      : null,
    data.logo || null
  );

const merchantChannelTransformFromDtoToDomain = (
  dto: ChannelsDto
): ChannelDomain =>
  new ChannelDomain(
    dto.id,
    dto.channel_name,
    dto.identifier,
    dto.country_code,
    dto.custom_webhook_url
  );

const bankDetailsTransformFromDtoToDomain = (
  dto: BankDetailsType
): BankAccountDetailsDomain =>
  new BankAccountDetailsDomain(
    dto.account_number,
    dto.sort_code,
    dto.bic,
    dto.iban
  );

const bankAccountTransformFromDtoToDomain = (
  dto: BankAccountType
): BankAccountDomain =>
  new BankAccountDomain(
    dto.country,
    dto.beneficiary_name,
    bankDetailsTransformFromDtoToDomain(dto.details)
  );

const integrationsTransformDtoToDomain = (
  dto: IntegrationsDto
): MerchantIntegrations => {
  const integrations: MerchantIntegrations = {
    google: new MerchantIntegration(
      "google",
      dto.google.connected || dto.google.reviews.connected
    ),
    outlook: new MerchantIntegration(
      "outlook",
      dto.microsoft.outlook.connected
    ),
    gmail: new MerchantIntegration("gmail", dto.google.gmail.connected),
    stripe: new MerchantIntegration("stripe", dto.stripe.connected),
    quickbooks: new MerchantIntegration("quickbooks", dto.quickbooks.connected),
    xero: new MerchantIntegration("xero", dto.xero.connected),
    facebook: new MerchantIntegration(
      "facebook",
      dto.facebook.reviews.connected
    ),
    messenger: new MerchantIntegration(
      "messenger",
      dto.facebook.messenger.connected
    ),
    instagram: new MerchantIntegration(
      "instagram",
      dto.facebook.instagram.connected
    ),
    autotrader: new MerchantIntegration("autotrader", dto.autotrader.connected),
    keyloop: new MerchantIntegration("keyloop", dto.keyloop.connected),
    zapier: new MerchantIntegration("zapier", true),
    whatsapp: new MerchantIntegration(
      "whatsapp",
      dto.facebook.whatsapp.connected,
      dto.facebook.whatsapp.account_info_enabled
    ),
  };

  return integrations;
};

export const merchantTransformFromDtoToBaseDomain = (
  merch: MerchantDto
): MerchantDomainBase => {
  const transformedChannels = merch.channels.map(
    merchantChannelTransformFromDtoToDomain
  );

  const uniqueChannels = Object.values(
    transformedChannels.reduce(
      (uniqueMapOfChannels, currentValue: ChannelDomain) => {
        uniqueMapOfChannels[currentValue.id] = currentValue;

        return uniqueMapOfChannels;
      },
      {} as { [key: string]: ChannelDomain }
    )
  );

  return new MerchantDomainBase(
    merch.id,
    merch.public_id,
    merch.name,
    merch.registered_company_id,
    merch.subaccount_sid,
    externalMetadataTransformerFromDtoToDomain(merch.external_metadata),
    uniqueChannels,
    merch.has_stripe_account,
    merch.has_invoice_account,
    merch.has_open_banking,
    merch.has_reviews_enabled,
    integrationsTransformDtoToDomain(merch.integrations),
    merch.country,
    merch.user_guides,
    merch.description || null,
    merch.tier ? (merch.tier as TierType) : null,
    merch.strictness ? (merch.strictness as StrictnessType) : null,
    merch.limits
      ? new MerchantTierLimitsDomain(
          merch.limits.monthly_outgoing_sms_messages,
          merch.limits.monthly_campaign_messages
        )
      : null,
    merch.integrations.keyloop.privacy_statement_id
  );
};

export const adminMerchantTransformerFromDtoToDomain = (
  merch: AdminMerchantDto
): AdminMerchantDomain =>
  new AdminMerchantDomain(
    merch.id,
    merch.public_id,
    merch.name,
    merch.registered_company_id,
    merch.subaccount_sid,
    externalMetadataTransformerFromDtoToDomain(merch.external_metadata),
    merch.channels.map(merchantChannelTransformFromDtoToDomain),
    merch.has_stripe_account,
    merch.has_invoice_account,
    merch.has_open_banking,
    merch.has_reviews_enabled,
    merch.country,
    merch.user_guides,
    merch.description || null,
    merch.tier ? (merch.tier as TierType) : null,
    merch.strictness ? (merch.strictness as StrictnessType) : null,
    merch.limits
      ? new MerchantTierLimitsDomain(
          merch.limits.monthly_outgoing_sms_messages,
          merch.limits.monthly_campaign_messages
        )
      : null,
    merch.bank_account
      ? bankAccountTransformFromDtoToDomain(merch.bank_account)
      : undefined,
    merch.logo || null
  );

export const merchantUsageTransformerDtoToDomain = (
  usage: MerchantUsageDto
): MerchantUsageDomain =>
  new MerchantUsageDomain(
    usage.monthly_outgoing_sms_messages,
    usage.monthly_campaign_messages
  );
