import { Flex, Icon } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import { ReactComponent as FuzeyTarget } from "assets/icons/fuzey-target.svg";
import React from "react";
import {
  canManageCampaigns,
  canViewAgentsAnalytics,
  canViewPayments,
} from "util/permissions";
import { isFuzeyAndCoMerchant } from "util/methods";
import {
  AnalyticsLink,
  CalendarLink,
  CampaignsLink,
  ContactLink,
  InboxLink,
  PaymentsLink,
  ReviewsLink,
  SettingsLink,
} from "./DesktopNavButton";
import ProfileSettings from "./ProfileSettings";

const DekstopBrowserNavigationBar = () => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      h="100%"
      minHeight="100%"
      width="5rem"
      overflowX="hidden"
      py="1rem"
      bgColor={`${colorScheme}.900`}
      overflowY="auto"
    >
      <Flex
        direction="column"
        justifyContent="start"
        alignItems="center"
        gridGap={2}
      >
        <Icon
          as={FuzeyTarget}
          width="3rem"
          height="3rem"
          mb={4}
          __css={{
            path: {
              fill: "white",
            },
          }}
        />
        <InboxLink />
        <ContactLink />
        {!canManageCampaigns(merchant.id, currentAgent!) ? null : (
          <CampaignsLink />
        )}
        <CalendarLink />
        {!canViewPayments(merchant.id, currentAgent!) ? null : <PaymentsLink />}
        <ReviewsLink />
        {canViewAgentsAnalytics(merchant.id, currentAgent!) ? (
          <AnalyticsLink />
        ) : null}
        <SettingsLink />
      </Flex>
      <Flex m={4} pb={6}>
        <ProfileSettings />
      </Flex>
    </Flex>
  );
};

export default DekstopBrowserNavigationBar;
