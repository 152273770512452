import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import AgentsService from "services/agents";
import SwitchAccount from "components/switch-account";
import MerchantService from "services/merchant";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setCurrentAgent } from "redux/features/agents";
import { updateMerchantSuccess } from "redux/features/merchant";
import TagsService from "services/tags";
import { setTags } from "redux/features/tags";
import PrivateRoute from "./PrivateRoute";

const MerchantPrivateRoute = () => {
  const Url = useLocation();
  const { merchantId } = useParams();
  const dispatch = useAppDispatch();

  const auth0Context = useAuth0();
  const { isAuthenticated } = auth0Context;
  const { currentAgent } = useAppSelector((state) => state.agents);

  const [showMerchantSelection, setShowMerchantSelection] =
    useState<boolean>(false);

  const getCurrentMerchant = async (
    id: number
  ): Promise<MerchantDomainBase | null> => {
    try {
      const merchantResponse = await MerchantService.getMerchant(
        auth0Context,
        id
      );

      return merchantResponse;
    } catch (err) {
      // eslint-disable-next-line
      console.error("Error fetching merchant", err);
    }

    return null;
  };

  useEffect(() => {
    if (Url.pathname.includes("public")) {
      return;
    }

    AgentsService.getCurrentAgent(auth0Context).then((agent) => {
      if (!agent) {
        return;
      }

      dispatch(setCurrentAgent(agent));

      const availableMerchants = agent.merchants.map((m) => m.id);
      const selectedMerchantId = localStorage.getItem("fuzey:merchant:id");

      const merchantFromUrlAvailable = merchantId
        ? availableMerchants.includes(parseInt(merchantId, 10))
        : false;
      const merchantFromLocalStorageAvailable = selectedMerchantId
        ? availableMerchants.includes(parseInt(selectedMerchantId, 10))
        : false;

      if (merchantFromUrlAvailable) {
        TagsService.getTags(auth0Context, parseInt(merchantId!, 10)).then(
          (tags) => {
            dispatch(setTags(tags));
          }
        );

        getCurrentMerchant(parseInt(merchantId!, 10)).then(
          (merchantResponse) => {
            if (merchantResponse) {
              dispatch(updateMerchantSuccess(merchantResponse));
              localStorage.setItem("fuzey:merchant:id", merchantId!);
            } else {
              auth0Context.logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            }
          }
        );

        return;
      } else {
        if (merchantFromLocalStorageAvailable) {
          window.location.href = `/${selectedMerchantId}${Url.pathname}${Url.search}`;

          return;
        }

        if (agent.merchants.length === 1) {
          const merchant = agent.merchants[0];
          localStorage.setItem("fuzey:merchant:id", merchant.id.toString());
          window.location.href = `/${merchant.id}${Url.pathname}${Url.search}`;

          return;
        }

        setShowMerchantSelection(true);

        return;
      }
    });
  }, [isAuthenticated]);

  return (
    <>
      {showMerchantSelection ? null : (
        <PrivateRoute>
          <Outlet />
        </PrivateRoute>
      )}
      <SwitchAccount
        isRequired={true}
        isOpen={showMerchantSelection}
        setIsOpen={setShowMerchantSelection}
        merchants={currentAgent?.merchants || []}
      />
    </>
  );
};

export default MerchantPrivateRoute;
