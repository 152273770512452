import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import { ReactComponent as AccountSwitchIcon } from "assets/icons/account-switch-outline.svg";
import { ReactComponent as BellIcon } from "assets/icons/bell-solid-badged.svg";
import ProfileAvatar from "components/profile/profile-avatar";
import StylePreferences from "components/style-preferences";
import SwitchAccount from "components/switch-account";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { isMobileApp } from "util/methods";
import useNotificationsStore from "hooks/use-notifications-store";
import { Color } from "theme/old-design-system/styled-components";
import { MdPassword } from "react-icons/md";
import NotificationsModal from "./NotificationsModal";
import PasswordChangeModal from "./PasswordChangeModal";

interface ProfileSettingsProps {
  avatarOnly?: boolean;
}

const ProfileSettings = ({ avatarOnly = false }: ProfileSettingsProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { isAuthenticated, logout } = useAuth0();
  const { fetchNotifications } = useNotificationsStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const { loading: isLoadingMerchant } = useAppSelector(
    (state) => state.merchant
  );
  const { loading: isLoadingNotifications, notifications } = useAppSelector(
    (state) => state.notifications
  );
  const passwordChangeModal = useDisclosure();
  const notificationsModal = useDisclosure();

  useEffect(() => {
    if (loading && !isLoadingMerchant && !isLoadingNotifications) {
      setLoading(false);
    }

    if (isLoadingNotifications && !isLoadingMerchant) {
      setLoading(true);
    }
  }, [isLoadingMerchant, isLoadingNotifications]);

  useEffect(() => {
    setUnreadCount(
      notifications.filter((notification) => !notification.read).length
    );
  }, [notifications]);

  useEffect(() => {
    fetchNotifications();
  }, [isLoadingMerchant]);

  const [isStyleModalOpen, setIsStyleModalOpen] = useState<boolean>(false);
  const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] =
    useState<boolean>(false);

  const hasMultipleMerchants = currentAgent!.merchants.length > 1;

  return (
    <>
      <Menu closeOnBlur={true} autoSelect={false} strategy="absolute">
        <MenuButton>
          <Flex alignItems="center" direction="column" cursor="pointer">
            <Box position="relative" display="inline-block">
              <ProfileAvatar
                profilePicture={currentAgent!.getPicture()}
                name={currentAgent!.getFullName()}
              />
              {unreadCount > 0 && (
                <Badge
                  position="absolute"
                  top="-1px"
                  right="-1px"
                  transform="translate(50%, -50%)"
                  borderRadius="full"
                  width="1.5em"
                  height="1.5em"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="xs"
                  lineHeight={5}
                  background={
                    colorMode === "dark"
                      ? `${Color.RED.value}.400`
                      : `${Color.RED.value}.700`
                  }
                  color="white"
                  textAlign="center"
                >
                  {unreadCount}
                </Badge>
              )}
            </Box>

            {!avatarOnly ? (
              <>
                <Text
                  mt={2}
                  as="p"
                  textTransform="capitalize"
                  wordBreak="break-all"
                  color="white"
                >
                  {currentAgent ? currentAgent.name : null}
                </Text>
                <Text fontSize="xs" fontWeight="light" color="white">
                  {merchant.name}
                </Text>
              </>
            ) : null}
          </Flex>
        </MenuButton>
        <MenuList zIndex={2}>
          <VStack my={2} alignItems="start">
            <Text
              pl={4}
              fontWeight="bold"
              color={colorMode === "dark" ? "gray.500" : "gray.200"}
            >
              {currentAgent ? currentAgent.getFullName() : null}
            </Text>
            <Text pl={4} color={colorMode === "dark" ? "gray.500" : "gray.200"}>
              {currentAgent ? currentAgent.email : null}
            </Text>
            {hasMultipleMerchants ? (
              <MenuItem
                icon={
                  <Icon
                    width="1.5rem"
                    height="1.5rem"
                    as={AccountSwitchIcon}
                    fill={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.600`
                    }
                  />
                }
                onClick={() => {
                  setIsSwitchAccountModalOpen(true);
                }}
              >
                Switch Account
              </MenuItem>
            ) : null}
            <MenuItem
              icon={
                <Icon
                  width="1.5rem"
                  height="1.5rem"
                  as={BellIcon}
                  fill={
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`
                  }
                />
              }
              onClick={notificationsModal.onOpen}
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Text>Notifications</Text>
                {unreadCount > 0 && (
                  <Badge
                    ml="2"
                    background={
                      colorMode === "dark"
                        ? `${colorScheme}.400`
                        : `${colorScheme}.700`
                    }
                    color="white"
                    textAlign="center"
                    borderRadius="full"
                    width="1.5em"
                    height="1.5em"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {unreadCount}
                  </Badge>
                )}
              </Flex>
            </MenuItem>
            <MenuItem
              icon={
                <Icon
                  width="1.5rem"
                  height="1.5rem"
                  as={Brush}
                  fill={
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`
                  }
                />
              }
              onClick={() => {
                setIsStyleModalOpen(true);
              }}
            >
              Style
            </MenuItem>
            <MenuItem
              icon={
                <Icon
                  width="1.5rem"
                  height="1.5rem"
                  as={QuestionOutlineIcon}
                  __css={{
                    g: {
                      stroke:
                        colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.600`,
                    },
                  }}
                />
              }
              onClick={() => {
                const faqUrl = "https://www.getfuzey.com/resources/faq";

                if (isMobileApp()) {
                  window.natively.openExternalURL(faqUrl);
                } else {
                  window.open(faqUrl, "_blank");
                }
              }}
            >
              FAQ
            </MenuItem>
            <MenuItem
              icon={
                <Icon
                  width="1.5rem"
                  height="1.5rem"
                  as={MdPassword}
                  color={
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`
                  }
                />
              }
              onClick={() => {
                passwordChangeModal.onOpen();
              }}
            >
              Change Password
            </MenuItem>
            {isAuthenticated ? (
              <MenuItem
                icon={
                  <Icon
                    width="1.5rem"
                    height="1.5rem"
                    as={FiLogOut}
                    color={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.600`
                    }
                    __css={{
                      path: {
                        fill: "transparent",
                      },
                    }}
                  />
                }
                onClick={() => {
                  logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  });
                }}
              >
                Log out
              </MenuItem>
            ) : null}
          </VStack>
        </MenuList>
      </Menu>
      <StylePreferences
        isOpen={isStyleModalOpen}
        setIsOpen={setIsStyleModalOpen}
      />
      {currentAgent!.merchants.length > 1 ? (
        <SwitchAccount
          isOpen={isSwitchAccountModalOpen}
          setIsOpen={setIsSwitchAccountModalOpen}
          merchants={currentAgent!.merchants}
        />
      ) : null}
      <NotificationsModal
        isOpen={notificationsModal.isOpen}
        onClose={notificationsModal.onClose}
      />
      <PasswordChangeModal
        isOpen={passwordChangeModal.isOpen}
        onClose={passwordChangeModal.onClose}
      />
    </>
  );
};

export default ProfileSettings;
