import {
  Accordion,
  Icon,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Text,
  Flex,
  Heading,
  IconButton,
  Image,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { integrationOption } from "components/user-settings/Integrations/ConnectIntegrationTypes";
import StepsContent from "components/user-settings/Channels/items/extended-setup/StepsContent";
import { SocketMessage, SocketOnboardingAction } from "entities/ISocketArgs";
import UserGuideActionsDomain from "entities/domain/userGuides/user-guide-actions";
import { UserGuideStatus } from "entities/domain/userGuides/user-guides";

import { useAppSelector } from "redux/hooks";
import { useWebSocket } from "hooks/use-socket";
import {
  collapseAccordionItem,
  isAccordionItemExpanded,
} from "./AccordionHelpers";
import {
  Pre360DialogRedirect,
  Post360DialogRedirect,
} from "./360DialogComponents";

interface StepTwoProps {
  actions?: UserGuideActionsDomain[];
}

interface Channel {
  name: string;
  icon: ReactNode;
  isCompleted: boolean;
  setupDetails: any;
}

const StepTwo: React.FC<StepTwoProps> = ({ actions }) => {
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const toast = useToast();

  const [connectButtonClicked, setConnectButtonClicked] =
    useState<boolean>(false);

  const defaultChannels: Channel[] = [
    {
      name: "WhatsApp",
      icon: <Icon as={WhatsappIcon} />,
      isCompleted: false,
      setupDetails: {
        title: "Connect WhatsApp",
        body: "",
        connectButton: (
          <Pre360DialogRedirect setConsentGiven={setConnectButtonClicked} />
        ),
      },
    },
    {
      name: "Messenger",
      icon: <Icon as={MessengerIcon} />,
      isCompleted: false,
      setupDetails: integrationOption.MESSENGER(colorScheme, merchant.id)
        .connect,
    },
    {
      name: "Instagram",
      icon: <Icon as={InstagramIcon} />,
      isCompleted: false,
      setupDetails: integrationOption.INSTAGRAM(colorScheme, merchant.id)
        .extendedConnect,
    },
  ];

  const [channels, setChannels] = useState(defaultChannels);
  const [expandedIndices, setExpandedIndices] = useState<number | number[]>([]);

  useEffect(() => {
    const actionByName = actions?.reduce((map, action) => {
      return { ...map, [action.name]: action };
    }, {} as { [key: string]: UserGuideActionsDomain });

    const newChannels = channels.map((channel) => {
      if (channel.name === "WhatsApp") {
        return {
          ...channel,
          isCompleted:
            actionByName?.connect_whatsapp?.status ===
            UserGuideStatus.COMPLETED,
        };
      }

      if (channel.name === "Messenger") {
        return {
          ...channel,
          isCompleted:
            actionByName?.connect_messenger?.status ===
            UserGuideStatus.COMPLETED,
        };
      }

      if (channel.name === "Instagram") {
        return {
          ...channel,
          isCompleted:
            actionByName?.connect_instagram?.status ===
            UserGuideStatus.COMPLETED,
        };
      }
      return channel;
    });
    return setChannels(newChannels);
  }, [actions]);

  const [socialChannelEvent, setSocialChannelEvent] = useState(
    {} as SocketOnboardingAction
  );

  const [newMessage, setNewMessage] = useState<string | undefined>();
  const [newMessageEvent, setNewMessageEvent] = useState<
    SocketMessage | undefined
  >();

  const handleInboundMessage = (args: SocketMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    if (args.message) {
      setNewMessageEvent(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
      toast({
        status: "error",
        title: "Couldn't receive a message.",
        description: "Feel free to skip this step for now then.",
      });
    }
  };

  useEffect(() => {
    addEventHandler("inbound_message", handleInboundMessage);
    addEventHandler("user_guide_action_completed", handleGuideActionCompleted);

    return () => {
      removeEventHandler("inbound_message", handleInboundMessage);
      removeEventHandler(
        "user_guide_action_completed",
        handleGuideActionCompleted
      );
    };
  }, [addEventHandler, removeEventHandler]);

  useEffect(() => {
    if (!newMessageEvent) {
      return;
    }

    if (newMessageEvent.merchantId && newMessageEvent.message) {
      if (newMessageEvent.message.is_incoming && !newMessage) {
        setNewMessage(newMessageEvent.message.body);
      }
    }
  }, [newMessageEvent]);

  const handleGuideActionCompleted = (args: SocketOnboardingAction) => {
    const { guide_name: guideName, action_name: actionName } = args;

    const isOnboarding = guideName === "onboarding";
    const isWhatsappAction = actionName === "connect_whatsapp";
    const isFacebookAction = actionName === "connect_messenger";
    const isInstagramAction = actionName === "connect_instagram";
    const isSocialGroupAction =
      isWhatsappAction || isFacebookAction || isInstagramAction;

    if (isOnboarding && isSocialGroupAction) {
      setSocialChannelEvent(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
    }
  };

  const handleSocialSWS = (socialChannelEv: SocketOnboardingAction) => {
    const isWhatsappAction = socialChannelEv.action_name === "connect_whatsapp";
    const isFacebookAction =
      socialChannelEv.action_name === "connect_messenger";
    const isInstagramAction =
      socialChannelEv.action_name === "connect_instagram";

    const newChannels = channels.map((channel) => {
      if (isWhatsappAction && channel.name === "WhatsApp") {
        if (isAccordionItemExpanded(0, expandedIndices)) {
          collapseAccordionItem(0, expandedIndices, setExpandedIndices);
        }
        return {
          ...channel,
          isCompleted: true,
        };
      }
      if (isFacebookAction && channel.name === "Messenger") {
        if (isAccordionItemExpanded(1, expandedIndices)) {
          collapseAccordionItem(1, expandedIndices, setExpandedIndices);
        }
        return {
          ...channel,
          isCompleted: true,
        };
      }
      if (isInstagramAction && channel.name === "Instagram") {
        if (isAccordionItemExpanded(2, expandedIndices)) {
          collapseAccordionItem(2, expandedIndices, setExpandedIndices);
        }
        return {
          ...channel,
          isCompleted: true,
        };
      }
      return channel;
    });
    setChannels(newChannels);
  };

  useEffect(() => {
    if (Object.keys(socialChannelEvent).length > 0) {
      handleSocialSWS(socialChannelEvent);
    }
  }, [socialChannelEvent]);

  const getBadge = (channel: Channel, isExpanded: boolean) => {
    if (channel.isCompleted) {
      return <Badge colorScheme="green">Connected</Badge>;
    }

    if (isExpanded) {
      return <Badge colorScheme={colorScheme}>Click to collapse</Badge>;
    }

    return <Badge colorScheme="purple">Click to set up</Badge>;
  };

  const renderConnectButton = (channel: Channel) =>
    channel.setupDetails?.connectButton ? (
      channel.setupDetails.connectButton
    ) : (
      <IconButton
        alignSelf="end"
        colorScheme={colorScheme}
        onClick={() => {
          setConnectButtonClicked(true);
          channel.setupDetails?.openInNewTab();
        }}
        aria-label={`Connect with ${channel.name}`}
      >
        <Image height="2rem" src={channel.setupDetails?.buttonImage} />
      </IconButton>
    );

  return (
    <Accordion
      allowToggle
      borderColor="gray.50"
      index={expandedIndices}
      onChange={(indices) => {
        setConnectButtonClicked(false);
        setExpandedIndices(indices);
      }}
    >
      {channels.map((channel) => (
        <AccordionItem isDisabled={channel.isCompleted}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  opacity="1!important"
                  bg={
                    channel.isCompleted
                      ? colorMode === "dark"
                        ? "green.800"
                        : "green.50"
                      : "inherit"
                  }
                  _hover={{
                    bg: channel.isCompleted
                      ? colorMode === "dark"
                        ? "green.700"
                        : "green.50"
                      : colorMode === "dark"
                      ? "gray.800"
                      : "gray.50",
                  }}
                  _expanded={{
                    bg:
                      colorMode === "dark"
                        ? `${colorScheme}.800`
                        : `${colorScheme}.50`,
                    borderBottomLeftRadius: "1rem",
                    borderBottomRightRadius: "1rem",
                  }}
                >
                  <Flex
                    flex="1"
                    textAlign="left"
                    gridGap={2}
                    alignItems="center"
                  >
                    {channel.icon}
                    <Text>Connect {channel.name}</Text>
                  </Flex>
                  {getBadge(channel, isExpanded)}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Heading>{channel.setupDetails?.title}</Heading>
                {channel.setupDetails?.steps ? (
                  <StepsContent
                    steps={channel.setupDetails.steps}
                    openInNewTab={true}
                    showActionsBorder={true}
                    nextButtonText="Next"
                    previousButtonText="Previous"
                    imgHeight="2rem"
                  />
                ) : (
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    my={4}
                    gridGap={4}
                  >
                    <Text>{channel.setupDetails?.body}</Text>
                    {channel.name === "WhatsApp" && connectButtonClicked && (
                      <Post360DialogRedirect />
                    )}
                    {!connectButtonClicked && renderConnectButton(channel)}
                  </Flex>
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default StepTwo;
