import { useAuth0 } from "@auth0/auth0-react";
import { Box, useBreakpointValue, useToast } from "@chakra-ui/react";
import MergeContact from "components/modals/merge-contact/MergeContact";
import UpdateTags from "components/modals/tags/UpdateTags";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import InboxService from "services/inbox";
import {
  ContactsSorting,
  GetContactsFilter,
  GetContactsSorting,
} from "util/ContactsFilter";
import ContactsService from "services/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  cleanContactsToastMessages,
  setShouldUseCriteriaForSearch,
} from "redux/features/contacts";
import useDebounce from "hooks/use-debounce";
import ContactsListTable from "./ContactsListTable";
import SkeletonOverlay from "./SkeletonOverlay";

type ContactListProps = {
  audience: ContactDomain[] | null;
  setDisplayBackIcon: (val: boolean) => void;
  setDisplayMoreIcon: (val: boolean) => void;
  contactToDisplay: ContactDomain | undefined;
  setSorting: (sorting: ContactsSorting | undefined) => void;
  handleOpenContactDisplay: (contact: ContactDomain) => void;
  handleCloseContactDisplay: () => void;
  setOpenEditTags: (val: boolean) => void;
  openEditTags: boolean;
  filter?: {
    name?: string;
    channels?: string[];
    tagIds?: string[];
  };
  sorting?: Sorting;
};

type Sorting = {
  field: "name" | "customer_last_contact";
  direction: "asc" | "desc";
};

const parseSorting = (sorting?: Sorting) => {
  let contactsSorting = new GetContactsSorting([
    { name: "asc", surname: "asc" },
  ]);

  if (sorting) {
    if (sorting.field === "name") {
      contactsSorting = new GetContactsSorting([
        { name: sorting.direction, surname: sorting.direction },
      ]);
    } else if (sorting.field === "customer_last_contact") {
      contactsSorting = new GetContactsSorting([
        { customer_last_contact: sorting.direction },
      ]);
    }
  }

  return contactsSorting;
};

const ContactList = ({
  audience = null,
  filter,
  sorting,
  setSorting,
  setDisplayBackIcon,
  setDisplayMoreIcon,
  contactToDisplay,
  handleOpenContactDisplay,
  handleCloseContactDisplay,
  setOpenEditTags,
  openEditTags,
}: ContactListProps) => {
  const { deleteContact, updateContactTags, setContacts } = useContactsStore();
  const { contacts, toastMessage, shouldUseCriteriaForSearch, advancedFilter } =
    useAppSelector((state) => state.contacts);
  const { tags: allTags } = useAppSelector((state) => state.tags);
  const navigate = useNavigate();
  const { merchant } = useAppSelector((state) => state.merchant);
  const toast = useToast();
  const dispatch = useAppDispatch();

  const auth0Context = useAuth0();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const debouncedIsInitialLoading = useDebounce(isInitialLoading, 50);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openMergeForm, setOpenMergeForm] = useState<boolean>(false);
  const [contactToMerge, setContactToMerge] = useState<ContactDomain>();
  const [selectedContactId, setSelectedContactId] = useState<
    number | undefined
  >(undefined);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [tagIdToDelete, setTagIdToDelete] = useState<string>("");

  const numberOfContactsToLoad = 100;

  useEffect(() => {
    setDisplayBackIcon(false);
    setDisplayMoreIcon(false);
  }, [filter, sorting]);

  // remove this shit
  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast({ status: "success", title: toastMessage.success });
      } else if (toastMessage.errors) {
        toast({ status: "error", title: toastMessage.errors[0] });
      }
      dispatch(cleanContactsToastMessages());
    }
  }, [toastMessage]);

  useEffect(() => {
    if (tagIdToDelete)
      setSelectedTagIds(selectedTagIds.filter((tId) => tId !== tagIdToDelete));
  }, [tagIdToDelete]);

  useEffect(() => {
    if (!contactToDisplay && !selectedContactId) {
      setSelectedTagIds([]);
      return;
    }

    if (selectedContactId) {
      const selectedContact = contacts.find((c) => c.id === selectedContactId);

      if (!selectedContact) {
        return;
      }

      setSelectedTagIds(selectedContact.tagIds);
    } else if (contactToDisplay) {
      setSelectedTagIds(contactToDisplay.tagIds);
    }
  }, [contactToDisplay, selectedContactId]);

  const showContactList = (): boolean => {
    if (!isBaseSize) return true;

    if (isBaseSize && !contactToDisplay) return true;

    return false;
  };

  const [shouldShowContactList, setShouldShowContactList] = useState<boolean>(
    showContactList()
  );

  useEffect(() => {
    setShouldShowContactList(showContactList());
  }, [isBaseSize, contactToDisplay]);

  const handleDeleteContact = (contactId: number) => {
    deleteContact(contactId);
  };

  const handleOpenMergeModal = (selectedContact: ContactDomain) => {
    setContactToMerge(selectedContact);
    setOpenMergeForm(true);
  };

  const handleCloseMergeModal = (
    updatedContactResponse: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      handleOpenContactDisplay(updatedContactResponse);
    }
    setContactToMerge(undefined);
    setOpenMergeForm(false);
  };

  const PostConversationWithChanId = async (chanId: string) => {
    const result = await InboxService.createConversationWithChannelId(
      auth0Context,
      chanId,
      merchant.id
    );

    return result;
  };

  const handleCreate = async (chanId: string) => {
    const newConversation = await PostConversationWithChanId(chanId);
    navigate(`/${merchant.id}/inbox/${newConversation.id}`);
  };

  const handleOpenEditTags = useCallback((contact: ContactDomain) => {
    setOpenEditTags(true);
    setSelectedContactId(contact.id);
  }, []);

  const handleCloseEditTags = () => {
    setSelectedContactId(undefined);
    setOpenEditTags(false);
  };

  const addTagsToContact = async (tagIds: string[]) => {
    if (!selectedContactId) {
      toast({
        status: "error",
        title: "Can't add tags to contact without an id",
      });

      return;
    }

    try {
      const selectedTags = allTags.filter((tag) => tagIds.includes(tag.id));
      const tags =
        (await updateContactTags(selectedTags, selectedContactId)) || [];
      if (contactToDisplay && contactToDisplay.id === selectedContactId) {
        handleOpenContactDisplay(
          Object.setPrototypeOf(
            {
              ...contactToDisplay,
              tagIds: tags.map((tag) => tag.id),
            },
            ContactDomain.prototype
          )
        );
      }
    } catch (error: unknown) {
      toast({
        status: "error",
        title: "Failed to update tags. Please try again.",
      });
    } finally {
      handleCloseEditTags();
    }
  };

  const hasMoreContacts = (
    contactsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (contactsAmount < numberOfContactsToLoad) {
      return false;
    }

    if (!currentPageAmount) {
      return false;
    }

    return true;
  };

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const fetchMoreContacts = useCallback(
    async (forceRefresh: boolean = false) => {
      const name = filter?.name;
      const channels = filter?.channels ? filter?.channels : undefined;
      const tagIds = filter?.tagIds ? filter?.tagIds : undefined;
      const contactsFilter = new GetContactsFilter({ name, channels, tagIds });
      const contactsSorting = parseSorting(sorting);
      const offset = forceRefresh ? 0 : contacts.length;

      setIsLoading(true);

      const nextPage =
        (
          await ContactsService.getAllContacts(
            auth0Context,
            offset,
            merchant.id,
            contactsFilter,
            contactsSorting,
            shouldUseCriteriaForSearch
              ? advancedFilter || undefined
              : undefined,
            numberOfContactsToLoad
          )
        ).contacts || [];

      setIsInitialLoading(false);
      setIsLoading(false);

      if (forceRefresh) {
        setContacts(nextPage);
        setHasNextPage(hasMoreContacts(nextPage.length, nextPage?.length || 0));
        return;
      }

      const newContacts: ContactDomain[] = contacts?.length
        ? contacts.concat(nextPage)
        : nextPage;

      setContacts(newContacts);
      setHasNextPage(
        hasMoreContacts(newContacts.length, nextPage?.length || 0)
      );
    },
    [
      contacts,
      filter,
      sorting,
      isInitialLoading,
      shouldUseCriteriaForSearch,
      advancedFilter,
    ]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setContacts([]);
    fetchMoreContacts(true);
  }, [
    filter?.name,
    filter?.channels,
    filter?.tagIds,
    sorting?.field,
    sorting?.direction,
    shouldUseCriteriaForSearch,
    advancedFilter,
  ]);

  return (
    <>
      <Box
        id="contacts_scrollable-div"
        display="flex"
        flexDirection="column"
        height="100%"
      >
        {contacts.length && shouldShowContactList ? (
          <Box height="100%" width="100%" position="relative">
            {debouncedIsInitialLoading && <SkeletonOverlay />}
            <ContactsListTable
              fetchMore={fetchMoreContacts}
              sorting={sorting}
              setSorting={setSorting}
              hasNextPage={hasNextPage}
              handleDeleteContact={handleDeleteContact}
              contacts={(!!audience && audience) || contacts}
              openContactDetails={handleOpenContactDisplay}
              handleOpenEditTags={handleOpenEditTags}
            />
          </Box>
        ) : null}
        {contactToDisplay && (
          <Outlet
            context={{
              showDeleteButton: true,
              contactToDisplay,
              onClose: handleCloseContactDisplay,
              onChannelClick: handleCreate,
              handleCloseContactDisplay,
              displayPopover: true,
              handleOpenMergeModal,
              setDisplayBackIcon,
              setDisplayMoreIcon,
              onCreateNewTag: () => handleOpenEditTags(contactToDisplay),
              setTagIdToBeDeleted: setTagIdToDelete,
            }}
          />
        )}
      </Box>

      {contactToMerge && (
        <MergeContact
          contactToMerge={contactToMerge}
          isOpen={openMergeForm}
          onClose={handleCloseMergeModal}
        />
      )}

      {selectedContactId && (
        <UpdateTags
          isOpen={openEditTags}
          onClose={handleCloseEditTags}
          tagIds={selectedTagIds}
          onSubmit={addTagsToContact}
        />
      )}
    </>
  );
};

export default ContactList;
