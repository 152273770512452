import {
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import PhoneInput from "components/contact-form/PhoneInput";
import { MultipleWarnings } from "components/shared/WarningTextComponent";
import FuzeyDropdown from "components/shared/dropdown";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import CustomerChannelDomain, {
  MarketingStatus,
} from "entities/domain/customers/contact-channel-domain";
import { useAppSelector } from "redux/hooks";
import React, { ChangeEvent, useEffect, useState } from "react";

interface ChannelFormProps {
  isNew: boolean;
  existingChannels: CustomerChannelDomain[];
  onCancel: () => void;
  warnings?: MultipleWarnings;
  channel?: CustomerChannelDomain;
  enabledChannelTypes: ConversationChannel[];
  setChannel: (updatedChannel: CustomerChannelDomain) => void;
}

interface ChannelHandleByTypeProps {
  isActive: boolean;
  type: string;
  handle: string;
  autoSuggestion: string | undefined;
  warnings?: MultipleWarnings;
  setHandle: (updatedHandle: string) => void;
}

const ChannelHandleByType = ({
  isActive,
  autoSuggestion,
  type,
  handle,
  warnings,
  setHandle,
}: ChannelHandleByTypeProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);

  useEffect(() => {
    if (autoSuggestion && !handle) {
      setHandle(autoSuggestion);
    }
  }, [autoSuggestion]);

  if (type === "email") {
    return (
      <Input
        isDisabled={!isActive}
        colorScheme={colorScheme}
        data-testid="contact-page-email-input"
        borderRadius="full"
        placeholder="Email address"
        value={handle}
        borderColor={
          warnings?.invalid_email || !handle
            ? colorMode === "dark"
              ? "pink.200"
              : "pink.500"
            : colorMode === "dark"
            ? `${colorScheme}.200`
            : `${colorScheme}.500`
        }
        onChange={(e) => {
          setHandle(e.target.value);
        }}
      />
    );
  }

  if (type === "sms" || type === "whatsapp") {
    return (
      <PhoneInput
        id="contact-page-phone-input"
        placeholder="Phone number"
        defaultCountry={merchant.getMerchantLocation()}
        value={handle || ""}
        label=""
        isDisabled={!isActive}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setHandle(e.target.value)
        }
        borderColor={
          warnings?.invalid_phone_number || !handle
            ? colorMode === "dark"
              ? "pink.200"
              : "pink.500"
            : colorMode === "dark"
            ? `${colorScheme}.200`
            : `${colorScheme}.500`
        }
      />
    );
  }

  return <Text>{handle}</Text>;
};

const marketingStatusOptions = [
  {
    value: MarketingStatus.ENABLED,
    label: "Subscribed",
  },
  {
    value: MarketingStatus.NOT_REQUESTED,
    label: "Not subscribed",
  },
  {
    value: MarketingStatus.DISABLED,
    label: "Unsubscribed",
  },
];

const hasMarketingStatus = (channelType: string) => {
  return channelType === "sms" || channelType === "whatsapp";
};

const ChannelForm = ({
  isNew,
  warnings,
  channel,
  existingChannels,
  enabledChannelTypes,
  setChannel,
  onCancel,
}: ChannelFormProps) => {
  if (!channel) {
    return null;
  }

  const { merchant } = useAppSelector((state) => state.merchant);

  const MARKETING_STATUS_DESCRIPTIONS: {
    [key in MarketingStatus]?: string;
  } = {
    not_requested: `By selecting "Not subscribed", this contact will not be able to receive marketing related messages from ${
      merchant.name || "you"
    }`,
    enabled: `By selecting "Subscribed", you confirm that there is lawful basis for sending marketing related messages to this contact from ${
      merchant.name || "you"
    }`,
    disabled: `By selecting "Unsubscribed", you are indicating that this contact have explicitly unsubscribed from receiving marketing related messages from ${
      merchant.name || "you"
    }`,
  };

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const channelTypeOptions: { value: string; label: string }[] = [
    { value: "sms", label: "SMS" },
    { value: "whatsapp", label: "WhatsApp" },
    { value: "email", label: "Email" },
  ].filter((option) =>
    enabledChannelTypes.includes(option.value as ConversationChannel)
  );

  const [channelToEdit, setChannelToEdit] =
    useState<CustomerChannelDomain>(channel);

  useEffect(() => {
    setChannelToEdit(channel);
  }, [channel]);

  const [autoSuggestion, setAutoSuggestion] = useState<string | undefined>();

  useEffect(() => {
    setAutoSuggestion(
      [...existingChannels].reverse().find((c) => c.type === channelToEdit.type)
        ?.displayHandle
    );
  }, [existingChannels, channelToEdit.type]);

  return (
    <VStack
      w="100%"
      spacing={4}
      p={4}
      backgroundColor={colorMode === "dark" ? "gray.700" : "gray.50"}
      borderRadius="lg"
    >
      <FormControl>
        <FuzeyDropdown
          placeholder="Choose Channel Type"
          isDisabled={!isNew}
          setSelectedValue={(channelType) => {
            setChannelToEdit(
              Object.setPrototypeOf(
                {
                  ...channelToEdit,
                  type: channelType,
                },
                CustomerChannelDomain.prototype
              )
            );
          }}
          width="100%"
          isMulti={false}
          controlShouldRenderValue={true}
          isClearable
          closeMenuOnSelect={true as ((() => void) & boolean) | undefined}
          isSetOnSelect
          borderColor={`${colorScheme}.400`}
          optionFontColor="var(--chakra-colors-gray-700)"
          options={channelTypeOptions}
          value={channelTypeOptions.filter(
            (option) => option.value === channelToEdit.type
          )}
          control={{ height: "40px" }}
          id="contact-page-channel-type-dropdown"
          zIndex={3}
        />
      </FormControl>
      {channelToEdit.type && (
        <FormControl>
          <ChannelHandleByType
            isActive={channelToEdit.isActive}
            autoSuggestion={autoSuggestion}
            warnings={warnings}
            type={channelToEdit.type}
            handle={channelToEdit.handle}
            setHandle={(updatedHandle) => {
              setChannelToEdit(
                Object.setPrototypeOf(
                  {
                    ...channelToEdit,
                    handle: updatedHandle,
                    displayHandle: updatedHandle,
                  },
                  CustomerChannelDomain.prototype
                )
              );
            }}
          />
        </FormControl>
      )}
      {hasMarketingStatus(channelToEdit.type) && (
        <FormControl>
          <FuzeyDropdown
            id={`contact-page-${channelToEdit.type}-marketing-status`}
            zIndex={3}
            isSearchable={false}
            borderColor={`${colorScheme}.400`}
            placeholder="Select marketing status"
            width="100%"
            setSelectedValue={(option: string | undefined) => {
              setChannelToEdit(
                Object.setPrototypeOf(
                  {
                    ...channelToEdit,
                    marketingStatus: option as MarketingStatus,
                  },
                  CustomerChannelDomain.prototype
                )
              );
            }}
            isSetOnSelect={true}
            isMulti={false}
            options={marketingStatusOptions}
            closeMenuOnSelect={true as ((() => void) & boolean) | undefined}
            controlShouldRenderValue={true}
            optionFontColor="inherit"
            value={marketingStatusOptions.find(
              (o) => o.value === channelToEdit.marketingStatus
            )}
          />
          {channelToEdit.marketingStatus &&
            MARKETING_STATUS_DESCRIPTIONS[channelToEdit.marketingStatus] && (
              <Text my={3} px={3} fontSize="12px">
                {MARKETING_STATUS_DESCRIPTIONS[channelToEdit.marketingStatus]}
              </Text>
            )}
        </FormControl>
      )}
      <Flex mt={4} w="100%" justifyContent="space-between" alignItems="center">
        <Button
          colorScheme={colorScheme}
          variant="ghost"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          colorScheme={colorScheme}
          onClick={() => {
            setChannel(channelToEdit);
          }}
          isDisabled={
            !channelToEdit.handle || !channelToEdit.displayHandle || false
          }
        >
          Save
        </Button>
      </Flex>
    </VStack>
  );
};

export default ChannelForm;
