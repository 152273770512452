import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect } from "react";
import { OnboardingStatus } from "redux/features/onboarding";
import { useAppSelector } from "redux/hooks";

interface StripeCallbackProps {}

const StripeCallback: React.FC<StripeCallbackProps> = () => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { status } = useAppSelector((state) => state.onboarding);

  useEffect(() => {
    if (!merchant) {
      return;
    }

    if (
      merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING) &&
      status === OnboardingStatus.IN_PROGRESS
    ) {
      localStorage.setItem(
        "callback_success",
        JSON.stringify({
          title: "Stripe was successfully connected!",
          description: "You can disconnect any time from your settings page.",
        })
      );
      window.location.assign(`/public/callback/success`);

      return;
    }

    window.location.assign(`/${merchant.id}/payments`);
  }, [merchant]);

  return <div>Loading...</div>;
};

export default StripeCallback;
