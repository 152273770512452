import {
  Avatar,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import { ReactComponent as Checkmark } from "assets/icons/Correct-ds.svg";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";

interface SwitchAccountProps {
  merchants: AgentMerchantDomain[];
  isOpen: boolean;
  isRequired?: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const sortMerchantsAlphabetically = (
  a: AgentMerchantDomain,
  b: AgentMerchantDomain
) => {
  return a.name.localeCompare(b.name);
};

const SwitchAccount = ({
  isOpen,
  isRequired = false,
  setIsOpen,
  merchants,
}: SwitchAccountProps) => {
  const { merchant: currentMerchant } = useAppSelector(
    (state) => state.merchant
  );
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [selectedMerchant, setSelectedMerchant] =
    useState<AgentMerchantDomain | null>(null);

  useEffect(() => {
    if (!currentAgent || !Object.keys(currentMerchant).length) {
      return;
    }

    setSelectedMerchant(
      new AgentMerchantDomain(
        currentMerchant.id,
        currentMerchant.name,
        currentMerchant.externalMetadata?.logo || "",
        currentAgent.getRole(currentMerchant.id)
      )
    );
  }, [currentAgent, currentMerchant]);

  if (!currentAgent || !currentMerchant) {
    return null;
  }

  return (
    <Modal
      {...(isRequired
        ? {
            closeOnEsc: false,
            closeOnOverlayClick: false,
          }
        : {})}
      isOpen={isOpen}
      size="md"
      onClose={() => {
        setSelectedMerchant(
          new AgentMerchantDomain(
            currentMerchant.id,
            currentMerchant.name,
            currentMerchant.externalMetadata?.logo || "",
            currentAgent.getRole(currentMerchant.id)
          )
        );
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Select Account</ModalHeader>
        {isRequired ? null : <ModalCloseButton />}
        <ModalBody>
          <VStack w={isBaseSize ? "100%" : "80%"} mx="auto">
            {merchants.sort(sortMerchantsAlphabetically).map((merchant) => (
              <Button
                key={merchant.id}
                w="100%"
                height="auto"
                borderRadius="lg"
                colorScheme={colorScheme}
                variant="ghost"
                isActive={
                  !!selectedMerchant && selectedMerchant.id === merchant.id
                }
                pointerEvents={
                  selectedMerchant && selectedMerchant.id === merchant.id
                    ? "none"
                    : "all"
                }
                position="relative"
                onClick={() => setSelectedMerchant(merchant)}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  w="100%"
                >
                  <Flex
                    justifyContent="start"
                    alignItems="center"
                    width="100%"
                    gridGap={2}
                  >
                    <Avatar
                      key={merchant.id}
                      size="sm"
                      name={merchant.name}
                      src={merchant.logo || undefined}
                    />
                    <Text
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                    >
                      {merchant.name}
                    </Text>
                  </Flex>
                  {selectedMerchant && selectedMerchant.id === merchant.id ? (
                    <Icon
                      as={Checkmark}
                      position="absolute"
                      right={2}
                      __css={{
                        rect: {
                          fill: "transparent",
                        },
                        path: {
                          fill:
                            colorMode === "dark" ? "green.100" : "green.500",
                        },
                      }}
                    />
                  ) : null}
                </Flex>
              </Button>
            ))}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              window.location.href = `/${selectedMerchant!.id}/inbox`;
            }}
            isDisabled={!selectedMerchant}
            colorScheme={colorScheme}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SwitchAccount;
