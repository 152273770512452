export default class KeyloopPrivacyStatement {
  id: string;

  description: string;

  channels: string[];

  dataChannels: string[];

  level: string;

  providerName: string;

  constructor(
    id: string,
    description: string,
    channels: string[],
    dataChannels: string[],
    level: string,
    providerName: string
  ) {
    this.id = id;
    this.description = description;
    this.channels = channels;
    this.dataChannels = dataChannels;
    this.level = level;
    this.providerName = providerName;
  }
}
