import { Badge, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

interface TextLimitsProps {
  maxCharacters: number;
  maxCustomFields?: number;
  text: string;
}

export const getCustomFieldsCount = (text: string) => {
  return text.match(/{(.*?)}/g)?.length || 0;
};

const TextLimits = ({
  maxCharacters,
  maxCustomFields,
  text,
}: TextLimitsProps) => {
  const [customFieldsCount, setCustomFieldsCount] = useState<number>(0);

  useEffect(() => {
    if (!maxCustomFields) {
      return;
    }

    setCustomFieldsCount(getCustomFieldsCount(text));
  }, [text]);

  return (
    <HStack position="absolute" right={0} bottom={0}>
      <Badge colorScheme="gray" variant="solid">
        {text.length}/{maxCharacters} characters
      </Badge>
      {maxCustomFields ? (
        <Badge colorScheme="gray" variant="solid">
          {customFieldsCount}/{maxCustomFields} custom fields
        </Badge>
      ) : null}
    </HStack>
  );
};

export default TextLimits;
