import { useAuth0 } from "@auth0/auth0-react";
import ContactSelectModal from "components/shared/contact-select-modal";
import ContactDomain from "entities/domain/customers/contact-domain";
import { useAppSelector } from "redux/hooks";
import useDebounce from "hooks/use-debounce";
import React, { useEffect, useState } from "react";
import ContactsService from "services/contacts";
import { GetContactsFilter } from "util/ContactsFilter";
import { numberOfContactsPerLoad } from "util/constants";

interface ContactListContentProps {
  setSelectedContactToMessage: (
    contactToMsg: ContactDomain | undefined
  ) => void;
  selectedContactToMessage?: ContactDomain;
  setStep: (step: number) => void;
  currentSearchText: string;
  setCurrentSearchText: (text: string) => void;
}

const ContactListContent = ({
  setSelectedContactToMessage,
  selectedContactToMessage,
  setStep,
  currentSearchText,
  setCurrentSearchText,
}: ContactListContentProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);

  useEffect(() => {
    setSelectedContactToMessage(undefined);
  }, []);

  const auth0Context = useAuth0();

  const [loading, setLoading] = useState<boolean>(true);
  const [fetchedContacts, setFetchedContacts] = useState<Array<ContactDomain>>(
    []
  );
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const debouncedSearchText = useDebounce<string>(currentSearchText, 800);
  const [filter, setFilter] = useState<{
    name?: string;
  }>({});

  useEffect(() => {
    if (debouncedSearchText !== filter.name) {
      setFilter({
        ...filter,
        name: debouncedSearchText,
      });
    }
  }, [debouncedSearchText]);

  const loadFirstPage = async () => {
    const name = filter?.name;
    const contactsFilter = new GetContactsFilter({ name });

    setLoading(true);

    const result = await ContactsService.getAllContacts(
      auth0Context,
      0,
      merchant.id,
      contactsFilter,
      undefined,
      undefined,
      numberOfContactsPerLoad
    );
    if (result.contacts.length < numberOfContactsPerLoad) {
      setHasNextPage(false);
    } else {
      setHasNextPage(true);
    }

    setLoading(false);

    setFetchedContacts(
      result.contacts.filter((contact) => {
        return (
          contact.channels.length > 1 ||
          merchant.isMerchantChannelEnabled(contact.channels[0]?.type)
        );
      })
    );
  };

  const fetchMoreContacts = async () => {
    const name = filter?.name;
    const contactsFilter = new GetContactsFilter({ name });
    setLoading(true);
    const result = await ContactsService.getAllContacts(
      auth0Context,
      fetchedContacts.length,
      merchant.id,
      contactsFilter,
      undefined,
      undefined,
      numberOfContactsPerLoad
    );
    setLoading(false);

    if (result.contacts.length < numberOfContactsPerLoad) {
      setHasNextPage(false);
    } else {
      setHasNextPage(true);
    }

    setFetchedContacts((prev) => [
      ...prev,
      ...result.contacts.filter((contact) => {
        return (
          contact.channels.length > 1 ||
          merchant.isMerchantChannelEnabled(contact.channels[0]?.type)
        );
      }),
    ]);
  };

  useEffect(() => {
    loadFirstPage();
  }, [filter]);

  return (
    <ContactSelectModal
      searchText={currentSearchText}
      setSearchText={setCurrentSearchText}
      setDisplayContinue={() => {}}
      unableToContinueMessage={
        debouncedSearchText ? "No existing contact found" : ""
      }
      setSelectedContact={setSelectedContactToMessage}
      selectedContact={selectedContactToMessage}
      contacts={fetchedContacts}
      loading={loading}
      hasNextPage={hasNextPage}
      loadMore={fetchMoreContacts}
    />
  );
};

export default ContactListContent;
