import {
  Box,
  Image,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { CustomFieldKey, CustomFields } from "entities/domain/templates";
import React, { useEffect, useState } from "react";
import { WhatsappButton } from "entities/domain/whatsapp-template";
import { useAppSelector } from "redux/hooks";
import ButtonsPreview from "./ButtonsPreview";
import HeaderPreview from "./HeaderPreview";

interface PreviewProps {
  headerText: string;
  bodyText: string;
  footerText: string;
  buttons?: WhatsappButton[];
  file: File | null;
  mediaUrl?: string;
  mediaType?: string;
}

const MAX_ATTACHMENT_WIDTH_MOBILE = "100px";

const replaceCustomFields = (text: string, customFields: CustomFields) => {
  return text.replace(
    /{(contact_first_name|contact_last_name|merchant_name|review_link)}/g,
    (_, field) => {
      const customFieldValue: string | undefined =
        customFields[field as CustomFieldKey];

      if (customFieldValue) {
        return customFieldValue;
      }

      return `{${field}}`;
    }
  );
};

const Preview = ({
  headerText,
  bodyText,
  footerText,
  file,
  mediaUrl,
  mediaType,
  buttons = [],
}: PreviewProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [customFieldsDefinition, setCustomFieldsDefinition] =
    useState<CustomFields>({
      contact_first_name: currentAgent?.name || "Alex",
      contact_last_name: currentAgent?.surname || "Boyce",
      merchant_name: merchant.name || "Fuzey",
      review_link: "https://random.link",
    });

  const [editorBodyText, setEditorBodyText] = useState<{
    value: string;
  }>({
    value: replaceCustomFields(bodyText, customFieldsDefinition),
  });
  const [localHeaderText, setLocalHeaderText] = useState<string>(
    replaceCustomFields(headerText, customFieldsDefinition)
  );

  useEffect(() => {
    setCustomFieldsDefinition({
      contact_first_name: currentAgent?.name || "Alex",
      contact_last_name: currentAgent?.surname || "Boyce",
      merchant_name: merchant.name || "Fuzey",
      review_link: "https://random.link",
    });
  }, [currentAgent]);

  useEffect(() => {
    if (!bodyText) {
      setEditorBodyText({
        value: " ",
      }); // that's a hack to trigger default text plugin
    } else {
      setEditorBodyText({
        value: replaceCustomFields(bodyText, customFieldsDefinition),
      });
    }
  }, [bodyText]);

  useEffect(() => {
    setLocalHeaderText(replaceCustomFields(headerText, customFieldsDefinition));
  }, [headerText]);

  return (
    <VStack
      w="100%"
      h="fit-content"
      borderRadius="xl"
      backgroundColor={
        colorMode === "dark" ? `${colorScheme}.800` : `${colorScheme}.100`
      }
      px={12}
      py={16}
      justifyContent="center"
      alignItems="center"
      position="relative"
      backgroundImage={`url('/preview-background-${colorMode}.jpg')`}
      backgroundSize="cover"
    >
      <VStack
        flexShrink={0}
        minHeight="8rem"
        minWidth="100%"
        borderRadius="xl"
        background={colorMode === "dark" ? "gray.700" : "white"}
        alignItems="start"
        justifyContent="start"
        pt={3}
        wordBreak="break-word"
        position="relative"
        __css={{
          _after: {
            content: "''",
            position: "absolute",
            bottom: 0,
            zIndex: 4,
            left: "-1.5rem",
            width: "2.25rem",
            height: "1.5rem",
            background: `radial-gradient(1.5rem at top left, #0000 99%, ${
              colorMode === "dark" ? "var(--chakra-colors-gray-700)" : "white"
            } 102%)`,
          },
          ".template-attachment-wrapper": {
            mb: 0,
            minWidth: "100%",
          },
          ".template-attachment": {
            maxHeight: "auto",
            minWidth: "100%",
            width: isBaseSize ? MAX_ATTACHMENT_WIDTH_MOBILE : "auto",
          },
        }}
      >
        <HeaderPreview
          file={file}
          mediaUrl={mediaUrl}
          mediaType={mediaType}
          headerText={localHeaderText}
        />
        {!editorBodyText.value ? null : (
          <Box mx={3} flexGrow={1}>
            <EditorUltra
              placeholder=""
              isEditable={false}
              highlightUnknownCustomFields={false}
              defaultText={editorBodyText}
              channels={[ConversationChannel.WHATSAPP]}
              enabledPlugins={[EditorUltraPlugin.RICHTEXT]}
              customFields={customFieldsDefinition}
            />
          </Box>
        )}
        {!footerText ? null : (
          <Text
            mx={3}
            flexGrow={1}
            color={colorMode === "dark" ? "gray.500" : "gray.200"}
          >
            {footerText}
          </Text>
        )}
        <ButtonsPreview buttons={buttons} />
      </VStack>
    </VStack>
  );
};

export default Preview;
