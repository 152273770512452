import {
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { memo, useEffect, useState } from "react";
import TagsList from "components/tags/list";
import { TriangleDownIcon } from "@chakra-ui/icons";

interface SnippetHeaderProps {
  customerName: string;
  date: string;
  tagIds: string[];
  assignedTeamId: string | null;
}

const SnippetHeader = memo(
  ({ customerName, date, tagIds, assignedTeamId }: SnippetHeaderProps) => {
    const { colorMode } = useColorMode();
    const { teams } = useAppSelector((state) => state.agents);
    const { selectedInbox } = useAppSelector((state) => state.conversations);

    const [teamName, setTeamName] = useState<string>("");

    useEffect(() => {
      if (assignedTeamId === null) {
        setTeamName("");
        return;
      }

      const team = teams.find((t) => t.id === assignedTeamId);
      setTeamName(team?.name || "");
    }, [assignedTeamId]);

    return (
      <VStack mb={2} alignItems="start" spacing={0}>
        <HStack
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
          width="100%"
        >
          <Text
            flexGrow={1}
            flexShrink={0}
            flexBasis="auto"
            maxWidth="33%"
            fontWeight="bold"
            fontSize="sm"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {customerName}
          </Text>
          <TagsList tagIds={tagIds} defaultMaxVisibleTags={1} />
          <Text
            flexShrink={0}
            flexGrow={0}
            flexBasis="auto"
            fontSize="xs"
            color={colorMode === "dark" ? "gray.600" : "gray.200"}
          >
            {date}
          </Text>
        </HStack>
        {teamName && selectedInbox !== assignedTeamId ? (
          <HStack spacing={0.5}>
            <Icon
              as={TriangleDownIcon}
              transform="rotate(-90deg)"
              width="0.5rem"
              height="0.5rem"
              color={colorMode === "dark" ? "gray.600" : "gray.200"}
            />
            <Text
              fontSize="xs"
              fontWeight="extrabold"
              color={colorMode === "dark" ? "gray.600" : "gray.200"}
            >
              {teamName}
            </Text>
          </HStack>
        ) : null}
      </VStack>
    );
  }
);

export default SnippetHeader;
